<template>
  <v-card class="pa-2" flat>
    <v-row class="mx-0 pb-1 align-center" justify="space-between" style="height: 100px">
      <span>{{ $lang.labels.code }}</span>
      <div class="d-inline-flex">
        <v-autocomplete
          v-model="customJSFunctionsValue"
          :items="customJSFunctions"
          color="white"
          :label="$lang.labels.customFunctions"
          outlined
          dense
          hide-details
          hide-no-data
          hide-selected
          auto
          style="max-width: 300px"
          class="ml-2"
          @change="addShortCode(customJSFunctionsValue)"
        />
        <v-autocomplete
          v-model="valueArrayValue"
          :items="valueArray"
          color="white"
          item-text="key"
          label="Variables"
          outlined
          dense
          hide-details
          hide-no-data
          hide-selected
          auto
          style="max-width: 300px"
          class="ml-2"
          @change="addShortCode(valueArrayValue)"
        />
        <v-btn
          icon
          color="primary"
          text
          large
          class="ml-2"
          @click="$emit('closeDialog', true)"
        >
          X
        </v-btn>
      </div>
    </v-row>
    <p class="pb-0 mb-1">{{ $lang.header.key }}</p>
    <v-text-field
      ref="keyRef"
      v-model="key"
      outlined
      dense
      required
      :rules="[v => !!v || 'Required!', (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
      class="required-asterisk"
      :readonly="!canEdit"
    ></v-text-field>
    <p class="pb-0 mb-0">{{ $lang.header.value }}</p>
    <codemirror v-if="initDone" v-model="code" :options="cmOptions" @blur="handleBlurEditor"></codemirror>
    <v-card-title v-if="withButtons" class="pt-2">
      <v-btn
        color="primary"
        text
        @click="$emit('closeDialog', true)"
      >
        {{ $lang.actions.cancel }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        :disabled="!key || !code || !$options.filters.javaVariableConventionRules(key, true)"
        @click="save()"
      >
        {{ $lang.actions.save }}
      </v-btn>
    </v-card-title>
  </v-card>
</template>

<script>
// require component
import { codemirror } from 'vue-codemirror'
import { JSHINT } from 'jshint'

// require styles
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/ayu-mirage.css'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/hint/show-hint.css'

// import js
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/groovy/groovy.js'
import 'codemirror/mode/python/python.js'
import 'codemirror/addon/lint/lint.js'
import 'codemirror/addon/lint/javascript-lint.js'
import 'codemirror/addon/hint/javascript-hint.js'
import 'codemirror/addon/hint/show-hint.js'

export default {
  components: {
    // PrismEditor,
    codemirror
  },
  props: {
    vars: {
      type: Array,
      default: () => {
        return []
      }
    },
    item: {
      type: Object,
      default: () => {
        return {
          text: '',
          value: ''
        }
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    withButtons: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    stepType: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data: () => (
    {
      initDone: false,
      code: '',
      key: '',
      customJSFunctions: [
        'f_is_var_defined'
      ],
      cursorPosition: {
        line: 0,
        ch: 0
      },
      valueArray: [],
      customJSFunctionsValue: '',
      customFunctionsValue: '',
      valueArrayValue: '',
      cmOptions: {
        gutters: ['CodeMirror-lint-markers'],
        tabSize: 4,
        mode: 'javascript',
        theme: 'ayu-mirage',
        lineNumbers: true,
        line: true,
        lineWrapping: true,
        autocorrect: true,
        autocomplete: true,
        selfContain: true,
        highlightLines: true,
        viewportMargin: Infinity,
        matchBrackets: true,
        autoCloseBrackets: true,
        lint: true,
        showHints: true
      }
    }),
  watch: {
    code: {
      handler(val) {
        if (!this.withButtons) this.$emit('fromGlobalEditor', val)
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.item && !this.item.text) this.$refs.keyRef.focus()
    }, 200)
  },
  created() {
    window.JSHINT = JSHINT
    if (this.item) {
      this.code = this.item.value
      this.key = this.item.text
    }

    // this.cursorPosition = this.code ? this.code.length : 0

    this.valueArray = this.recursion(this.vars)

    const names = this.valueArray.map((o) => o.key)
    const filtered = this.valueArray.filter(({ key }, index) => !names.includes(key, index + 1))

    this.valueArray = filtered.sort((a, b) => a.key.localeCompare(b.key))

    if (this.stepType === 'GROOVY') {
      this.cmOptions.mode = 'groovy'
      this.cmOptions.lint = false
      this.cmOptions.gutters = []
      this.cmOptions.selfContain = false
    } else if (this.stepType === 'PYTHON') {
      this.cmOptions.mode = 'python'
      this.cmOptions.lint = false
      this.cmOptions.gutters = []
      this.cmOptions.selfContain = false
    }

    setTimeout(() => {
      this.initDone = true
    }, 300)
  },
  methods: {
    handleBlurEditor(data) {
      const doc = data.getDoc()

      const cursor = doc.getCursor()

      const pos = {
        line: cursor.line,
        ch: cursor.ch
      }

      this.cursorPosition = pos
    },
    handleBlur(event) {
      this.cursorPosition = event.target.selectionStart
    },
    addShortCode(value) {
      const cm = document.querySelectorAll('.CodeMirror')[0].CodeMirror

      const position = this.cursorPosition.ch === 0 || this.cursorPosition ? this.cursorPosition : this.code.length

      const doc = cm.getDoc()

      doc.replaceRange(value, position) // adds a new line

      setTimeout(() => {
        this.customJSFunctionsValue = null
        this.customFunctionsValue = ''
        this.valueArrayValue = ''
      }, 5)
    },
    highlighter(code) {
      return highlight(code, languages.js) //returns html
    },
    save() {
      this.$emit('fromGlobalEditor', { key: this.key, value: this.code })
    },
    recursion(arr) {
      let stepValue = []
      let bool = false

      arr.forEach((item) => {
        const id = typeof this.step?.localId === 'string' ? this.step?.localId.split('.')[0] : this.step?.localId
        const itemId = typeof item?.localId === 'string' ? item?.localId.split('.')[0] : item?.localId

        if (itemId > id) {
          bool = true
        } else {
          bool = false
          if (this.step?.localId + '' >= item?.localId + '' && ('' + item.localId)?.split('.')[0] === ('' + this.step?.localId)?.split('.')[0]) {
            if (item.type === 'FOREACH') {
              stepValue.push({ 'key': item?.properties?.recordName })
            }
          }
        }

        if (item.properties?.set && !bool && (this.step?.localId + '' >= item?.localId + '' || +this.step?.localId > +item?.localId)) {
          for (const key in item?.properties?.set) {
            stepValue.push({
              key,
              localId: item.localId,
              value: item.properties.set[key],
              type: item.type
            })
          }
        } else if (item.type === 'FOREACH' && !bool) {
          if (item.properties?.steps.length) {
            const stepsArray = this.recursion(item.properties?.steps, stepValue)

            stepsArray.forEach((step) => {
              stepValue.push(step)
            })
          }
        } else if (item.type === 'UNSET_VARIABLES' && !bool) {
          stepValue = stepValue.filter( ( el ) => {
            return !item?.properties?.variables.find((objFromB) => {
              return el.key === objFromB
            })
          })

        } else if ((item?.properties?.steps?.length > 0 || item?.steps?.length) && !bool) {
          let stepsArray = []

          if (item?.steps?.length) {
            stepsArray = this.recursion(item?.steps, stepValue)
          } else {
            stepsArray = this.recursion(item?.properties?.steps, stepValue)

          }

          stepsArray.forEach((step) => {
            stepValue.push(step)
          })
        } else if (item?.properties?.conditions?.length > 0 && !bool) {
          const stepsArray = this.recursion(item?.properties?.conditions, stepValue)

          stepsArray.forEach((step) => {
            stepValue.push(step)
          })
        }
        if ((item.type === 'JDBC' ||
            item.type === 'MONGODB' ||
            item.type === 'CSV' ||
            item.type === 'EXECUTE_EXTERNAL_COMMAND' ||
            item.type === 'IMAGE' ||
            item.type === 'JDBC' ||
            item.type === 'JWT' ||
            item.type === 'PDF' ||
            item.type === 'UUID') &&
          !bool)
        {
          if (item.properties?.targetObject)
            stepValue.push({ 'key': item.properties?.targetObject })
        } else if (item.type === 'USER') {
          if (item.properties?.fields?.targetObject)
            stepValue.push({ 'key': item.properties?.fields?.targetObject })
        } else if (item.type === 'REST' && !bool) {
          if (item.properties?.restResponseCode)
            stepValue.push({ 'key': item.properties?.restResponseCode })
          if (item.properties?.targetObject)
            stepValue.push({ 'key': item.properties?.targetObject })
        }
      })

      return stepValue
    }
  }
}
</script>
<style lang="scss" scoped>

@font-face {
  font-family: 'Fira code';
  src: local('fira code'), url('~@/assets/fonts/FiraCode-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Fira Mono';
  src: local('Fira Mono'), url('~@/assets/fonts/FiraMono-Regular.ttf') format('truetype');
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 12px;
  height: 50vh;
  overflow-y: auto;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
