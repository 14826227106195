<template>
  <div class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="6" class="d-inline-flex">
        <h3 class="pt-2">{{ $lang.labels[type] }}</h3>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          color="accent"
          icon
          class="color-accent-text"
          :disabled="!canEdit"
          @click="deconstructedData.push({ text: type === 'switch' ? 'query' : '', value: '' })"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col v-if="duplicatedKeys" cols="12" class="pb-2">
        <v-alert dense color="error" style="color: black">{{ $lang.errors.duplicatedKeys }}</v-alert>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters align="center" class="pb-1">
          <v-col v-if="userSettings.display.showId" cols="1">
            <span>{{ $lang.header.id }} </span>
          </v-col>
          <v-col cols="4">
            <span>{{ ['EMAIL', 'SLACK', 'TWILIO', 'PDF'].includes(stepType) ? $lang.header.variable : $lang.header.key }}</span>
          </v-col>
          <v-col cols="5" class="px-1">
            <span>{{ $lang.header.value }}</span>
          </v-col>
          <v-col cols="2" class="text-right">
            <span>{{ $lang.header.act }}</span>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <template v-for="(itemOuter, i) in deconstructedData">
          <v-row :key="i" no-gutters align="center" class="pb-1">
            <v-col v-if="userSettings.display.showId">
              <span>{{ i + 1 }}</span>
            </v-col>
            <v-col cols="4" style="padding-top: 0px">
              <v-combobox
                v-model="itemOuter.text"
                full-width
                dense
                :items="filteredKeys"
                item-text="name"
                item-value="name"
                :readonly="!canEdit"
                :data-cy="`${type}-key-${i}`"
                :rules="[v => itemOuter.isRequired ? !!v || 'Required!' : true, (v) => itemOuter.isRequired ? $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong : true]"
              >
                <template v-slot:item="{ item }">
                  <span :style="`color: ${item.isRequired ? 'red' : ''}`">{{ item.name }}</span>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="5" class="px-1 align-center" style="padding-top: 13px">
              <v-text-field
                v-model="itemOuter.value"
                dense
                required
                :rules="[v => !!v || 'Required!']"
                :readonly="!canEdit"
                :data-cy="`${type}-value-${i}`"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="d-inline-flex">
                <v-btn
                  icon
                  small
                  :disabled="!canEdit"
                  @click="editRow(i)"
                >
                  <v-icon small color="info">mdi-file-edit-outline</v-icon>
                </v-btn>
                <v-btn
                  class="ml-1"
                  icon
                  small
                  :disabled="!canEdit"
                  @click="deleteRow(i)"
                >
                  <v-icon small color="error">mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    },
    stepType: {
      type: String,
      default: () => {
        return ''
      }
    },
    full: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    keyList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: () => ({
    deconstructedData: [],
    selectedKeys: [],
    emailKeys: ['locale', 'subject'],
    slackKeys: ['locale', 'message'],
    duplicatedKeys: false,
    languages: ['bg', 'zh', 'hr', 'cs', 'da', 'nl', 'en', 'et', 'fi', 'fr', 'de', 'el', 'hu', 'ga', 'it', 'lv', 'lt', 'mt', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sv', 'tr', 'uk'],
    searchSync: '',
    isBodyScript: false,
    bodyScript: '',
    headerPredefinedValues: [
      '\'application/json\'',
      '\'application/xml\'',
      '\'application/x-www-form-urlencoded\'',
      '\'multipart/form-data\''
    ]
  }),
  computed: {
    ...mapState('app', ['userSettings']),
    filteredKeys() {
      return this.keyList.filter((x) => !this.selectedKeys.includes(x))
    },
    availableLanguages() {
      return this.languages.map((x) => {
        return { text: this.$lang.labels[x], value: x }
      })
    }
  },
  watch: {
    searchSync: {
      handler(val) {
        const tempSearch = this.availableLanguages.find((x) => x.text === val)
        const localeData = this.deconstructedData.find((x) => x.text === 'locale')

        if (tempSearch) localeData.value = tempSearch.value
      }
    },
    bodyScript: {
      handler(val) {
        this.$emit('dataBodyScriptChanged', val)
      }
    },
    isBodyScript: {
      handler(val) {
        if (val) {
          this.deconstructedData = []
        } else {
          this.bodyScript = ''
        }
      }
    },
    deconstructedData: {
      handler() {
        const tempData = {}

        this.deconstructedData.forEach((item) => {
          if (item.text && item.text.name && item.value) tempData[item.text.name] = item.value
          if (item.text && !item.text.name && item.value) tempData[item.text] = item.value
        })

        this.selectedKeys = this.deconstructedData.map((x) => x.text)
        this.duplicatedKeys = !!this.hasDuplicates(this.selectedKeys)
        this.$emit('dataChanged', tempData)
      },
      deep: true
    }
  },
  created() {
    for (const row in this.data) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.data.hasOwnProperty(row)) {
        this.deconstructedData.push({ text: row , value: this.data[row] })
      }
    }
  },
  methods: {
    removeJavaDollarSign() {
      this.deconstructedData.forEach((obj, i) => {
        const isDollarStart = obj.text.substring(0, 1) === '$'

        if (isDollarStart) this.deconstructedData[i].text = obj.text.substring(1)
      })
    },
    hasDuplicates(arr) {
      return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x))
    },
    emailRules(v) {
      const isSplit = v.split(',')

      if (isSplit.length > 1) {
        let correct = true

        isSplit.forEach((testEmail) => {
          if (correct) {
            correct = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(testEmail.trim().toLowerCase())
          }
        })

        if (correct) return true

        return this.$lang.errors.formatEmail
      } else {
        return (v && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v.toLowerCase())) || this.$lang.errors.formatEmail
      }
    },
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    },
    editRow(i) {
      this.$emit('editor', this.deconstructedData[i])
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
