<template>
  <v-card class="pa-2" style="min-height: 80vh; max-height: 80vh; overflow-y: auto">
    <v-row no-gutters>
      <v-col v-if="success" cols="12">
        <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
      </v-col>
      <v-col cols="12">
        <v-card-title class="pb-2">
          <span>{{ $lang.header.event }} #{{ eventId }}</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            text
            large
            @click="$emit('closeDialog', true)"
          >
            X
          </v-btn>
        </v-card-title>
      </v-col>
      <v-col cols="12">
        <v-tabs
          v-model="tab0"
          class="pb-2"
        >
          <v-tabs-slider color="accent"></v-tabs-slider>

          <v-tab
            :key="9"
          >
            {{ $lang.header.details }}
          </v-tab>

          <v-tab
            :key="0"
          >
            {{ $lang.header.input }}
          </v-tab>
          <v-tab
            :key="1"
          >
            {{ $lang.header.output }}
          </v-tab>
          <v-tab
            :key="2"
          >
            {{ $lang.header.exception }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab0">
          <v-tab-item
            :key="110"
            eager
          >
            <div class="d-inline-flex">
              <p class="color-secondary" style="width: 180px">{{ $lang.labels.status }}:</p> <p class="color-primary">{{ $lang.status[data.status] }}</p>
            </div>
            <br />
            <div class="d-inline-flex">
              <p class="color-secondary" style="width: 180px">{{ $lang.labels.type }}:</p> <p class="color-primary">{{ $lang.status[data.type] }}</p><br />
            </div>
            <br />
            <div class="d-inline-flex">
              <p class="color-secondary" style="width: 180px">{{ $lang.labels.respondSent }}:</p> <p class="color-primary">{{ data.respondSent ? $lang.labels.yes : $lang.labels.no }}</p>
            </div>
            <br />
            <div class="d-inline-flex">
              <p class="color-secondary" style="width: 180px">{{ $lang.labels.processingStartOn }}:</p> <p class="color-primary">{{ data.processingStartOn | formatDateTimePrecise }}</p>
            </div>
            <br />
            <div class="d-inline-flex">
              <p class="color-secondary" style="width: 180px">{{ $lang.labels.receivingInstanceId }}:</p> <p class="color-primary">{{ data.receivingInstanceId }}</p>
              <v-btn
                icon
                color="primary"
                class="ml-2"
                style="margin-top: -8px"
                @click="clipboardCopy(data.receivingInstanceId)"
              >
                <v-icon>mdi-clipboard-outline</v-icon>
              </v-btn>
            </div>
            <br />
            <div class="d-inline-flex">
              <p class="color-secondary" style="width: 180px">{{ $lang.labels.instanceIdlong }}:</p> <p class="color-primary">{{ data.instanceId }}</p>
              <v-btn
                icon
                color="primary"
                class="ml-2"
                style="margin-top: -8px"
                @click="clipboardCopy(data.instanceId)"
              >
                <v-icon>mdi-clipboard-outline</v-icon>
              </v-btn>
            </div>
            <br v-if="data.parentEventId" />
            <div v-if="data.parentEventId" class="d-inline-flex">
              <p class="color-secondary" style="width: 180px">{{ $lang.labels.parentEvent }}:</p> <p class="color-primary">{{ data.parentEventId }}</p>
              <v-btn
                icon
                color="primary"
                class="ml-2"
                style="margin-top: -8px"
                @click="clipboardCopy(data.parentEventId)"
              >
                <v-icon>mdi-clipboard-outline</v-icon>
              </v-btn>
            </div>
            <br v-if="parentProcessObj" />
            <div v-if="parentProcessObj" class="d-inline-flex">
              <p class="color-secondary" style="width: 180px">{{ $lang.labels.parentProcess }}:</p> <p class="color-primary clickable-simple" @click="openProcess()">{{ parentProcessObj.name }}</p>
              <v-btn
                icon
                color="primary"
                class="ml-2"
                style="margin-top: -8px"
                @click="clipboardCopy(parentProcessObj.name)"
              >
                <v-icon>mdi-clipboard-outline</v-icon>
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item
            :key="1110"
            eager
          >
            <v-row no-gutters>
              <v-col cols="12">
                <v-tabs
                  v-model="tab1"
                  class="pb-2"
                >
                  <v-tabs-slider color="accent"></v-tabs-slider>

                  <v-tab
                    :key="220"
                  >
                    {{ $lang.labels.list }}
                  </v-tab>
                  <v-tab
                    :key="221"
                  >
                    {{ $lang.labels.json }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab1">
                  <v-tab-item
                    :key="220"
                    eager
                  >
                    <template v-for="(item, i) in inputArr">
                      <span v-if="item.value === ''" :key="i" class="headline">{{ `${item.text} / ` }}</span>
                      <p v-else :key="i" class="color-secondary">{{ item.text }}: <span class="color-primary">{{ item.value }}</span></p>
                    </template>
                  </v-tab-item>
                  <v-tab-item
                    :key="221"
                    eager
                  >
                    <v-lazy class="my-2">
                      <json-custom-viewer :data="input" @success="success = $event" />
                    </v-lazy>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            :key="331"
            eager
          >
            <v-row no-gutters>
              <v-col cols="12">
                <v-tabs
                  v-model="tab2"
                  class="pb-1"
                >
                  <v-tabs-slider color="accent"></v-tabs-slider>

                  <v-tab
                    :key="440"
                  >
                    {{ $lang.labels.list }}
                  </v-tab>
                  <v-tab
                    :key="441"
                  >
                    {{ $lang.labels.json }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab2">
                  <v-tab-item
                    :key="550"
                    eager
                  >
                    <template v-for="(item, i) in outputArr">
                      <span v-if="item.value === ''" :key="i" class="headline">{{ `${item.text} / ` }}</span>
                      <p v-else :key="i" class="color-secondary">{{ item.text }}: <span class="color-primary">{{ item.value }}</span></p>
                    </template>
                  </v-tab-item>
                  <v-tab-item
                    :key="661"
                    eager
                  >
                    <v-lazy class="my-2">
                      <json-custom-viewer :data="output" @success="success = $event" />
                    </v-lazy>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            :key="772"
            eager
          >
            <v-row no-gutters>
              <v-col cols="12">
                <v-tabs
                  v-model="tab3"
                  class="pb-1"
                >
                  <v-tabs-slider color="accent"></v-tabs-slider>

                  <v-tab
                    :key="880"
                  >
                    {{ $lang.header.exceptionMessage }}
                  </v-tab>
                  <v-tab
                    :key="881"
                  >
                    {{ $lang.header.exceptionStackTrace }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab3">
                  <v-tab-item
                    :key="880"
                  >
                    <v-row no-gutters>
                      <v-col cols="12" class="pt-2">
                        <div class="calculated-height" style="overflow-y: auto;">
                          <v-tooltip v-if="exception && exception.exceptionMessage" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                color="primary"
                                text
                                large
                                v-bind="attrs"
                                v-on="on"
                                @click="clipboardCopy(exception.exceptionMessage)"
                              >
                                <v-icon>mdi-content-copy</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $lang.labels.copy }}</span>
                          </v-tooltip>
                          <p class="color-primary font-change" v-html="exception && exception.exceptionMessage ? exception.exceptionMessage : $lang.hints.noExceptionMessage"></p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item
                    :key="991"
                  >
                    <v-row no-gutters>
                      <v-col cols="12" class="pt-2">
                        <div class="calculated-height" style="overflow-y: auto;">
                          <v-tooltip v-if="exception && exception.exceptionStackTrace" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                color="primary"
                                text
                                large
                                v-bind="attrs"
                                v-on="on"
                                @click="clipboardCopy(exception.exceptionStackTrace)"
                              >
                                <v-icon>mdi-content-copy</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $lang.labels.copy }}</span>
                          </v-tooltip>
                          <p class="color-primary font-change" v-html="exception && exception.exceptionStackTrace ? exception.exceptionStackTrace : $lang.hints.noExceptionStackTrace"></p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import jsonCustomViewer from '@/components/ui/JsonCustomViewer'
import { getProcessByIdUsingGET as getProcess } from '@/utils/api'
export default {
  components: {
    jsonCustomViewer
  },
  props: {
    eventId: {
      type: String,
      default: () => ''
    },
    data: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      tab0: 0,
      tab1: 0,
      tab2: 0,
      tab3: 0,
      input: null,
      output: null,
      exception: {
        exceptionMessage: null,
        exceptionStackTrace: null
      },
      inputArr: [],
      outputArr: [],
      success: '',
      parentProcessObj: null
    }
  },
  created() {
    if (this.data?.parentProcessId) {
      getProcess({ id: this.data?.parentProcessId })
        .then(async (res) => {
          this.parentProcessObj = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const formatInputObj = (obj) => {
      if (obj && Object.keys(obj).length > 0) {
        for (const row in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(row)) {
            if (typeof  obj[row] !== 'object') {
              this.inputArr.push({ text: row , value: obj[row] })
            } else if (obj[row] && typeof  obj[row] === 'object' && Object.keys(obj[row]).length > 0) {
              this.inputArr.push({ text: row , value: '' })
              formatInputObj(obj[row])
            } else if (obj[row] && typeof  obj[row] === 'object' && Object.keys(obj[row]).length === 0) {
              this.inputArr.push({ text: row , value: '{}' })
            } else {
              this.inputArr.push({ text: row , value: 'null' })
            }
          }
        }
      }
    }

    const formatOutputObj = (obj) => {
      if (obj && JSON.stringify(obj) !== '{}') {
        for (const row in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(row)) {
            if (typeof  obj[row] !== 'object') {
              this.outputArr.push({ text: row , value: obj[row] })
            } else if (obj[row] && typeof  obj[row] === 'object' && Object.keys(obj[row]).length > 0) {
              this.outputArr.push({ text: row , value: '' })
              formatOutputObj(obj[row])
            } else if (obj[row] && typeof  obj[row] === 'object' && Object.keys(obj[row]).length === 0) {
              this.outputArr.push({ text: row , value: '{}' })
            } else {
              this.outputArr.push({ text: row , value: 'null' })
            }
          }
        }
      }
    }

    if (this.data && this.data.exceptionMessage) {
      this.exception.exceptionMessage = this.data.exceptionMessage.replaceAll('\n', '<br />')
    }

    if (this.data && this.data.exceptionStackTrace) {
      this.exception.exceptionStackTrace = this.data.exceptionStackTrace.replaceAll('\n', '<br />')
    }

    if (this.data && this.data.input) {
      this.input = this.data.input
      formatInputObj(this.input)
    }
    if (this.data && this.data.output) {
      this.output = this.data.output
      formatOutputObj(this.output)
    }
  },
  methods: {
    openProcess() {
      if (this.parentProcessObj.id) window.open(`/${localStorage.selectedLanguage || 'en'}/processes/edit/${this.parentProcessObj.id}`, '_blank')
    },
    clipboardCopy(type) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard

      clipboardData.writeText(type)

      this.success = this.$lang.success.copiedClipboard

      setTimeout(() => this.success = '', 5000)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-lazy {
  max-height: calc(80vh - 230px);
}
.calculated-height {
  max-height: calc(80vh - 230px);
}

.font-change {
  font-family: "Courier New", Courier, monospace !important;
}
</style>
