<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn class="dropDown-triggers color-primary" v-on="on">
        Triggers
      </v-btn>
    </template>
    <v-list dense nav>
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        :to="{ name: item.link, params: { lang: $lang.current_lang }, query: { processId: processId, activeOnly: false } }"
        link
        target="_blank"
        :exact="item.exact"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
/*
|---------------------------------------------------------------------
| Toolbar Triggers Component
|---------------------------------------------------------------------
*/

export default {
  name: 'ToolbarTriggers',
  props: {
    processId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      menu: [
        { icon: 'mdi-earth', text: 'Cron', link: 'cron', exact: true },
        { icon: 'mdi-earth', text: 'Messaging', link: 'messaging', exact: true },
        { icon: 'mdi-earth', text: 'Rest', link: 'rest', exact: true }
      ]
    }
  }
}
</script>
<style>
.dropDown-triggers {
  font-weight: normal!important;
  margin: 2px 8px 0 8px;
  padding: 0!important;
  color: var(--v-primary-base)!important;
  background-color: unset!important;
  box-shadow: none;
}
</style>
