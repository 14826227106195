<template>
  <div style="overflow-y: hidden" class="this-screen-max-height process-container">
    <div class="d-inline-flex justify-space-between pr-1" style="width: 100%">
      <div class="d-inline-flex align-content-center align-self-center align-center">
        <h1 class="text-h5 ma-0 pa-0 align-self-center">
          <strong>{{ $lang.labels.process }}:</strong>
          <span
            class="process-name"
            @click="selectProcessName()"
          >
            {{ process.name ? process.name : $lang.labels.addProcessName }}
          </span>
        </h1>
      </div>
      <div class="d-flex">
        <div class="d-inline-flex ml-4 align-center">
          <span class="mr-1">{{ $lang.labels.view }}:</span>
          <v-btn
            :disabled="lock || process.steps.steps.length === 0"
            icon
            class="mr-1"
            :color="radioButtonsVal === '1' ? 'accent' : 'primary'"
            @click="radioButtonsVal = '1'"
          >
            <v-icon>mdi-timeline-check-outline</v-icon>
          </v-btn>
          <v-btn
            :disabled="lock || process.steps.steps.length === 0"
            icon
            class="mr-1"
            :color="radioButtonsVal === '2' ? 'accent' : 'primary'"
            @click="radioButtonsVal = '2'"
          >
            <v-icon>mdi-dots-vertical-circle-outline</v-icon>
          </v-btn>
          <v-btn
            :disabled="lock || process.steps.steps.length === 0"
            icon
            class="mr-2"
            :color="radioButtonsVal === '3' ? 'accent' : 'primary'"
            @click="radioButtonsVal = '3'"
          >
            <v-icon>mdi-dots-horizontal-circle-outline</v-icon>
          </v-btn>
          <span v-if="['2', '3'].includes(radioButtonsVal)" class="mr-1">{{ $lang.labels.groupNodes }}:</span>
          <v-switch
            v-if="['2', '3'].includes(radioButtonsVal)"
            v-model="mermaidGrouping"
            dense
            hide-details
            class="mt-0"
          />
          <v-divider v-if="['2', '3'].includes(radioButtonsVal)" vertical class="mx-1" />
        </div>
        <div>
          <span class="mr-1">{{ $lang.labels.actions }}:</span>
          <v-btn
            v-if="process.steps.steps.length === 0"
            :disabled="lock || !userCanEdit"
            icon
            color="primary"
            @click="showImport = true"
          >
            <v-icon>mdi-import</v-icon>
          </v-btn>
          <v-btn
            :disabled="!valid || lock || !userCanEdit || process.steps.steps.length === 0"
            icon
            color="primary"
            :class="process.steps.steps.length === 0 ? 'ml-1' : ''"
            @click="submit()"
          >
            <v-icon>mdi-content-save-outline</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <v-divider class="my-2"></v-divider>
    <v-row wrap no-gutters class="fill-height" style="max-height: calc(100% - 33px - 36px )">
      <v-col
        cols="12"
        class="fill-height"
      >
        <v-row v-if="err || success" dense no-gutters class="px-0">
          <v-col v-if="err" cols="12">
            <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
          </v-col>
          <v-col v-if="success" cols="12">
            <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
          </v-col>
        </v-row>
        <div class="d-inline-flex fill-height" style="width: 100%">
          <div
            ref="steps"
            class="pr-1 flex-fill"
            :style="showProcessMermaid ? 'overflow-y: auto' : ''"
          >
            <v-alert v-if="process.steps.steps.length === 0" color="warning" style="color: black">
              {{ $lang.errors.zeroSteps }}
            </v-alert>
            <v-alert v-if="ttlLogsHint" color="warning" style="color: black">
              <v-row align="center">
                <v-col class="grow">
                  {{ $lang.hints.ttlLogsHint }}
                </v-col>
                <v-col class="shrink">
                  <v-btn @click="ttlLogsHint=false">OK</v-btn>
                </v-col>
              </v-row>
            </v-alert>
            <v-alert v-if="ttlErrLogsHint" color="warning" style="color: black">
              <v-row align="center">
                <v-col class="grow">
                  {{ $lang.hints.ttlLogsHint }}
                </v-col>
                <v-col class="shrink">
                  <v-btn @click="ttlErrLogsHint=false">OK</v-btn>
                </v-col>
              </v-row>
            </v-alert>
            <process-mermaid
              v-if="process && process.steps && process.steps.steps && process.steps.steps.length > 0 && !loading && showProcessMermaid"
              :mermaid-data="process.steps.steps"
              :process-name="process.name || ''"
              :mermaid-type="processMermaidType"
              :highest-id="highestId"
              :mermaid-id="mermaidId"
              :mermaid-grouping="mermaidGrouping"
              class="mermaid-style"
              @goToStep="goToStep"
            />
            <timeline
              v-if="process && process.steps && process.steps.steps && process.steps.steps.length > 0 && !loading && !showProcessMermaid"
              :key="tableKey"
              style="overflow-x: auto"
              :steps="process.steps.steps"
              :can-edit="userCanEdit"
              :drag-lock="dragLock"
              @previewData="previewData($event.item, $event.isSpecial, $event.innerStep, $event.isAfter, $event.tryCatch)"
              @addStep="addStep"
              @moveStep="moveStep"
              @deleteStep="selectedDeleteItem = $event; yesNoShow = true"
              @showSnack="showSnack"
              @pasteStep="pasteStep"
              @duplicateStep="duplicateStep"
              @dragSteps="dragSteps"
              @showHideChildren="showHideChildren"
            ></timeline>
            <div v-else class="justify-center text-center">
              <add-row
                v-if="!loading && !showProcessMermaid"
                single
                data-cy="add-standard-new"
                @click.native="userCanEdit ? addStep({ id: 0 }) : ''"
              ></add-row>
              <v-progress-circular v-if="loading" indeterminate color="primary"/>
            </div>
          </div>
          <div
            ref="resizeme"
            :class="{ 'fill-height resize-me toggle-right-list': true, 'pr-1': mini, 'is-close': !mini }"
            style="padding-left: 18px"
          >
            <div class="toggle-menu">
              <v-btn
                class="toggle-left"
                icon
                @click.stop="mini = !mini"
              >
                <v-icon v-if="mini">mdi-chevron-right </v-icon>
                <v-icon v-else> mdi-chevron-left </v-icon>
              </v-btn>

            </div>
            <div class="border-me h-100"></div>
            <v-row
              :key="logKey"
              wrap
              no-gutters
              :class="{ 'visibility-hidden': !mini }"
            >
              <v-col
                cols="12"
              >
                <v-card class="py-1 px-2" color="customDrawerBg" style="border-bottom-left-radius: 0; border-bottom-right-radius: 0">
                  <v-row wrap no-gutters class="justify-space-between align-center">
                    <div class="d-inline-flex align-center">
                      <v-text-field
                        id="eventId"
                        ref="eventId"
                        v-model="selectedEventId"
                        outlined
                        dense
                        type="number"
                        :label="$lang.labels.eventId"
                        hide-details
                        style="position: relative; width: 142px"
                        @input="stepLogsSearch = false"
                        @keyup.enter="selectedEventId && selectedEventId.length > 0 ? fetchLogs(selectedEventId) : fetchEventsLogs()"
                      >
                        <template v-slot:append>
                          <v-icon v-if="selectedEventId" :color="iconColorChanger()" style="position: absolute; right: 6px;">mdi-circle</v-icon>
                          <v-progress-circular
                            v-if="selectedEventId"
                            indeterminate
                            size="30"
                            :color="loadingAutoRefresh ? 'primary' : 'transparent'"
                            style="position: absolute; right: 6px; top: 5px"
                          />
                        </template>
                      </v-text-field>
                      <v-btn
                        :disabled="lock || process.steps.steps.length === 0"
                        icon
                        small
                        color="primary"
                        class="mx-1"
                        @click="selectedEventId && selectedEventId.length > 0 ? fetchLogs(selectedEventId) : fetchEventsLogs()"
                      >
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </div>
                    <div class="d-inline-flex">
                      <v-btn
                        :disabled="lock || process.steps.steps.length === 0 || !selectedEventId"
                        small
                        color="primary"
                        class="mx-1"
                        @click="fetchEvent()"
                      >{{ $lang.labels.event }}
                      </v-btn>
                      <v-btn
                        :disabled="lock || process.steps.steps.length === 0 || selectedLogIndex === 0"
                        icon
                        small
                        color="primary"
                        class="mx-1"
                        @click="selectedLogIndex ? selectedLogIndex-- : selectedLogIndex = 0"
                      >
                        <v-icon>mdi-arrow-up</v-icon>
                      </v-btn>
                      <v-btn
                        :disabled="lock || process.steps.steps.length === 0 || selectedLogIndex === logs.length - 1"
                        icon
                        small
                        color="primary"
                        class="mx-1"
                        @click="selectedLogIndex || selectedLogIndex === 0 ? selectedLogIndex++ : selectedLogIndex = 0"
                      >
                        <v-icon>mdi-arrow-down</v-icon>
                      </v-btn>
                    </div>
                    <div class="d-inline-flex">
                      <v-btn
                        :disabled="lock || process.steps.steps.length === 0"
                        icon
                        small
                        color="primary"
                        class="mx-1"
                        :loading="lastEventsLogsLoader"
                        @click="fetchEventsLogs()"
                      >
                        <v-icon>mdi-page-last</v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                  <v-row
                    v-if="logs && logs.length > 0"
                    id="logsScrollElement"
                    wrap
                    no-gutters
                    class="align-center"
                    style="max-height: 32vh; overflow-y: auto"
                  >
                    <v-data-table
                      :headers="headers"
                      :items="logs"
                      item-key="id"
                      class="elevation-1 bland-bg pt-1"
                      hide-default-footer
                      dense
                      style="width: 100%"
                      :item-class="selectedRow"
                      :items-per-page="-1"
                      @click:row="selectTableRow"
                    >
                      <template v-slot:item.stepId="{ item, index }">
                        <div :id="`row-${foundLogsSteps[item.stepId]}-${index}`" class="font-weight-bold">
                          {{ foundLogsSteps[item.stepId] || 'Not present' }}
                        </div>
                      </template>

                      <template v-slot:item.finishedOn="{ item }">
                        <div>{{ item.finishedOn | formatDateTimePrecise }}</div>
                      </template>

                      <template v-slot:item.status="{ item }">
                        <div v-if="item.stepType === 'EXECUTE_PROCESS'">{{
                          $lang.status[item.status]
                        }}{{ item.stepType === 'EXECUTE_PROCESS' && item.childEventId ? ': ' : '' }}<span
                          class="clickable-simple underline-simple"
                          style="z-index: 1000"
                          @click="openStepFromDebug(item.stepId, item.childEventId)"
                        >{{
                          item.stepType === 'EXECUTE_PROCESS' && item.childEventId ? item.childEventId : ''
                        }}</span>
                        </div>
                        <div v-else-if="['JDBC', 'MONGODB', 'LOG'].includes(item.stepType)">{{
                          $lang.status[item.status]
                        }}{{ item.debugLog ? ': ' : '' }}<span
                          class="clickable-simple underline-simple"
                          style="z-index: 1000"
                          @click="debugLogData = item.debugLog; showDebugLog = true"
                        >{{
                          item.debugLog ? $lang.labels.debug : ''
                        }}</span>
                        </div>
                        <div v-else>{{ $lang.status[item.status] }}{{ item.debugLog ? ': ' : '' }}<span
                          v-if="item.debugLog"
                          class="clickable-simple underline-simple"
                          style="z-index: 1000"
                          @click="debugLogData = item.debugLog; showDebugLog = true"
                        >{{
                          item.debugLog ? $lang.labels.debug : ''
                        }}</span></div>
                      </template>

                      <template v-slot:item.stepType="{ item }">
                        <div>{{ $lang.status[item.stepType] }}</div>
                      </template>

                      <template v-slot:item.actions="{ item }">
                        <div class="d-inline-flex">
                          <v-btn
                            small
                            color="primary"
                            class="mx-1"
                            @click="openLogCache(item.id)"
                          >
                            <span class="color-customStepLogSelect">{{ $lang.labels.showCache }}</span>
                          </v-btn>
                          <v-btn
                            small
                            color="primary"
                            class="mx-1"
                            :disabled="!item.exceptionStackTrace && !item.exceptionMessage"
                            @click="selectedEst = { exceptionStackTrace: item.exceptionStackTrace, exceptionMessage: item.exceptionMessage }; showSelectedEst = true"
                          ><span class="color-customStepLogSelect">{{ $lang.labels.est }}</span>
                          </v-btn>
                        </div>
                      </template>
                    </v-data-table>
                  </v-row>
                  <v-row v-if="logs.length === 0 && stepLogsSearch" no-gutters>
                    <v-col
                      cols="12"
                      class=""
                    >
                      <v-alert dense color="warning" style="color: black">{{ $lang.errors.noLogs }} {{
                        selectedEventId
                      }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row :class="!mini && 'visibility-hidden'" wrap no-gutters>
              <v-col
                v-if="!userCanEdit && !loading"
                cols="12"
                class="px-1 pt-1"
              >
                <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
              </v-col>
              <v-col cols="12">
                <v-tabs
                  v-model="tab"
                  background-color="customDrawerBg"
                >
                  <v-tabs-slider color="accent"></v-tabs-slider>

                  <v-tab
                    :key="0"
                    data-cy="tab-process"
                    @click="selectedItem = null; previewData({ id: null }, '', null, ''); unselectAllStepsFromLog()"
                  >
                    {{ $lang.labels.process }}
                  </v-tab>
                  <v-tab
                    :key="1"
                    data-cy="tab-roles"
                    @click="selectedItem = null; previewData({ id: null }, '', null, ''); unselectAllStepsFromLog()"
                  >
                    {{ $lang.labels.roles }}
                  </v-tab>
                  <v-tab
                    :key="2"
                    data-cy="tab-history"
                    @click="selectedItem = null; previewData({ id: null }, '', null, ''); unselectAllStepsFromLog()"
                  >
                    {{ $lang.labels.history }}
                  </v-tab>
                  <v-tab
                    v-if="selectedItem"
                    :key="3"
                    data-cy="tab-step"
                  >
                    {{ $lang.labels.step }} {{ selectedItem.localId ? selectedItem.localId : '' }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item
                    :key="0"
                  >
                    <v-card class="pa-2" color="customDrawerBg" style="border-top-left-radius: 0; border-top-right-radius: 0">
                      <v-form
                        ref="form"
                        v-model="valid"
                      >
                        <v-row wrap no-gutters justify="space-between">
                          <v-col
                            cols="12"
                          >
                            <div class="d-inline-flex max-width">
                              <v-text-field
                                id="processName"
                                ref="processName"
                                v-model="process.name"
                                outlined
                                dense
                                :label="$lang.labels.name"
                                required
                                :rules="[v => !!v || 'Required!']"
                                class="required-asterisk"
                                :persistent-hint="isEdit"
                                :hint="formatHint"
                                :readonly="!userCanEdit"
                              ></v-text-field>
                              <v-btn
                                icon
                                color="primary"
                                class="ml-2"
                                style="margin-top: 2px"
                                @click="copyProcessName()"
                              >
                                <v-icon>mdi-clipboard-outline</v-icon>
                              </v-btn>
                            </div>
                          </v-col>
                          <v-col v-if="isEdit" cols="12" class="pt-2 justify-space-between">
                            <v-row wrap no-gutters justify="space-between">
                              <v-btn
                                class="button-default-width"
                                color="secondary"
                                :disabled="!userCanEdit"
                                @click="changeStatus()"
                              >
                                {{ process.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                              </v-btn>
                              <div>
                                <div class="d-flex">
                                  <p class="pt-1 px-1">{{ $lang.labels.open }}:</p>
                                  <p
                                    class="clickable-simple color-primary"
                                  >
                                    <toolbar-triggers class="pt-1 px-1" :process-id="process.id"></toolbar-triggers>
                                  </p>

                                  <v-divider
                                    vertical
                                    style="min-height: unset; height: 21px; transform: translateY(12px)"
                                  />
                                  <p
                                    class="pt-1 px-1 clickable-simple color-primary"
                                    @click="openProcessLogs()"
                                  >
                                    {{ $lang.labels.openProcessLogs }}
                                  </p>
                                  <v-divider
                                    vertical
                                    style="min-height: unset; height: 21px; transform: translateY(12px)"
                                  />
                                  <p
                                    class="pt-1 pl-1 clickable-simple color-primary"
                                    @click="openProcessEvents()"
                                  >
                                    {{ $lang.labels.openProcessEvents }}
                                  </p>
                                </div>
                              </div>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row v-if="!loading" no-gutters>
                          <v-col
                            cols="12"
                            class="pb-2"
                          >
                            <v-expansion-panels id="expansionProcessAdvanced" v-model="panelIndex" multiple>
                              <v-expansion-panel
                                :key="0"
                              >
                                <v-expansion-panel-header>
                                  <v-row no-gutters justify="space-between" align="center" class="mr-1">
                                    <div class="d-inline-flex align-center">
                                      <v-icon class="mr-1">mdi-comment-outline</v-icon>
                                      {{ $lang.labels.comment }}
                                    </div>
                                    <span
                                      class="clickable-simple color-primary pa-0 ma-0"
                                      @click="lock || !userCanEdit ? '' : showMarkdown = true"
                                    >
                                      {{ $lang.actions.edit }}
                                    </span>
                                  </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <div class="mark-class pa-1 clickable-simple" @click="lock || !userCanEdit ? '' : showMarkdown = true" v-html="compiledMarkdown"></div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                              <v-expansion-panel
                                :key="1"
                              >
                                <v-expansion-panel-header>
                                  <v-row no-gutters justify="start" align="center" class="mr-1">
                                    <v-icon class="mr-1">mdi-cog</v-icon>
                                    {{ $lang.labels.advancedOptions }}
                                  </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-row v-if="!loading" wrap no-gutters>
                                    <v-col cols="12">
                                      <div class="d-inline-flex max-width">
                                        <v-autocomplete
                                          v-model="process.validationRuleId"
                                          outlined
                                          dense
                                          :items="validations"
                                          :loading="isLoadingValidations"
                                          :search-input.sync="searchValidations"
                                          clearable
                                          hide-no-data
                                          item-text="name"
                                          item-value="id"
                                          :label="$lang.labels.validation"
                                          :placeholder="$lang.actions.startTyping"
                                          prepend-inner-icon="mdi-cloud-search-outline"
                                          :readonly="!userCanEdit"
                                        ></v-autocomplete>
                                        <v-btn
                                          icon
                                          color="primary"
                                          class="ml-1"
                                          @click="searchValidationsFunction(searchValidations)"
                                        >
                                          <v-icon>mdi-refresh</v-icon>
                                        </v-btn>
                                        <v-btn
                                          text
                                          class="mx-0 ml-1"
                                          color="primary"
                                          :disabled="!process.validationRuleId"
                                          @click="openValidation(process.validationRuleId)"
                                        >
                                          {{ $lang.actions.openProcess }}
                                        </v-btn>
                                      </div>
                                    </v-col>
                                    <v-col cols="6" class="pr-1">
                                      <v-text-field
                                        v-model="tempErrorsTtlInMSec"
                                        outlined
                                        dense
                                        :label="$lang.labels.logsErrTtlInMSec"
                                        required
                                        :rules="[v => (!!v || v === 0) || 'Required!']"
                                        class="required-asterisk"
                                        :readonly="!userCanEdit"
                                        @change="tempErrorsTtlInMSec === 0 || tempErrorsTtlInMSec === '0' ? ttlErrLogsHint = true : ttlErrLogsHint = false"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="pl-1">
                                      <v-select
                                        v-model="timeUnitErrTtl"
                                        :items="timeUnits"
                                        :label="$lang.labels.unit"
                                        outlined
                                        dense
                                        required
                                        :readonly="!userCanEdit"
                                        style="min-width: 115px"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="6" class="pr-1">
                                      <v-text-field
                                        v-model="tempLogsTtlInMSec"
                                        outlined
                                        dense
                                        :label="$lang.labels.logsTtlInMSec"
                                        required
                                        :rules="[v => (!!v || v === 0) || 'Required!']"
                                        class="required-asterisk"
                                        :readonly="!userCanEdit"
                                        @change="tempLogsTtlInMSec === 0 || tempLogsTtlInMSec === '0' ? ttlLogsHint = true : ttlLogsHint = false"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="pl-1">
                                      <v-select
                                        v-model="timeUnitTtl"
                                        :items="timeUnits"
                                        :label="$lang.labels.unit"
                                        outlined
                                        dense
                                        required
                                        :readonly="!userCanEdit"
                                        style="min-width: 115px"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="6" class="pr-1">
                                      <v-text-field
                                        v-model="tempMaxProcessingDurationInMSec"
                                        outlined
                                        dense
                                        :label="$lang.labels.maxProcessingDuration"
                                        required
                                        :rules="[v => !!v || 'Required!']"
                                        class="required-asterisk"
                                        :readonly="!userCanEdit"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="pl-1">
                                      <v-select
                                        v-model="timeUnitPDur"
                                        :items="timeUnits"
                                        :label="$lang.labels.unit"
                                        outlined
                                        dense
                                        required
                                        :readonly="!userCanEdit"
                                        style="min-width: 115px"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="6" class="pr-1">
                                      <v-text-field
                                        v-model="process.simultaneousExecutionsPerInstance"
                                        outlined
                                        dense
                                        :label="$lang.labels.simultaneousExecutionsPerInstance"
                                        required
                                        class="required-asterisk"
                                        :rules="[v => !!v || 'Required!', v => v >= 1 || 'Must be greater than 0', v => v <= process.overallSimultaneousExecutions || 'Must be less than or equal to overall simultaneous executions']"
                                        :readonly="!userCanEdit"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="pl-1">
                                      <v-text-field
                                        v-model="process.overallSimultaneousExecutions"
                                        outlined
                                        dense
                                        :label="$lang.labels.overallSimultaneousExecutions"
                                        required
                                        :rules="[v => !!v || 'Required!', v => v >= 1 || 'Must be greater than 0']"
                                        class="required-asterisk"
                                        :readonly="!userCanEdit"
                                        @change="$refs.form.validate()"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col
                                      cols="12"
                                    >
                                      <v-row wrap no-gutters justify="space-between" align="center">
                                        <v-checkbox v-model="process.isGdprRelevant" hide-details :label="$lang.labels.gdpr"></v-checkbox>
                                        <v-checkbox
                                          v-model="process.isCacheStepLogsEnabled"
                                          hide-details
                                          :label="$lang.labels.isCacheStepLogsEnabled"
                                        ></v-checkbox>
                                        <v-checkbox
                                          v-model="process.isStepLogsEnabled"
                                          hide-details
                                          :label="$lang.labels.isStepLogsEnabled"
                                          @change="process.isStepLogsEnabled ? '' : process.isCacheStepLogsEnabled = false"
                                        ></v-checkbox>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                        </v-row>
                        <v-row no-gutters wrap justify="space-between">
                          <v-col
                            cols="6"
                            class="text-left"
                          >
                            <div class="d-inline-flex align-center">
                              <v-btn
                                class="button-default-width"
                                color="secondary"
                                :to="{ name: 'processes', params: { lang: $lang.current_lang } }"
                              >
                                <v-icon
                                  right
                                  dark
                                  class="ma-0"
                                >
                                  mdi-arrow-left
                                </v-icon>
                                <span class="ml-1">{{ $lang.actions.back }}</span>
                              </v-btn>
                              <action-button-with-confirmation
                                v-if="isEdit"
                                :action-text="$lang.actions.areYouSureYouWantToDelete"
                                :action-text-suffix="process.name"
                                :title="$lang.actions.delete"
                                type="process"
                                :is-disabled="!userCanDelete"
                                :button-text="$lang.actions.delete"
                                :button-color="'error'"
                                :data-cy="'process-delete'"
                                :forced-option="true"
                                :trigger-force-logic="triggerForceLogic"
                                :regular-delete-errors-usages="regularDeleteErrorsUsages"
                                class="ml-2"
                                :delete-success="deleteSuccess"
                                @submit="deleteProcessFunct($event)"
                                @closeDialog="''"
                                @closeAfterDelete="$router.push({ name: 'processes' })"
                              />
                            </div>
                          </v-col>
                          <v-col
                            cols="6"
                            class="text-right"
                          >
                            <v-btn
                              :disabled="!valid || lock || !userCanEdit || process.steps.steps.length === 0"
                              color="primary"
                              class="button-default-width"
                              @click="submit()"
                            >
                              <v-icon
                                left
                                dark
                                class="mr-1"
                              >
                                mdi mdi-floppy
                              </v-icon>
                              {{ isEdit ? $lang.actions.save : $lang.actions.create }}
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="statistics && statistics.eventStatuses && statistics.eventStatuses.length > 0"
                          wrap
                          no-gutters
                          justify="space-between"
                          align="center"
                          class="pb-1 pt-3"
                        >
                          <v-col
                            cols="12"
                          >
                            <v-row
                              wrap
                              no-gutters
                              class="pl-2 pb-1"
                              justify="space-between"
                            >
                              <h4 style="padding-top: 4px">{{ $lang.labels.executions }}</h4>
                              <v-btn
                                icon
                                small
                                color="primary"
                                @click="fetchStatistics()"
                              >
                                <v-icon>mdi-refresh</v-icon>
                              </v-btn>
                            </v-row>
                            <v-data-table
                              :items="statArr"
                              :headers="headersStatArr"
                              item-key="text"
                              class="elevation-0 bland-bg"
                              dense
                              hide-default-footer
                            />
                          </v-col>
                          <v-col
                            cols="12"
                          >
                            <v-row
                              wrap
                              no-gutters
                              class="pl-2 pt-2 pb-1"
                            >
                              <h4>{{ $lang.labels.eventStates }}</h4>
                            </v-row>
                            <v-data-table
                              :items="statistics.eventStatuses"
                              :headers="headersStatistics"
                              item-key="status"
                              class="elevation-0 bland-bg"
                              dense
                              hide-default-footer
                            >

                            </v-data-table>
                          </v-col>
                        </v-row>
                        <v-row
                          v-else
                          wrap
                          no-gutters
                          justify="space-between"
                          class="pb-1 pt-3"
                        >
                          <v-progress-circular v-if="loadingStatistic" indeterminate/>
                          <h4 v-if="!loadingStatistic">{{ $lang.errors.noEvents }}</h4>
                        </v-row>
                      </v-form>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                    :key="1"
                  >
                    <v-card class="pa-2" color="customDrawerBg" style="border-top-left-radius: 0; border-top-right-radius: 0">
                      <v-row wrap no-gutters class="pb-4">
                        <v-col cols="12" class="pb-2">
                          <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                            <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                            <user-roles-select
                              :role="editRolesIds"
                              :options="allRoles"
                              data-cy="roles-edit"
                              :required="false"
                              :readonly="!userCanEdit"
                              @changeRoles="editRolesIds = $event"
                            ></user-roles-select>
                            <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                            <user-roles-select
                              :role="useRolesIds"
                              :options="useRolePool"
                              data-cy="roles-use"
                              :required="false"
                              :readonly="!userCanEdit"
                              @changeRoles="useRolesIds = $event"
                            ></user-roles-select>
                            <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                            <user-roles-select
                              :role="viewRolesIds"
                              :options="viewRolePool"
                              data-cy="roles-view"
                              :required="false"
                              :readonly="!userCanEdit"
                              @changeRoles="viewRolesIds = $event"
                            ></user-roles-select>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                    :key="2"
                  >
                    <v-card class="pa-1" color="customDrawerBg" style="border-top-left-radius: 0; border-top-right-radius: 0">
                      <div style="width: 100%; height: 100%">
                        <v-row dense no-gutters>
                          <v-col cols="12">
                            <Table
                              :items="history"
                              @fetchHistory="fetchHistory($event)"
                              @restoreHistory="restoreProcess($event)"
                            ></Table>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                    v-if="selectedItem"
                    :key="3"
                  >
                    <v-card class="pa-2" color="customDrawerBg" style="border-top-left-radius: 0; border-top-right-radius: 0">
                      <div style="width: 100%">
                        <v-text-field
                          v-if="selectedItem.type"
                          v-model="selectedItem.name"
                          dense
                          color="primary"
                          :label="$lang.labels.stepName"
                          required
                          class="headline mb-2 required-asterisk"
                          :rules="[v => !!v || 'Required!']"
                          :readonly="!userCanEdit"
                          persistent-hint
                          :hint="formatStepHint"
                          data-cy="step-name"
                        ></v-text-field>
                        <h3 v-else-if="selectedItem.subType && selectedItem.subType === 'QUERY'">Query</h3>
                        <h3 v-else-if="selectedItem.subType && selectedItem.subType === 'EXCEPTION'">Exceptions</h3>
                        <step-properties
                          :key="stepPropertiesKey"
                          :can-edit="userCanEdit"
                          :is-new-selected="isNewSelected"
                          :step="selectedItem"
                          :steps="process.steps.steps"
                          :filtered-exceptions="filteredExceptions"
                          @save="submit()"
                          @saveSelected="saveSelected($event)"
                          @showSnack="showSnack($event.text, $event.type)"
                          @err="showError($event)"
                        ></step-properties>
                        <div class="d-inline-flex justify-space-between max-width">
                          <v-checkbox
                            v-if="selectedItem.type"
                            v-model="selectedItem.enabled"
                            hide-details
                            class="pt-1"
                            dense
                            :label="$lang.labels.enabled"
                          ></v-checkbox>
                          <v-checkbox
                            v-if="selectedItem.type"
                            v-model="selectedItem.enableCacheLog"
                            class="pt-1"
                            hide-details
                            dense
                            :label="$lang.labels.enableCacheLog"
                          ></v-checkbox>
                          <v-checkbox
                            v-if="selectedItem.type"
                            v-model="selectedItem.enableStepLog"
                            class="pt-1"
                            hide-details
                            dense
                            :label="$lang.labels.enableStepLog"
                            @change="selectedItem.enableStepLog ? '' : selectedItem.enableCacheLog = false"
                          ></v-checkbox>
                        </div>
                      </div>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackShow"
      :color="snackColor"
      content-class="text-center"
      top
    >
      <span class="color-accent-text">{{ snackbarText }}</span>
    </v-snackbar>
    <v-snackbar
      v-model="isErrorShow"
      color="error"
      timeout="-1"
      top
      content-class="text-center"
    >
      <span class="color-accent-text" v-html="isError"></span>
      <template v-for="(error, i) in errorsNew">
        <p
          :key="i"
          class="error-highlight"
          style="color: black; cursor: pointer; margin-bottom: 8px;"
          @click="openStepFromError(error.value)"
        >{{ error.text }}</p>
      </template>
      <v-btn icon small @click="isErrorShow = false; isError = ''; errorsNew = []">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog v-if="showTypeSelector" v-model="showTypeSelector" max-width="30%">
      <create-dialog
        is-step
        :types="stepTypes"
        @create="createStep"
        @closeDialog="showTypeSelector = false"
      ></create-dialog>
    </v-dialog>
    <v-dialog v-if="yesNoShow" v-model="yesNoShow" max-width="30%">
      <yes-no
        :title="$lang.actions.delete"
        :action-text="doesHaveChildren(selectedDeleteItem) ? $lang.header.sureDeleteStepChildren : $lang.header.sureDeleteStep"
        @submit="deleteStep"
        @closeDialog="selectedDeleteItem = null; yesNoShow = false"
      ></yes-no>
    </v-dialog>
    <v-dialog
      v-if="showCacheDetails"
      v-model="showCacheDetails"
      max-width="50%"
      style="min-height: 80vh; max-height: 80vh"
    >
      <log-cache
        :data="selectedCacheData"
        @closeDialog="selectedCacheData = null; showCacheDetails = false"
      ></log-cache>
    </v-dialog>
    <v-dialog
      v-if="showSelectedEst"
      v-model="showSelectedEst"
      max-width="71%"
      style="min-height: 80vh; max-height: 80vh"
    >
      <log-est :exception="selectedEst" @closeDialog="selectedEst = null; showSelectedEst = false"/>
    </v-dialog>
    <v-dialog
      v-if="showDebugLog"
      v-model="showDebugLog"
      max-width="71%"
      style="min-height: 80vh; max-height: 80vh"
    >
      <log-debug :debug-log-data="debugLogData" @closeDialog="debugLogData = null; showDebugLog = false"/>
    </v-dialog>
    <v-dialog
      v-if="showExceptionDetails"
      v-model="showExceptionDetails"
      max-width="71%"
      style="min-height: 80vh; max-height: 80vh"
    >
      <log-cache-complete
        :event-id="String(selectedEventId)"
        :data="selectedEventData"
        @closeDialog="selectedEventData = null; showExceptionDetails = false"
      ></log-cache-complete>
    </v-dialog>
    <v-dialog v-if="cacheLoading" v-model="cacheLoading" max-width="30%">
      <cache-loading
        :title="$lang.labels.showCache"
        :action-text="$lang.hints.dataTooLArge"
        :loading="cacheInnerLoading"
        @submit="downloadCache()"
        @closeDialog="cacheDownload = null; cacheLoading = false; cacheInnerLoading = false"
      ></cache-loading>
    </v-dialog>
    <v-dialog v-if="showMarkdown" v-model="showMarkdown" max-width="80%" width="80%">
      <markdown
        :markdown-data="process.comment"
        @save="process.comment = $event"
        @closeDialog="showMarkdown = false"
      ></markdown>
    </v-dialog>
    <v-dialog v-if="showImport" v-model="showImport" max-width="80%" width="80%">
      <json-import
        v-if="showImport"
        type="JS"
        @import="showImport = false; importFunction($event)"
        @closeDialog="showImport = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  disableProcessUsingGET as disableProcess,
  enableProcessUsingGET as enableProcess,
  getProcessByIdUsingGET as getProcess,
  updateProcessUsingPUT as updateProcess,
  createProcessUsingPOST as createProcess,
  getRolesUsingGET as getRoles,
  getHistoriesUsingGET as getHistories,
  getProcessStepLogsUsingGET as getLogs,
  getEventByIdUsingGET as getEvent,
  getProcessesUsingGET as getProcesses,
  getProcessStepLogCacheUsingGET as getProcessStepLogCache,
  getProcessStatisticsUsingGET as getStatistics,
  getMostRecentEventIdUsingGET as getEventsLogs,
  deleteProcessUsingDELETE as deleteProcess,
  getValidationRulesUsingGET as getValidations,
  getValidationRuleByIdUsingGET as getValidation,
  getValidationRuleByIdUsingGET as getValidationRule
} from '@/utils/api'
import {
  predictBestTimeUnitFromMsValue
} from '@/utils/helpers'
import addRow from './AddRow'
import stepProperties from './StepProperties'
import CreateDialog from '../../components/ui/modals/DialogCreate'
import YesNo from '@/components/ui/modals/YesNo'
import Markdown from '@/components/ui/modals/Markdown'
import Table from '@/components/ui/tables/HistoryDatatable'
import LogCache from './LogCache'
import LogEst from './LogEst'
import LogDebug from '../../components/ui/modals/LogDebug'
import LogCacheComplete from '../../components/ui/modals/LogCacheEvent'
import CacheLoading from '../../components/ui/modals/Loading'
import { format, sub } from 'date-fns'
import { marked } from 'marked'
import copy from 'copy-to-clipboard'
import ProcessMermaid from '../../components/ui/ProcessMermaid'
import { stepExceptions } from '@/utils/constants'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ToolbarTriggers from '../../components/toolbar/ToolbarTriggers'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import jsonImport from '@/components/ui/modals/JsonImport.vue'
import { mapState } from 'vuex'

export default {
  components: {
    ActionButtonWithConfirmation,
    ToolbarTriggers,
    addRow,
    stepProperties,
    CreateDialog,
    YesNo,
    Table,
    LogCache,
    LogEst,
    LogCacheComplete,
    CacheLoading,
    Markdown,
    LogDebug,
    ProcessMermaid,
    UserRolesSelect,
    jsonImport
  },
  data() {
    return {
      showImport: false,
      mini: true,
      radioButtonsVal: '1',
      mermaidGrouping: false,
      showProcessMermaid: false,
      processMermaidType: false,
      showMarkdown: false,
      showSelectedEst: false,
      selectedEst: null,
      showDebugLog: false,
      debugLogData: null,
      selectedCacheData: null,
      showCacheDetails: false,
      isErrorShow: false,
      isError: '',
      snackbarText: '',
      snackShow: false,
      snackColor: 'success',
      showTypeSelector: false,
      isNewSelected: false,
      tab: 0,
      borderSize: 2,
      err: '',
      success: '',
      valid: false,
      isEdit: false,
      loading: true,
      loadingStatistic: true,
      lock: false,
      process: {
        createdOn: '',
        id: 0,
        modifiedOn: '',
        name: '',
        status: '',
        logsTtlInMSec: '',
        errorsTtlInMSec: '',
        maxProcessingDurationInMSec: '',
        maxSimultaneousExecutions: '1',
        overallSimultaneousExecutions: 1,
        simultaneousExecutionsPerInstance: 1,
        steps: { steps: [] },
        isGdprRelevant: false,
        roles: [],
        isCacheStepLogsEnabled: true,
        isStepLogsEnabled: true,
        comment: '',
        validationRuleId: ''
      },
      timeUnits: ['MSec', 'Sec', 'Min', 'Hr', 'Day'],
      timeUnitPDur: 'Sec',
      timeUnitTtl: 'Hr',
      timeUnitErrTtl: 'Hr',
      tempMaxProcessingDurationInMSec: '60',
      tempLogsTtlInMSec: '24',
      tempErrorsTtlInMSec: '24',
      selectedItem: null,
      tableKey: 0,
      stepTypes: [
        'EMAIL',
        'EXECUTE_PROCESS',
        'FOREACH',
        'GROOVY',
        'JS',
        'JWT',
        'MONGODB',
        'JDBC',
        'REST',
        'SLACK',
        'SWITCH',
        'WHILE',
        'TWILIO',
        'PASTE',
        'PROCESS_SETTING',
        'USER',
        'PLUGIN',
        'S3',
        'UNSET_VARIABLES',
        'SECURITY',
        'IMAP',
        'PDF',
        'CSV',
        'IMAGE',
        'EXECUTE_EXTERNAL_COMMAND',
        'TRY_CATCH',
        'MESSAGING',
        'PAYMENT_SENSE_PAC',
        'PAYMENT_SENSE_CONNECT_E',
        'UUID',
        'CREDENTIAL',
        'QUERY_BUILDER',
        'ZIP',
        'LOG',
        'ENCODER',
        'STORAGE',
        'FORMATTING'
      ].sort(),
      beforeStep: null,
      addIsSpecial: false,
      addIsInner: false,
      addIsAfter: false,
      tryCatch: '',
      newStep: {
        enabled: true,
        id: 0,
        isSelected: true,
        name: '',
        properties: {},
        type: '',
        enableStepLog: true,
        enableCacheLog: true
      },
      newQuery: {
        query: '',
        name: '',
        isSelected: true,
        steps: [],
        subType: 'QUERY'
      },
      newException: {
        exceptions: [],
        name: '',
        isSelected: true,
        steps: [],
        subType: 'EXCEPTION'
      },
      previewLock: false,
      highestId: 0,
      firstKey: 0,
      moveLock: false,
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      yesNoShow: false,
      selectedDeleteItem: null,
      duplicateLock: false,
      dragLock: false,
      history: { items: [], meta: {} },
      isSuperUser: false,
      logs: [],
      logKey: 0,
      selectedLogIndex: null,
      headers: [
        { text: this.$lang.labels.stepId, value: 'stepId' },
        { text: this.$lang.labels.status, value: 'status', sortable: false },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      headersStatArr: [
        { text: this.$lang.header.timeSpan, value: 'text' },
        { text: this.$lang.header.count, value: 'value' }
      ],
      headersStatistics: [
        { text: this.$lang.header.state, value: 'status' },
        { text: this.$lang.header.count, value: 'count' }
      ],
      selectedEventId: '',
      stepLogsSearch: false,
      stepPropertiesKey: 0,
      showExceptionDetails: false,
      selectedEventData: null,
      selectedExceptionData: null,
      idIncreaseItem: null,
      foundLogsSteps: {},
      cacheLoading: false,
      cacheInnerLoading: false,
      cacheDownload: null,
      statistics: null,
      statArr: [],
      errorItems: [],
      errorsNew: [],
      timeSpanArr: [1, 2, 4, 8, 12, 16, 20, 24],
      lastEventsLogsLoader: false,
      autoRefreshTimeout: null,
      loadingAutoRefresh: false,
      mermaidId: 0,
      filteredExceptions: {},
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      ttlLogsHint: false,
      ttlErrLogsHint: false,
      panelIndex: [],
      searchValidations: '',
      isLoadingValidations: false,
      validations: []
    }
  },
  computed: {
    ...mapState('app', ['userSettings']),
    compiledMarkdown: function () {
      return marked.parse(this.process?.comment) || ''
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.process.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.process.modifiedOn)}, ID: ${this.process.id}`
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    formatStepHint() {
      return `${this.$lang.labels.type}: ${this.$lang.status[this.selectedItem.type]}, ${this.$lang.labels.stepId}: ${this.selectedItem && this.selectedItem.id ? this.selectedItem.id : ''}, ${this.$lang.labels.visualId}: ${this.selectedItem && this.selectedItem.localId ? this.selectedItem.localId : ''}`
    }
  },
  watch: {
    searchValidations: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchValidationsFunction(val)
        }
      }
    },
    selectedLogIndex: {
      handler(val) {
        this.logs.map((x, i) => {
          x.isSelected = val === i

          return x
        })
        this.selectStepFromLog(this.foundLogsSteps[this.logs[val].stepId] || 'Not present')
        const rowElem = document.getElementById(`row-${this.foundLogsSteps[this.logs[val].stepId]}-${val}`)
        const stepElem = document.getElementById(`select-${this.foundLogsSteps[this.logs[val].stepId]}`)

        if (stepElem) {
          stepElem.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
          })
        }

        rowElem.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        })
      }
    },
    radioButtonsVal: {
      handler(val) {
        if (val === '1') {
          this.showProcessMermaid = false
          this.processMermaidType = false
        }
        if (val === '2') {
          this.showProcessMermaid = true
          this.processMermaidType = false
        }
        if (val === '3') {
          this.showProcessMermaid = true
          this.processMermaidType = true
        }
      }
    }
  },
  mounted() {
    this.setBorderWidth()
    this.setEvents()
  },
  async created() {
    this.isEdit = this.$route.name === 'processEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'PROCESS_DELETER') || this.isSuperUser
    }

    if (this.userSettings.display.showStepType) {
      this.headers.splice(1, 0, { text: this.$lang.labels.stepType, value: 'stepType' })
    }

    if (this.userSettings.display.showFinishedOn) {
      this.headers.splice(1, 0, { text: this.$lang.labels.finishedOn, value: 'finishedOn' })
    }

    this.init()
      .then(async () => {
        if (this.isEdit && this.$route.params.id) {
          this.loading = true
          getProcess({ id: this.$route.params.id })
            .then(async (res) => {
              this.process = res.data.data

              this.editRolesIds = this.process.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
              this.useRolesIds = this.process.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
              this.viewRolesIds = this.process.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

              this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

              if (this.process.validationRuleId) {
                getValidation({ id: this.process.validationRuleId })
                  .then((res) => {
                    this.validations = [res.data.data]
                    this.searchValidations = res.data.data.name
                  })
                  .catch((err) => {
                    this.err = err
                  })
              }

              if (this.process.comment === null) {
                this.process.comment = ''
              }

              if (this.process.comment !== '') {
                this.panelIndex = [0]
              }

              if (this.process.maxProcessingDurationInMSec !== null) {
                const unitResPDur = await predictBestTimeUnitFromMsValue(this.process.maxProcessingDurationInMSec)

                this.timeUnitPDur = unitResPDur.unit
                this.tempMaxProcessingDurationInMSec = unitResPDur.value
              }
              if (this.process.logsTtlInMSec !== null) {
                const unitResTtl = await predictBestTimeUnitFromMsValue(this.process.logsTtlInMSec)

                this.timeUnitTtl = unitResTtl.unit
                this.tempLogsTtlInMSec = unitResTtl.value
              }

              if (this.process.errorsTtlInMSec !== null) {
                const unitResErrTtl = await predictBestTimeUnitFromMsValue(this.process.errorsTtlInMSec)

                this.timeUnitErrTtl = unitResErrTtl.unit
                this.tempErrorsTtlInMSec = unitResErrTtl.value
              }

              // eslint-disable-next-line no-prototype-builtins
              if (this.process && !this.process.hasOwnProperty('isCacheStepLogsEnabled ')) {
                this.isCacheStepLogsEnabled = true
              }
              // eslint-disable-next-line no-prototype-builtins
              if (this.process && !this.process.hasOwnProperty('isStepLogsEnabled ')) {
                this.isStepLogsEnabled = true
              }

              await this.previewData({ id: null }, '', null, '')

              this.loading = false
              this.loadingStatistic = false

              if (this.$route.query && this.$route.query.eventId) {

                if (!isNaN(Number(this.$route.query.eventId))) {
                  this.selectedEventId = this.$route.query.eventId

                  this.fetchLogs(this.selectedEventId)
                }
              }

              if (window.location.hash) {
                const localSelectedItem = window.location.hash.replace('#', '')

                setTimeout(() => {
                  this.selectStepFromLog(localSelectedItem)
                }, 500)
              }

              getStatistics({ id: this.$route.params.id })
                .then((res) => {
                  this.statistics = res.data.data
                  this.statArr = [
                    {
                      text: this.$lang.labels.last15MinutesCount,
                      value: this.statistics.last15MinutesCount
                    },
                    {
                      text: this.$lang.labels.last60MinutesCount,
                      value: this.statistics.last60MinutesCount
                    },
                    {
                      text: this.$lang.labels.last24HoursCount,
                      value: this.statistics.last24HoursCount
                    },
                    {
                      text: this.$lang.labels.todayCount,
                      value: this.statistics.todayCount
                    }
                  ]
                  this.loadingStatistic = false
                })
                .catch((err) => {
                  this.err = err
                  this.loadingStatistic = false
                })

              this.fetchStatistics()
            })
            .catch((err) => {
              this.err = err
            })
        } else {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          if (this.$route.params.copy) {
            getProcess({ id: this.$route.params.copy.id })
              .then(async (res) => {
                this.process = res.data.data

                this.process.name = `${res.data.data.name} - Copy - ${this.$options.filters.formatDateTime(new Date())}`

                if (this.process.comment === null) {
                  this.process.comment = ''
                }

                if (this.process.comment !== '') {
                  this.panelIndex = [0]
                }

                if (this.process.maxProcessingDurationInMSec !== null) {
                  const unitResPDur = await predictBestTimeUnitFromMsValue(this.process.maxProcessingDurationInMSec)

                  this.timeUnitPDur = unitResPDur.unit
                  this.tempMaxProcessingDurationInMSec = unitResPDur.value
                }
                if (this.process.logsTtlInMSec !== null) {
                  const unitResTtl = await predictBestTimeUnitFromMsValue(this.process.logsTtlInMSec)

                  this.timeUnitTtl = unitResTtl.unit
                  this.tempLogsTtlInMSec = unitResTtl.value
                }

                if (this.process.errorsTtlInMSec !== null) {
                  const unitResErrTtl = await predictBestTimeUnitFromMsValue(this.process.errorsTtlInMSec)

                  this.timeUnitErrTtl = unitResErrTtl.unit
                  this.tempErrorsTtlInMSec = unitResErrTtl.value
                }

                // eslint-disable-next-line no-prototype-builtins
                if (this.process && !this.process.hasOwnProperty('isCacheStepLogsEnabled ')) {
                  this.isCacheStepLogsEnabled = true
                }
                // eslint-disable-next-line no-prototype-builtins
                if (this.process && !this.process.hasOwnProperty('isStepLogsEnabled ')) {
                  this.isStepLogsEnabled = true
                }

                this.previewData({ id: null }, '', null, '')

                this.loading = false
                this.loadingStatistic = false
              })
              .catch((err) => {
                this.err = err
                this.loading = false
                this.loadingStatistic = false
              })
          } else if (this.$route.params.copyFromMemory) {
            if (navigator.clipboard) {
              navigator.permissions.query({ name: 'clipboard-read' }).then((result) => {
                if (result.state === 'granted' || result.state === 'prompt') {
                  navigator.clipboard.readText()
                    .then(async (text) => {

                      try {
                        const processLocal = JSON.parse(text)

                        this.success = this.validateDataStructure(processLocal).message
                        setTimeout(() => {
                          this.success = ''
                        }, 5000)

                        if (this.validateDataStructure(processLocal).success) {
                          await this.importFunction(processLocal)
                        } else {
                          this.err = this.validateDataStructure(processLocal).message
                          setTimeout(() => {
                            this.err = ''
                          }, 6000)
                        }

                        this.loading = false
                        this.loadingStatistic = false
                      } catch (e) {
                        this.err = `Invalid JSON: ${e}`
                        this.loading = false
                        this.loadingStatistic = false
                        setTimeout(() => {
                          this.err = ''
                        }, 6000)
                      }
                    })
                    .catch((err) => {
                      this.err = `Failed to read clipboard contents: ${err}`
                      this.loading = false
                      this.loadingStatistic = false
                      setTimeout(() => {
                        this.err = ''
                      }, 6000)
                    })
                }
              })
            } else {
              this.err = 'Clipboard not available in browser, please check if you have granted access to clipboard in your browser settings.'
              setTimeout(() => {
                this.err = ''
              }, 6000)
            }
          } else if (this.$route.params.copyFromModal) {
            this.showImport = true
            this.loading = false
            this.loadingStatistic = false
          } else {
            this.loading = false
            this.loadingStatistic = false
          }
        }
      })
  },
  beforeDestroy() {
    clearTimeout(this.autoRefreshTimeout)
    this.autoRefreshTimeout = null
  },
  methods: {
    openValidation(id) {
      window.open(`/${localStorage.selectedLanguage || 'en'}/validations/edit/${id}`, '_blank')
    },
    async importFunction(dataJson) {
      this.process = dataJson

      if (this.process.comment === null) {
        this.process.comment = ''
      }

      if (this.process.comment !== '') {
        this.panelIndex = [0]
      }

      if (this.process.maxProcessingDurationInMSec !== null) {
        const unitResPDur = await predictBestTimeUnitFromMsValue(this.process.maxProcessingDurationInMSec)

        this.timeUnitPDur = unitResPDur.unit
        this.tempMaxProcessingDurationInMSec = unitResPDur.value
      }
      if (this.process.logsTtlInMSec !== null) {
        const unitResTtl = await predictBestTimeUnitFromMsValue(this.process.logsTtlInMSec)

        this.timeUnitTtl = unitResTtl.unit
        this.tempLogsTtlInMSec = unitResTtl.value
      }

      if (this.process.errorsTtlInMSec !== null) {
        const unitResErrTtl = await predictBestTimeUnitFromMsValue(this.process.errorsTtlInMSec)

        this.timeUnitErrTtl = unitResErrTtl.unit
        this.tempErrorsTtlInMSec = unitResErrTtl.value
      }

      // eslint-disable-next-line no-prototype-builtins
      if (this.process && !this.process.hasOwnProperty('isCacheStepLogsEnabled ')) {
        this.isCacheStepLogsEnabled = true
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.process && !this.process.hasOwnProperty('isStepLogsEnabled ')) {
        this.isStepLogsEnabled = true
      }

      this.previewData({ id: null }, '', null, '')
    },
    validateDataStructure(data) {
      // Check if data has all required fields
      const requiredFields = [
        'id',
        'name',
        'steps',
        'isGdprRelevant',
        'isStepLogsEnabled',
        'isCacheStepLogsEnabled'
      ]

      for (const field of requiredFields) {
        if (!Object.prototype.hasOwnProperty.call(data, field)) {
          return {
            success: false,
            message: `Missing required field: ${field}`
          }
        }
      }

      // Check if steps has all required fields
      const stepsRequiredFields = [
        'scriptEngine',
        'steps'
      ]

      const { steps } = data

      for (const field of stepsRequiredFields) {
        if (!Object.prototype.hasOwnProperty.call(steps, field)) {
          return {
            success: false,
            message: `Missing required field in 'steps': ${field}`
          }
        }
      }

      // Check if scriptEngine is 'JS'
      if (steps.scriptEngine !== 'JS') {
        return {
          success: false,
          message: 'Property \'scriptEngine\' in \'steps\' must be \'JS\''
        }
      }

      // All checks passed
      return {
        success: true,
        message: 'Data structure is valid'
      }
    },
    searchValidationsFunction(val = '') {

      this.isLoadingValidations = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getValidations(obj)
        .then((res) => {
          this.validations = res.data.data.items
          this.isLoadingValidations = false
        })
        .catch((err) => {
          this.isLoadingValidations = false
          this.err = err
        })
    },
    deleteProcessFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteProcess({ id: this.$route.params.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.err = err
        })
    },
    async filterStepExceptions(tryCatchStep) {
      let allSelectedExceptions = []

      const formatSteps = (items) => {
        for (const item of items) {

          if (item.type && stepExceptions[item.type]) allSelectedExceptions = [...allSelectedExceptions, ...stepExceptions[item.type]]

          if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
            formatSteps(item.properties.steps)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0) {
            formatSteps(item.properties.try.steps)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0) {
            formatSteps(item.properties.finally.steps)
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
            item.properties.catch.forEach((item2) => {
              formatSteps(item2.steps)
            })
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
            for (const item2 of item.properties.conditions) {
              formatSteps(item2.steps)
            }
          }
        }
      }

      if (tryCatchStep.properties?.try?.steps?.length > 0) {
        await formatSteps(tryCatchStep.properties.try.steps)
      }

      tryCatchStep.properties?.catch?.forEach((item) => {
        this.filteredExceptions[item.localId] = [...new Set(allSelectedExceptions)]
        this.filteredExceptions[item.localId].push('ALL')
      })
    },
    async filterTryCatchSteps() {

      const formatSteps = (items) => {
        for (const item of items) {

          if (item.type === 'TRY_CATCH') {
            this.filterStepExceptions(item)
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
            formatSteps(item.properties.steps)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0) {
            formatSteps(item.properties.try.steps)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0) {
            formatSteps(item.properties.finally.steps)
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
            item.properties.catch.forEach((item2) => {
              formatSteps(item2.steps)
            })
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
            for (const item2 of item.properties.conditions) {
              formatSteps(item2.steps)
            }
          }
        }
      }

      await formatSteps(this.process.steps.steps)
    },
    goToStep(stepId) {
      this.findStepByMermaidId(stepId)
    },
    async findStepByMermaidId(mermaidId) {
      let found = null
      let maybeTryCatch = ''

      const formatSteps = (items) => {
        for (const item of items) {
          if (item.mermaidId === Number(mermaidId)) {
            found = JSON.parse(JSON.stringify(item))
          }

          if (item.type === 'TRY_CATCH') {
            if (item.properties?.try?.mermaidId === Number(mermaidId)) {
              found = JSON.parse(JSON.stringify(item))
              maybeTryCatch = 'try'
            }
            if (item.properties?.finally?.mermaidId === Number(mermaidId)) {
              found = JSON.parse(JSON.stringify(item))
              maybeTryCatch = 'finally'
            }
            if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
              // eslint-disable-next-line
              item.properties.catch.forEach((item2) => {
                if (item2.mermaidId === Number(mermaidId)) {
                  found = JSON.parse(JSON.stringify(item2))
                  maybeTryCatch = 'catch'
                }
              })
            }
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
            formatSteps(item.properties.steps)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0) {
            formatSteps(item.properties.try.steps)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0) {
            formatSteps(item.properties.finally.steps)
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
            item.properties.catch.forEach((item2) => {
              formatSteps(item2.steps)
            })
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
            for (const item2 of item.properties.conditions) {
              if (item2.mermaidId === Number(mermaidId)) {
                found = JSON.parse(JSON.stringify(item2))
              }
              formatSteps(item2.steps)
            }
          }
        }
      }

      await formatSteps(this.process.steps.steps)

      if (found) this.previewData(found, '', null, '', maybeTryCatch)
    },
    iconColorChanger() {
      if (this.selectedEventData && ['FAILED', 'PROCESS_INACTIVE', 'TIMEOUT'].includes(this.selectedEventData.status)) {
        return 'error'
      } else if (this.selectedEventData && ['IN_PROGRESS', 'NEW'].includes(this.selectedEventData.status)) {
        return 'info'
      } else if (this.selectedEventData && ['ON_HOLD'].includes(this.selectedEventData.status)) {
        return 'warning'
      } else if (this.selectedEventData && ['SUCCESSFUL'].includes(this.selectedEventData.status)) {
        return 'success'
      } else {
        return 'transparent'
      }
    },
    copyProcessName() {
      if (this.process.name) {
        copy(this.process.name)
        this.showSnack(this.$lang.success.copiedClipboard, 'success')
      } else {
        this.showSnack(this.$lang.errors.nothingToCopy, 'warning')
      }
    },
    fetchEventsLogs() {
      const obj = {
        processId: this.process.id
      }

      this.lastEventsLogsLoader = true

      getEventsLogs(obj)
        .then((res) => {
          if (res?.data?.data?.eventId) {
            this.selectedEventId = res.data.data.eventId
            this.lastEventsLogsLoader = false
            this.fetchLogs(res.data.data.eventId)
          } else {
            this.lastEventsLogsLoader = false
          }
        })
        .catch((error) => {
          this.lastEventsLogsLoader = false
          console.log(error)
        })
    },
    fetchStatistics() {
      this.statistics = null
      this.loadingStatistic = true
      getStatistics({ id: this.$route.params.id })
        .then((res) => {
          this.statistics = res.data.data
          this.statArr = [
            {
              text: this.$lang.labels.last15MinutesCount,
              value: this.statistics.last15MinutesCount
            },
            {
              text: this.$lang.labels.last60MinutesCount,
              value: this.statistics.last60MinutesCount
            },
            {
              text: this.$lang.labels.last24HoursCount,
              value: this.statistics.last24HoursCount
            },
            {
              text: this.$lang.labels.todayCount,
              value: this.statistics.todayCount
            }
          ]
          this.loadingStatistic = false
          setTimeout(() => this.loading = false, 200)
        })
        .catch((err) => {
          this.err = err
          this.loadingStatistic = false
          setTimeout(() => this.loading = false, 200)
        })
    },
    showError(text) {
      this.err = text
      setTimeout(() => this.err = '', 5000)
    },
    download(content, fileName) {
      const file = new Blob([content],
        {
          type: 'application/json;charset=UTF-8'
        })

      const reader = new FileReader()

      reader.onload = function () {
        const popup = window.open()

        const link = document.createElement('a')

        link.setAttribute('href', reader.result)
        link.setAttribute('download', fileName)
        popup.document.body.appendChild(link)
        link.click()
      }
      reader.readAsDataURL(file)
    },
    downloadCache() {
      if (this.cacheDownload) {
        this.cacheLoading = false
        this.cacheInnerLoading = false
        this.download(this.cacheDownload, 'Cache.json')
      }
    },
    openLogCache(id) {
      this.cacheLoading = true
      this.cacheInnerLoading = true
      this.cacheDownload = null
      getProcessStepLogCache({ id })
        .then((res) => {
          if (res && res.data && res.data.data && res.data.data.cache) {
            const size = new TextEncoder().encode(JSON.stringify(res.data.data.cache)).length
            const kiloBytes = size / 1024

            if (kiloBytes < 2048) {
              this.cacheLoading = false
              this.cacheInnerLoading = false
              this.selectedCacheData = res.data.data.cache
              this.showCacheDetails = true
            } else {
              this.cacheInnerLoading = false
              this.cacheDownload = JSON.stringify(res.data.data.cache)
            }
          } else {
            this.cacheLoading = false
            this.cacheInnerLoading = false
            this.cacheDownload = null
            this.showSnack(this.$lang.errors.noCache, 'warning')
            setTimeout(() => this.success = '', 5000)
          }
        })
        .catch((error) => {
          this.err = error
          setTimeout(() => this.err = '', 5000)
          console.log(error)
        })
    },
    openProcessLogs() {
      const dateFrom = format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd')
      const dateTill = format(new Date(), 'yyyy-MM-dd')

      const url = this.$router.resolve({
        name: 'logs-processes',
        query: { processId: String(this.process.id), dateFrom, dateTill }
      })

      window.open(url.href, '_blank')
    },
    openProcessEvents() {
      const dateFrom = format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd')
      const dateTill = format(new Date(), 'yyyy-MM-dd')

      const url = this.$router.resolve({
        name: 'logs-events',
        query: { processId: String(this.process.id), dateFrom, dateTill }
      })

      window.open(url.href, '_blank')
    },
    fetchEvent() {
      if (!this.selectedEventId) return

      getEvent({ id: this.selectedEventId })
        .then((res) => {
          this.selectedEventData = res.data.data
          this.showExceptionDetails = true
        })
        .catch((error) => {
          this.err = error
          setTimeout(() => this.err = '', 5000)
          console.log(error)
        })
    },
    fetchEventAutoRefresh(eventId) {
      getEvent({ id: eventId })
        .then((res) => {
          this.selectedEventData = res.data?.data || null
          if (['IN_PROGRESS', 'NEW', 'ON_HOLD'].includes(res.data?.data?.status)) {
            this.loadingAutoRefresh = true
            this.autoRefreshTimeout = setTimeout(() => this.fetchLogs(eventId), 5000)
          } else {
            this.loadingAutoRefresh = false
            clearTimeout(this.autoRefreshTimeout)
          }
        })
        .catch((error) => {
          this.err = error
          setTimeout(() => this.err = '', 5000)
          console.log(error)
        })
    },
    fetchLogs(eventId) {
      this.fetchEventAutoRefresh(eventId)

      const obj = {
        eventId: eventId,
        processId: this.$route.params.id
      }

      getLogs(obj)
        .then((res) => {
          this.logs = res.data?.data?.items.map((x) => {
            x.isSelected = false

            return x
          }) || []
          this.logs.forEach((x) => {
            this.visualIdSearch(x.stepId)
          })
          if (this.logs.length === 0) {
            if (window.location.hash) {
              window.history.replaceState(null, '', `?eventId=${window.location.hash}`)
            } else {
              window.history.replaceState(null, '', '?eventId=')
            }
          } else {
            if (window.location.hash) {
              window.history.replaceState(null, '', `?eventId=${eventId}${window.location.hash}`)
            } else {
              window.history.replaceState(null, '', `?eventId=${eventId}`)
            }
          }
          this.stepLogsSearch = true
        })
        .catch((error) => {
          this.err = error
          setTimeout(() => this.err = '', 5000)
          console.log(error)
        })
    },
    selectedRow(item) {

      return `clickable-simple ${item.isSelected ? this.$vuetify.theme.dark ? 'is-log-selected-dark' : 'is-log-selected-light' : ''} animate__animated animate__bounceIn`
    },
    selectTableRow(item, data) {
      this.selectedLogIndex = data.index
    },
    selectProcessName() {
      this.mini = true
      this.tab = 0
      this.selectedItem = null
      this.previewData({ id: null }, '', null, '')

      setTimeout(() => this.$refs.processName.focus(), 200)
    },
    fetchHistory(options) {
      if (!this.process.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.process.id
      obj.resourceType = 'PROCESS'

      getHistories(obj)
        .then((res) => {
          this.history = res.data.data
        })
        .catch((err) => {
          this.err = err
        })
    },
    init() {
      return new Promise((resolve) => {
        getRoles()
          .then((res) => {
            this.allRoles = res.data.data.items
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    async pasteStep(inputItem) {

      this.idIncreaseItem = null
      const pasteableItem = localStorage.itemStorage ? JSON.parse(localStorage.itemStorage) : null

      await this.idIncrease(pasteableItem)

      this.loading = true

      setTimeout(async () => {
        if (this.idIncreaseItem) {
          const pasteStep = (items, itemWhereToPaste) => {
            items.forEach((item, index) => {

              if (item.localId === itemWhereToPaste.localId) {
                const tempID = item.id ? item.id : null

                if (tempID) {
                  items[index] = { ...this.idIncreaseItem, id: tempID }
                } else {
                  items[index] = { ...this.idIncreaseItem }
                }

                this.previewData({ id: null }, '', null, '')

                this.loading = false

                return
              }

              if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
                pasteStep(item.properties.steps, itemWhereToPaste)
              } else if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
                item.properties.conditions.forEach((item2, index2) => {
                  if (inputItem.subType && inputItem.subType === 'QUERY') {
                    if (item2.localId === itemWhereToPaste.localId) {

                      if (!this.idIncreaseItem.subType) {
                        this.showSnack(this.$lang.errors.wrongPaste, 'error')

                        return
                      }

                      item.properties.conditions[index2] = {
                        ...this.idIncreaseItem,
                        query: `${this.idIncreaseItem.query} - COPIED Q${this.idIncreaseItem.localId}`
                      }

                      this.previewData({ id: null }, '', null, '')

                      return
                    }
                  }
                  pasteStep(item2.steps, itemWhereToPaste)
                })
              }
            })
          }

          await pasteStep(this.process.steps.steps, inputItem)

          this.loading = false
        } else {
          this.showSnack(this.$lang.errors.noPaste, 'error')
        }
      }, 400)
    },
    async idIncrease(item) {
      this.idIncreaseItem = JSON.parse(JSON.stringify(item))
      let localHighestId = this.highestId

      if (this.idIncreaseItem.id) {
        this.idIncreaseItem.id = localHighestId + 1
        localHighestId++
      }

      const idIncrease = (items) => {
        items.forEach((item2) => {

          item2.id = localHighestId + 1
          localHighestId++

          if (item2.properties && item2.properties.steps && item2.properties.steps.length > 0) {
            idIncrease(item2.properties.steps)
          }
          if (item2.properties && item2.properties.conditions && item2.properties.conditions.length > 0) {
            item2.properties.conditions.forEach((item3) => {
              idIncrease(item3.steps)
            })
          }
          if (item2.properties?.try?.steps && item2.properties.try.steps.length > 0) {
            idIncrease(item2.properties.item.properties.try.steps)
          }
          if (item2.properties && item2.properties.catch && item2.properties.catch.length > 0) {
            item2.properties.catch.forEach((item2) => {
              idIncrease(item2.steps)
            })
          }
        })
      }

      if (this.idIncreaseItem.properties && this.idIncreaseItem.properties.steps && this.idIncreaseItem.properties.steps.length > 0) {
        idIncrease(this.idIncreaseItem.properties.steps)
      } else if (this.idIncreaseItem.properties && this.idIncreaseItem.properties.conditions && this.idIncreaseItem.properties.conditions.length > 0) {
        this.idIncreaseItem.properties.conditions.forEach((itemA) => {
          idIncrease(itemA.steps)
        })
      } else if (this.idIncreaseItem.steps && this.idIncreaseItem.steps.length > 0) {
        idIncrease(this.idIncreaseItem.steps)
      }
      if (this.idIncreaseItem.type === 'TRY_CATCH') {
        if (this.idIncreaseItem.properties?.try?.steps) idIncrease(this.idIncreaseItem.properties.try.steps)
        if (this.idIncreaseItem.properties?.catch) this.idIncreaseItem.properties.catch.forEach((item2) => {
          idIncrease(item2.steps)
        })
      }
    },
    async duplicateStep(inputItem) {
      this.duplicateLock = false

      const duplicateStep = (items, duplicateItem) => {
        items.forEach((item, index) => {

          if (item.localId === duplicateItem.localId && !this.duplicateLock) {
            this.duplicateLock = true

            items.splice(index + 1, 0, { ...JSON.parse(JSON.stringify(duplicateItem)) })

            this.previewData({ id: null }, '', null, '')

            return
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
            duplicateStep(item.properties.steps, duplicateItem)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0) {
            duplicateStep(item.properties.try.steps, duplicateItem)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0) {
            duplicateStep(item.properties.finally.steps, duplicateItem)
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
            item.properties.catch.forEach((item2, index2) => {
              if (inputItem.subType && inputItem.subType === 'EXCEPTION') {
                if (item2.localId === duplicateItem.localId && !this.duplicateLock) {
                  this.duplicateLock = true

                  item.properties.catch.splice(index2 + 1, 0, {
                    ...JSON.parse(JSON.stringify(duplicateItem)),
                    exceptions: []
                  })

                  this.previewData({ id: null }, '', null, '')

                  return
                }
              }
              duplicateStep(item2.steps, duplicateItem)
            })
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
            item.properties.conditions.forEach((item2, index2) => {
              if (inputItem.subType && inputItem.subType === 'QUERY') {
                if (item2.localId === duplicateItem.localId && !this.duplicateLock) {
                  this.duplicateLock = true

                  item.properties.conditions.splice(index2 + 1, 0, {
                    ...JSON.parse(JSON.stringify(duplicateItem)),
                    query: `${duplicateItem.query} - COPIED Q${item2.localId}`
                  })

                  this.previewData({ id: null }, '', null, '')

                  return
                }
              }
              duplicateStep(item2.steps, duplicateItem)
            })
          }
        })
      }

      this.idIncreaseItem = null
      await this.idIncrease(inputItem)
      setTimeout(() => {
        duplicateStep(this.process.steps.steps, this.idIncreaseItem)
      }, 200)
    },
    dragSteps(newSteps) {

      this.dragLock = true

      this.process.steps.steps = newSteps

      this.previewData({ id: null }, '', null, '')
    },
    showSnack(text, color = 'success') {
      this.snackbarText = text
      this.snackColor = color
      this.snackShow = true
    },
    doesHaveChildren(item) {
      if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) return true
      if (item.properties && item.properties.steps && item.properties.steps.length > 0) return true
      if (item.properties && item.properties.try && item.properties.try.steps && item.properties.try.steps.length > 0) return true

      return !!(item.properties && item.properties.finally && item.properties.finally.steps && item.properties.finally.steps.length > 0)
    },
    async deleteStep() {
      const deleteStep = (items, deleteItem) => {
        items.forEach((item, index) => {

          if (String(item.localId) === String(deleteItem.localId)) {
            items.splice(index, 1)

            this.previewData({ id: null }, '', null, '')

            return
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
            deleteStep(item.properties.steps, deleteItem)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0) {
            deleteStep(item.properties.try.steps, deleteItem)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0) {
            deleteStep(item.properties.finally.steps, deleteItem)
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
            deleteStep(item.properties.catch, deleteItem)
            item.properties.catch.forEach((item2) => {
              deleteStep(item2.steps, deleteItem)
            })
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
            deleteStep(item.properties.conditions, deleteItem)
            item.properties.conditions.forEach((item2) => {
              deleteStep(item2.steps, deleteItem)
            })
          }
        })
      }

      await deleteStep(this.process.steps.steps, this.selectedDeleteItem)

      this.yesNoShow = false
    },
    compare(a, b) {
      if (a.finishedOn < b.finishedOn) {
        return -1
      }
      if (a.finishedOn > b.finishedOn) {
        return 1
      }

      return 0
    },
    saveSelected(item) {
      this.isNewSelected = false
      this.selectedItem = item
    },
    createStep(type) {
      this.previewLock = false
      this.previewData({ id: this.highestId + 1 }, 'add', this.beforeStep ? this.beforeStep.id : 0, type)
      this.showTypeSelector = false
    },
    addStep(data) {
      this.beforeStep = data.beforeItem
      this.addIsSpecial = data.isSpecial
      this.addIsInner = data.innerStep
      this.addIsAfter = data.isAfter
      this.tryCatch = data.tryCatch
      if (data.isSpecial) {
        this.previewLock = false
        this.previewData({ id: this.highestId + 1 }, 'add', this.beforeStep.id, '')
      } else {
        this.showTypeSelector = true
      }
    },
    moveStep(data) {
      this.moveItemsAround(data.item, data.direction)
    },
    async restoreProcess(data) {
      this.loading = true
      this.process = data

      if (!this.process.comment) this.process.comment = ''

      if (this.process && this.process.roles) {
        this.editRolesIds = this.process.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.process.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.process.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
      }

      if (this.process && this.process.maxProcessingDurationInMSec !== null) {
        const unitResPDur = await predictBestTimeUnitFromMsValue(this.process.maxProcessingDurationInMSec)

        this.timeUnitPDur = unitResPDur.unit
        this.tempMaxProcessingDurationInMSec = unitResPDur.value
      }
      if (this.process && this.process.logsTtlInMSec !== null) {
        const unitResTtl = await predictBestTimeUnitFromMsValue(this.process.logsTtlInMSec)

        this.timeUnitTtl = unitResTtl.unit
        this.tempLogsTtlInMSec = unitResTtl.value
      }

      if (this.process && this.process.errorsTtlInMSec !== null) {
        const unitResErrTtl = await predictBestTimeUnitFromMsValue(this.process.errorsTtlInMSec)

        this.timeUnitErrTtl = unitResErrTtl.unit
        this.tempErrorsTtlInMSec = unitResErrTtl.value
      }

      this.previewData({ id: null }, '', null, '')
      this.loading = false
    },
    // PREVIEW METHOD
    async previewData(defaultItem = null, operation = '', id = null, type = '', isInnerTryCatch = '') {
      this.mini = true
      this.isNewSelected = true
      this.selectedItem = null
      let itemToPaste = null

      this.mermaidId = 0

      if (operation && operation === 'add') this.highestId = this.highestId + 1

      if (type === 'PASTE') {
        const itemToPasteTemp = localStorage.itemStorage ? JSON.parse(localStorage.itemStorage) : null

        if (!itemToPasteTemp) {
          this.showSnack(this.$lang.errors.noPaste, 'error')

          return
        }

        if (itemToPasteTemp.subType) {
          this.showSnack(this.$lang.errors.wrongPaste, 'error')

          return
        }
        this.idIncreaseItem = null
        await this.idIncrease(itemToPasteTemp)
        itemToPaste = this.idIncreaseItem
      }

      const formatSteps = (items, status = false, parentIndex, firstLevel = false, insideIsInnerTryCatch = '') => {
        if (items && items.length === 0) {
          if (operation && operation === 'add' && !this.addIsSpecial) {
            if (type === 'EMAIL') {
              this.newStep.properties.variables = {
                subject: ''
              }
            } else if (type === 'USER') {
              this.newStep.properties.fields = {
                email: '',
                name: '',
                roles: '',
                targetObject: '',
                apiKey: '',
                password: ''
              }
            } else if (type === 'S3') {
              this.newStep.properties.fields = {
                targetObject: '',
                path: '',
                key: '',
                content: ''
              }
            } else if (type === 'REST') {
              this.newStep.properties.headers = {
                'Content-Type': ''
              }
            } else if (type === 'IMAP') {
              this.newStep.properties.fields = {
                body: '',
                maxResults: '',
                readType: '',
                recipient: '',
                sender: '',
                subject: '',
                emailId: ''
              }
            } else if (type === 'CSV') {
              this.newStep.properties.includeHeaders = true
            } else if (type === 'STORAGE') {
              this.newStep.properties = {
                action: 'GET',
                scriptEngine: 'JS',
                fields: {
                  targetObject: ''
                }
              }
            } else if (type === 'ENCODER') {
              this.newStep.properties = {
                action: 'ENCODE_HTML_SPECIAL_CHARS',
                converts: []
              }
            } else {
              this.newStep.properties = {}
            }

            if (id === 0 && !this.previewLock) {
              this.previewLock = true
              if (itemToPaste && !itemToPaste.subType) {
                this.process.steps.steps.push({ ...itemToPaste, id: 1, localId: 1 })
              } else {
                this.process.steps.steps.push({
                  ...JSON.parse(JSON.stringify(this.newStep)),
                  id: 1,
                  type,
                  localId: 1,
                  name: '1'
                })
              }
              this.selectedItem = { ...JSON.parse(JSON.stringify(this.newStep)), id: 1, type, localId: 1, name: '1' }
              this.beforeStep = null
            }
          }

          return
        }

        items.forEach((item, index) => {

          if (item.id && item.id > this.highestId) this.highestId = item.id

          item.localId = firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
          item.mermaidId = this.mermaidId + 1
          this.mermaidId++
          if (item.name === '') item.name = firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
          if (item.type === 'TRY_CATCH') {
            if (item.properties?.try?.name === '') item.properties.try.name = `T${parentIndex}`
            if (item.properties?.try) {
              item.properties.try.localId = `T${parentIndex}`
              item.properties.try.mermaidId = this.mermaidId + 1
              this.mermaidId++
            }
            if (item.properties?.finally?.name === '') item.properties.finally.name = `F${parentIndex}`
            if (item.properties?.finally) {
              item.properties.finally.localId = `F${parentIndex}`
              item.properties.finally.mermaidId = this.mermaidId + 1
              this.mermaidId++
            }
          }

          if (operation && operation === 'add' && !this.addIsSpecial && !this.addIsInner) {
            if (type === 'EMAIL') {
              this.newStep.properties.variables = {
                subject: ''
              }
            } else if (type === 'USER') {
              this.newStep.properties.fields = {
                email: '',
                name: '',
                roles: '',
                targetObject: '',
                apiKey: '',
                password: ''
              }
            } else if (type === 'S3') {
              this.newStep.properties.fields = {
                targetObject: '',
                path: '',
                key: '',
                content: ''
              }
            } else if (type === 'REST') {
              this.newStep.properties.headers = {
                'Content-Type': ''
              }
            } else if (type === 'IMAP') {
              this.newStep.properties.fields = {
                body: '',
                maxResults: '',
                readType: '',
                recipient: '',
                sender: '',
                subject: '',
                emailId: ''
              }
            } else if (type === 'CSV') {
              this.newStep.properties.includeHeaders = true
            } else if (type === 'STORAGE') {
              this.newStep.properties = {
                action: 'GET',
                scriptEngine: 'JS',
                fields: {
                  targetObject: ''
                }
              }
            } else if (type === 'ENCODER') {
              this.newStep.properties = {
                action: 'ENCODE_HTML_SPECIAL_CHARS',
                converts: []
              }
            } else if (type === 'TRY_CATCH') {
              delete this.newStep.steps
              this.newStep.properties = {
                try: {
                  name: '',
                  steps: [],
                  subType: 'TRY'
                },
                catch: [
                  {
                    name: '',
                    exceptions: [],
                    steps: [],
                    subType: 'EXCEPTION'
                  }
                ],
                finally: {
                  name: '',
                  steps: [],
                  subType: 'FINALLY'
                }
              }
            } else {
              this.newStep.properties = {}
            }

            if (id === 0 && !this.previewLock) {
              this.previewLock = true
              if (itemToPaste && !itemToPaste.subType) {
                this.process.steps.steps.push({
                  ...itemToPaste,
                  localId: firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
                })
                this.selectedItem = {
                  ...itemToPaste,
                  localId: firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
                }
              } else {
                this.process.steps.steps.push({
                  ...JSON.parse(JSON.stringify(this.newStep)),
                  id: this.highestId,
                  type,
                  localId: firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
                })
                this.selectedItem = {
                  ...JSON.parse(JSON.stringify(this.newStep)),
                  id: this.highestId,
                  type,
                  localId: firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
                }
              }
              this.beforeStep = null
              item.localId = firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
              item.mermaidId = this.mermaidId + 1
              this.mermaidId++
            }
            if (id === item.id && !this.previewLock) {
              this.previewLock = true
              if (this.addIsAfter) {
                if (this.tryCatch && this.tryCatch === 'try') {
                  if (itemToPaste && !itemToPaste.subType) {
                    items.splice(index + 1, 0, {
                      ...itemToPaste,
                      localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                    })
                    this.selectedItem = {
                      ...itemToPaste,
                      localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                    }
                  } else {
                    if (items.length - 1 === index) {
                      items.push({
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                      })
                    } else {
                      items.splice(index + 1, 0, {
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel ? index + 2 : `T${parentIndex}.${index + 2}`
                      })
                    }
                    this.selectedItem = {
                      ...JSON.parse(JSON.stringify(this.newStep)),
                      id: this.highestId,
                      type,
                      localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                    }
                  }
                } else if (this.tryCatch && this.tryCatch === 'finally') {
                  if (itemToPaste && !itemToPaste.subType) {
                    items.splice(index + 1, 0, {
                      ...itemToPaste,
                      localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                    })
                    this.selectedItem = {
                      ...itemToPaste,
                      localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                    }
                  } else {
                    if (items.length - 1 === index) {
                      items.push({
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                      })
                    } else {
                      items.splice(index + 1, 0, {
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel ? index + 2 : `F${parentIndex}.${index + 2}`
                      })
                    }
                    this.selectedItem = {
                      ...JSON.parse(JSON.stringify(this.newStep)),
                      id: this.highestId,
                      type,
                      localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                    }
                  }
                } else if (this.tryCatch && this.tryCatch === 'catch') {
                  if (itemToPaste && !itemToPaste.subType) {
                    items.splice(index + 1, 0, {
                      ...itemToPaste,
                      localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                    })
                    this.selectedItem = {
                      ...itemToPaste,
                      localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                    }
                  } else {
                    if (items.length - 1 === index) {
                      items.push({
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                      })
                    } else {
                      items.splice(index + 1, 0, {
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel ? index + 2 : `T${parentIndex}.${index + 2}`
                      })
                    }
                    this.selectedItem = {
                      ...JSON.parse(JSON.stringify(this.newStep)),
                      id: this.highestId,
                      type,
                      localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                    }
                  }
                } else if (!this.tryCatch) {
                  if (itemToPaste && !itemToPaste.subType) {
                    items.splice(index + 1, 0, {
                      ...itemToPaste,
                      localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                    })
                    this.selectedItem = {
                      ...itemToPaste,
                      localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                    }
                  } else {
                    if (items.length - 1 === index) {
                      items.push({
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                      })
                    } else {
                      items.splice(index + 1, 0, {
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                      })
                    }
                    this.selectedItem = {
                      ...JSON.parse(JSON.stringify(this.newStep)),
                      id: this.highestId,
                      type,
                      localId: firstLevel ? index + 2 : `${parentIndex}.${index + 2}`
                    }

                    if (firstLevel) {
                      for (let k = 0; k < items.length; k++) {
                        items[k].localId = String(k + 1)
                      }
                    }
                  }
                }
              } else {
                if (itemToPaste && !itemToPaste.subType) {
                  items.splice(index, 0, {
                    ...itemToPaste,
                    localId: firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
                  })
                  this.selectedItem = {
                    ...itemToPaste,
                    localId: firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
                  }
                } else {
                  items.splice(index, 0, {
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
                  })
                  this.selectedItem = {
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
                  }
                }
              }
              this.beforeStep = null
              item.localId = firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
              item.mermaidId = this.mermaidId + 1
              this.mermaidId++
              if (!itemToPaste) {
                if (item.name === '') item.name = firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
              }
            }
          }

          if (operation && operation === 'add' && this.addIsInner && !this.previewLock) {
            if (this.beforeStep && item.localId === this.beforeStep.localId) {
              this.previewLock = true
              if (!item.properties.steps && !this.tryCatch) item.properties.steps = []
              if (itemToPaste && !itemToPaste.subType) {
                if (item.type === 'TRY_CATCH') {
                  if (this.tryCatch === 'finally') {
                    if (!item.properties.finally) item.properties.finally = {
                      name: '',
                      steps: [],
                      subType: 'FINALLY',
                      localId: `F${item.localId}.1`
                    }
                    item.properties.finally.steps.push({
                      ...itemToPaste,
                      localId: `${item.localId}.${item.properties.try.steps.length === 0 ? '1' : index + 2}`
                    })
                  }
                  if (this.tryCatch === 'try') {
                    if (!item.properties.try) item.properties.try = {
                      name: '',
                      steps: [],
                      subType: 'TRY',
                      localId: `T${item.localId}.1`
                    }
                    item.properties.try.steps.push({
                      ...itemToPaste,
                      localId: `${item.localId}.${item.properties.try.steps.length === 0 ? '1' : index + 2}`
                    })
                  }
                  this.selectedItem = {
                    ...itemToPaste,
                    localId: `${item.localId}.${item.properties.try.steps.length === 0 ? '1' : index + 2}`
                  }
                } else {
                  item.properties.steps.push({
                    ...itemToPaste,
                    localId: `${item.localId}.${item.properties.steps.length === 0 ? '1' : index + 2}`
                  })
                  this.selectedItem = {
                    ...itemToPaste,
                    localId: `${item.localId}.${item.properties.steps.length === 0 ? '1' : index + 2}`
                  }
                }
              } else {
                if (this.tryCatch === 'try') {
                  if (!item.properties.try) item.properties.try = {
                    name: '',
                    steps: [],
                    subType: 'TRY',
                    localId: `T${item.localId}.1`
                  }
                  item.properties.try.steps.push({
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  })
                  this.selectedItem = {
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `T${item.localId}.1`
                  }
                } else if (this.tryCatch === 'finally') {
                  if (!item.properties.finally) item.properties.finally = {
                    name: '',
                    steps: [],
                    subType: 'FINALLY',
                    localId: `F${item.localId}.1`
                  }
                  item.properties.finally.steps.push({
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `F${item.localId}.1`
                  })
                  this.selectedItem = {
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  }
                } else if (!this.tryCatch) {
                  item.properties.steps.push({
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  })
                  this.selectedItem = {
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  }
                }
              }
              this.beforeStep = null
            }
          }

          if (this.beforeStep && item.localId === this.beforeStep.localId && this.tryCatch === 'catch' && !this.previewLock && item.properties.catch?.length === 0) {
            if (!item.properties.catch) item.properties.catch = []
            item.properties.catch.push({
              ...JSON.parse(JSON.stringify(this.newException)),
              id: this.highestId,
              type,
              localId: `C${item.localId}.1`
            })
            this.selectedItem = {
              ...JSON.parse(JSON.stringify(this.newException)),
              id: this.highestId,
              type,
              localId: `C${item.localId}.1`
            }
          }

          if (defaultItem.localId === item.localId) {
            if (isInnerTryCatch === 'try') {
              item.isSelected = status
              this.selectedItem = item.properties.try
              item.properties.try.isSelected = true
              if (item.properties?.finally) item.properties.finally.isSelected = false
              item.properties.try.subType = 'TRY'
              item.enabled = !item.enabled
              item.enabled = !item.enabled
            } else if (isInnerTryCatch === 'finally') {
              item.isSelected = status
              this.selectedItem = item.properties.finally
              item.properties.finally.isSelected = true
              if (item.properties?.try) item.properties.try.isSelected = false
              item.properties.finally.subType = 'FINALLY'
              item.enabled = !item.enabled
              item.enabled = !item.enabled
            } else {
              if (item.properties?.finally) item.properties.finally.isSelected = true
              if (item.properties?.try) item.properties.try.isSelected = true
              item.isSelected = true
              this.selectedItem = item
              item.enabled = !item.enabled
              item.enabled = !item.enabled
            }
          } else {
            item.isSelected = status
            if (item.properties?.finally) item.properties.finally.isSelected = status
            if (item.properties?.try) item.properties.try.isSelected = status
            item.enabled = !item.enabled
            item.enabled = !item.enabled
          }

          const returnTryOrFinallyStatus = (whereAreWe) => {
            if (item.properties.try && isInnerTryCatch === 'try' && whereAreWe === 'try') {
              return item.properties.try.isSelected || item.isSelected
            } else if (item.properties.finally && isInnerTryCatch === 'finally' && whereAreWe === 'finally') {
              return item.properties.finally.isSelected || item.isSelected
            } else {
              return item.isSelected
            }
          }

          const returnTryOrFinallyStatusOnlyIfSelected = () => {
            if (item.properties.try && (isInnerTryCatch === 'try' || insideIsInnerTryCatch === 'try')) {

              return item.properties.try.isSelected || item.isSelected
            } else if (item.properties.finally && (isInnerTryCatch === 'finally' || insideIsInnerTryCatch === 'finally')) {

              return item.properties.finally.isSelected || item.isSelected
            } else {

              return item.isSelected
            }
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
            formatSteps(item.properties.steps, returnTryOrFinallyStatus(), item.localId, false)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0) {
            formatSteps(item.properties.try.steps, returnTryOrFinallyStatus('try'), `T${item.localId}`, false)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0) {
            formatSteps(item.properties.finally.steps, returnTryOrFinallyStatus('finally'), `F${item.localId}`, false)
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
            item.properties.catch.forEach((item2, index2) => {

              if (item2.id && item2.id > this.highestId) this.highestId = item2.id

              item2.localId = `C${item.localId}.${index2 + 1}`
              item2.mermaidId = this.mermaidId + 1
              this.mermaidId++

              if (operation && operation === 'add' && this.addIsSpecial && !this.previewLock) {
                if (item2 && item2.localId === this.beforeStep.localId && !this.previewLock) {
                  this.previewLock = true
                  if (!item.properties.catch) item.properties.catch = []
                  if (itemToPaste && !itemToPaste.subType) {
                    item.properties.catch.push({ ...itemToPaste, localId: `${item.localId}.${index2 + 2}` })
                    this.selectedItem = { ...itemToPaste, localId: `${item.localId}.${index2 + 2}` }
                  } else {
                    item.properties.catch.push({ ...this.newException, localId: `C${item.localId}.${index2 + 2}` })
                    this.selectedItem = {
                      ...JSON.parse(JSON.stringify(this.newException)),
                      localId: `C${item.localId}.${index2 + 2}`
                    }
                  }
                  this.beforeStep = null
                }
              }

              if (operation && operation === 'add' && !this.addIsSpecial && !this.previewLock) {
                if (item2 && this.beforeStep && item2.localId === this.beforeStep.localId && !this.previewLock && id === -1) {
                  this.previewLock = true
                  if (this.tryCatch === 'try') {
                    if (itemToPaste && !itemToPaste.subType) {
                      item2.try.steps.push({ ...itemToPaste })
                      this.selectedItem = { ...itemToPaste }
                    } else {
                      this.selectedItem = {
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: `T${item2.localId}.${item2.steps.length === 0 ? '1' : index2 + 2}`
                      }
                      item2.try.steps.push({
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: `T${item2.localId}.${item2.steps.length === 0 ? '1' : index2 + 2}`
                      })
                    }
                  } else if (this.tryCatch === 'finally') {
                    if (itemToPaste && !itemToPaste.subType) {
                      item2.finally.steps.push({ ...itemToPaste })
                      this.selectedItem = { ...itemToPaste }
                    } else {
                      this.selectedItem = {
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: `F${item2.localId}.${item2.steps.length === 0 ? '1' : index2 + 2}`
                      }
                      item2.finally.steps.push({
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: `F${item2.localId}.${item2.steps.length === 0 ? '1' : index2 + 2}`
                      })
                    }
                  } else {
                    if (itemToPaste && !itemToPaste.subType) {
                      item2.steps.push({ ...itemToPaste })
                      this.selectedItem = { ...itemToPaste }
                    } else {
                      this.selectedItem = {
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: `${item2.localId}.${item2.steps.length === 0 ? '1' : index2 + 2}`
                      }
                      item2.steps.push({
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: `${item2.localId}.${item2.steps.length === 0 ? '1' : index2 + 2}`
                      })
                    }
                  }
                  this.beforeStep = null
                }
              }

              item2.isSelected = item2.localId === defaultItem.localId || item.isSelected
              item2.enabled = !item.enabled
              item2.enabled = !item.enabled
              item2.subType = 'EXCEPTION'
              if (item2.localId === defaultItem.localId) this.selectedItem = item2
              formatSteps(item2.steps, item.isSelected || item2.isSelected, item2.localId, false)
            })
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
            item.properties.conditions.forEach((item2, index2) => {

              if (item2.id && item2.id > this.highestId) this.highestId = item2.id

              item2.localId = `${item.localId}.${index2 + 1}`
              item2.mermaidId = this.mermaidId + 1
              this.mermaidId++

              if (operation && operation === 'add' && this.addIsSpecial && !this.previewLock) {
                if (item2 && item2.localId === this.beforeStep.localId && !this.previewLock) {
                  this.previewLock = true
                  if (!item.properties.conditions) item.properties.conditions = []
                  if (itemToPaste && !itemToPaste.subType) {
                    item.properties.conditions.push({ ...itemToPaste, localId: `${item.localId}.${index2 + 2}` })
                    this.selectedItem = { ...itemToPaste, localId: `${item.localId}.${index2 + 2}` }
                  } else {
                    item.properties.conditions.push({ ...this.newQuery, localId: `${item.localId}.${index2 + 2}` })
                    this.selectedItem = {
                      ...JSON.parse(JSON.stringify(this.newQuery)),
                      localId: `${item.localId}.${index2 + 2}`
                    }
                  }
                  this.beforeStep = null
                }
              }

              if (operation && operation === 'add' && !this.addIsSpecial && !this.previewLock) {
                if (item2 && this.beforeStep && item2.localId === this.beforeStep.localId && !this.previewLock && id === -1) {
                  this.previewLock = true
                  if (itemToPaste && !itemToPaste.subType) {
                    item2.steps.push({ ...itemToPaste })
                    this.selectedItem = { ...itemToPaste }
                  } else {
                    this.selectedItem = {
                      ...JSON.parse(JSON.stringify(this.newStep)),
                      id: this.highestId,
                      type,
                      localId: `${item2.localId}.${item2.steps.length === 0 ? '1' : index2 + 2}`
                    }
                    item2.steps.push({
                      ...JSON.parse(JSON.stringify(this.newStep)),
                      id: this.highestId,
                      type,
                      localId: `${item2.localId}.${item2.steps.length === 0 ? '1' : index2 + 2}`
                    })
                  }
                  this.beforeStep = null
                }
              }

              item2.isSelected = item2.localId === defaultItem.localId ? true : returnTryOrFinallyStatus()
              item2.enabled = !item.enabled
              item2.enabled = !item.enabled
              item2.subType = 'QUERY'
              if (item2.localId === defaultItem.localId) this.selectedItem = item2
              formatSteps(item2.steps, returnTryOrFinallyStatusOnlyIfSelected() || item2.isSelected, item2.localId, false)
            })
          } else if (item.properties && !item.properties.conditions && !this.tryCatch) {
            if (operation && operation === 'add' && this.addIsSpecial && !this.previewLock) {
              if (this.beforeStep && item.localId === this.beforeStep.localId && !this.previewLock) {
                this.previewLock = true
                if (!item.properties.conditions) item.properties.conditions = []
                if (itemToPaste && !itemToPaste.subType) {
                  item.properties.conditions.push({ ...itemToPaste, localId: `${item.localId}.${index + 2}` })
                  this.selectedItem = { ...itemToPaste, localId: `${item.localId}.${index + 2}` }
                } else {
                  item.properties.conditions.push({ ...this.newQuery, localId: `${item.localId}.1` })
                  this.selectedItem = { ...JSON.parse(JSON.stringify(this.newQuery)), localId: `${item.localId}.1` }
                }
                this.beforeStep = null
              }
            }

            if (operation && operation === 'add' && !this.addIsSpecial && !this.previewLock) {
              if (this.beforeStep && item.localId === this.beforeStep.localId && !this.previewLock && id === -1) {
                this.previewLock = true
                if (!item.steps) item.steps = []
                if (itemToPaste && !itemToPaste.subType) {
                  item.steps.push({ ...itemToPaste, localId: `${item.localId}.1` })
                  this.selectedItem = { ...itemToPaste, localId: `${item.localId}.1` }
                } else {
                  item.steps.push({
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  })
                  this.selectedItem = {
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  }
                }
                this.beforeStep = null
              }
            }
          } else if (item.properties && !item.properties.catch && this.tryCatch === 'catch') {
            if (operation && operation === 'add' && this.addIsSpecial && !this.previewLock) {
              if (this.beforeStep && item.localId === this.beforeStep.localId && !this.previewLock) {
                this.previewLock = true
                if (!item.properties.catch) item.properties.catch = []
                if (itemToPaste && !itemToPaste.subType) {
                  item.properties.catch.push({ ...itemToPaste, localId: `${item.localId}.${index + 2}` })
                  this.selectedItem = { ...itemToPaste, localId: `${item.localId}.${index + 2}` }
                } else {
                  item.properties.catch.push({ ...this.newException, localId: `C${item.localId}.1` })
                  this.selectedItem = { ...JSON.parse(JSON.stringify(this.newException)), localId: `C${item.localId}.1` }
                }
                this.beforeStep = null
              }
            }

            if (operation && operation === 'add' && !this.addIsSpecial && !this.previewLock) {
              if (this.beforeStep && item.localId === this.beforeStep.localId && !this.previewLock && id === -1) {
                this.previewLock = true
                if (!item.steps) item.steps = []
                if (itemToPaste && !itemToPaste.subType) {
                  item.steps.push({ ...itemToPaste, localId: `${item.localId}.1` })
                  this.selectedItem = { ...itemToPaste, localId: `${item.localId}.1` }
                } else {
                  item.steps.push({
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  })
                  this.selectedItem = {
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  }
                }
                this.beforeStep = null
              }
            }
          }
        })
      }

      await formatSteps(this.process.steps.steps, false, 1, true)

      if (operation === 'add') {
        this.tryCatch = ''
        await formatSteps(this.process.steps.steps, false, 1, true)
        this.newStep = {
          enabled: true,
          id: 0,
          isSelected: true,
          name: '',
          properties: {},
          type: '',
          enableStepLog: true,
          enableCacheLog: true
        }
        if (this.addIsSpecial) {
          this.newQuery = {
            query: '',
            isSelected: true,
            steps: [],
            subType: 'QUERY'
          }
          this.newException = {
            exceptions: [],
            isSelected: true,
            steps: [],
            subType: 'EXCEPTION'
          }
        }
        if (this.addIsInner) {
          this.tableKey++
          this.addIsInner = false
          this.selectItem(this.selectedItem)
        }
        setTimeout(() => {
          const el = this.selectedItem ? document.getElementById(`select-${this.selectedItem.localId}`) : null

          if (el) el.click()

          this.stepPropertiesKey++
        }, 50)

        if (this.logs && this.logs.length > 0) {
          this.logs.forEach((x) => {
            this.visualIdSearch(x.stepId)
          })
          setTimeout(() => this.logKey++, 200)
        }
      }

      if (this.logs && this.logs.length > 0 && this.selectedItem && this.selectedItem.id) {
        const findLogStep = this.logs.find((x) => x.stepId === this.selectedItem.id)

        const logIndex = this.logs.indexOf(findLogStep)

        if (logIndex > -1) {
          this.selectedLogIndex = logIndex
        }

        if (findLogStep) {
          this.logs.map((x) => x.isSelected = false)
          findLogStep.isSelected = true
        } else {
          this.logs.map((x) => x.isSelected = false)
        }
      }

      this.filterTryCatchSteps()

      if (!this.loading && this.selectedItem) this.tab = 3
    },
    async selectItem(selectedStep) {

      const formatSteps = (items, status = false) => {

        items.forEach((item) => {

          if (String(selectedStep.localId) === String(item.localId)) {
            item.isSelected = true
            item.enabled = !item.enabled
            item.enabled = !item.enabled
            this.selectedItem = item
          } else {
            item.isSelected = status
            item.enabled = !item.enabled
            item.enabled = !item.enabled
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
            formatSteps(item.properties.steps, selectedStep.id === item.id && item.isSelected)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0) {
            item.properties.try.isSelected = item.properties.try.localId === selectedStep.localId || item.isSelected
            formatSteps(item.properties.try.steps, selectedStep.id === item.id && item.isSelected)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0) {
            item.properties.finally.isSelected = item.properties.finally.localId === selectedStep.localId || item.isSelected
            formatSteps(item.properties.finally.steps, selectedStep.id === item.id && item.isSelected)
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
            item.properties.catch.forEach((item2) => {
              item2.isSelected = item2.localId === selectedStep.localId || item.isSelected
              item2.subType = 'EXCEPTION'
              if (item2.localId === selectedStep.localId) this.selectedItem = item2
              formatSteps(item2.steps, selectedStep.subType && item2.isSelected || item.isSelected)
            })
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
            item.properties.conditions.forEach((item2) => {
              item2.isSelected = item2.localId === selectedStep.localId
              item2.subType = 'QUERY'
              if (item2.localId === selectedStep.localId) this.selectedItem = item2
              formatSteps(item2.steps, selectedStep.subType && item2.isSelected)
            })
          }
        })
      }

      await formatSteps(this.process.steps.steps)

      if (!this.loading && this.selectedItem) this.tab = 3

      this.stepPropertiesKey++
    },
    showHideChildren(selectedStep) {

      const formatSteps = (items, status = false) => {

        items.forEach((item) => {

          if (selectedStep.item.localId === item.localId) {
            item.isSelected = true
            item.hideChildren = selectedStep.hideChildren
            item.hideChildrenTry = selectedStep.hideChildrenTry || false
            item.hideChildrenFinally = selectedStep.hideChildrenFinally || false
            item.enabled = !item.enabled
            item.enabled = !item.enabled
            this.selectedItem = item
          } else {
            item.isSelected = status
            item.enabled = !item.enabled
            item.enabled = !item.enabled
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
            formatSteps(item.properties.steps, selectedStep.item.id === item.id && item.isSelected || status)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0) {
            formatSteps(item.properties.try.steps, (selectedStep.item.id === item.id && item.isSelected) || status)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0) {
            formatSteps(item.properties.finally.steps, (selectedStep.item.id === item.id && item.isSelected) || status)
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
            item.properties.catch.forEach((item2) => {
              item2.isSelected = item2.localId === selectedStep.item.localId || status
              item2.hideChildren = item2.localId === selectedStep.item.localId ? selectedStep.hideChildren : false
              item2.subType = 'EXCEPTION'
              if (item2.exceptions === selectedStep.exceptions) this.selectedItem = item2
              formatSteps(item2.steps, (selectedStep.subType && item2.isSelected) || status)
            })
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
            item.properties.conditions.forEach((item2) => {
              item2.isSelected = item2.localId === selectedStep.item.localId || status
              item2.hideChildren = item2.localId === selectedStep.item.localId ? selectedStep.hideChildren : false
              item2.subType = 'QUERY'
              if (item2.localId === selectedStep.item.localId) this.selectedItem = item2
              formatSteps(item2.steps, ((selectedStep.item.subType && item2.isSelected) || status))
            })
          }
        })
      }

      formatSteps(this.process.steps.steps)

      if (!this.loading && this.selectedItem) this.tab = 3

      this.stepPropertiesKey++
    },
    openStepFromDebug(stepId, eventId) {
      if (!stepId) return

      this.selectStepFromLog(this.foundLogsSteps[stepId] || 'Not present', false)

      setTimeout(() => {
        const obj = {}

        obj.name = this.selectedItem.properties.processName

        getProcesses(obj)
          .then((res) => {
            const routeData = this.$router.resolve({
              name: 'processEdit',
              params: { id: res.data.data.items[0].id },
              query: {
                eventId
              }
            })

            window.open(routeData.href, '_blank')
          })
          .catch((err) => {
            this.isLoadingProcesses = false
            this.err = err
          })
      }, 200)
    },
    selectStepFromLog(localStepId, isSelect = true) {
      if (localStepId === 'Not Present') {
        this.selectedItem = null
        this.previewData({ id: null }, '', null, '')
        this.tab = 0

        return
      }

      const searchStep = (items, searchableStepId) => {

        let itemFound = ''

        items.forEach((item) => {

          if (String(item.localId) === String(searchableStepId)) {
            itemFound = item
            this.selectedItem = itemFound
            this.selectItem(itemFound)
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0 && !itemFound) {
            searchStep(item.properties.steps, searchableStepId)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0 && !itemFound) {
            searchStep(item.properties.try.steps, searchableStepId)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0 && !itemFound) {
            searchStep(item.properties.finally.steps, searchableStepId)
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0 && !itemFound) {
            item.properties.catch.forEach((item2) => {
              searchStep(item2.steps, searchableStepId)
            })
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0 && !itemFound) {
            item.properties.conditions.forEach((item2) => {
              searchStep(item2.steps, searchableStepId)
            })
          }
        })
      }

      searchStep(this.process.steps.steps, localStepId)
    },
    unselectAllStepsFromLog() {
      this.logs.map((x) => {
        x.isSelected = false

        return x
      })
    },
    visualIdSearch(stepId) {
      const searchStep = (items, searchableStepId) => {

        let itemFound = ''

        items.forEach((item) => {

          if (item.id && item.id === searchableStepId) {
            itemFound = item
            this.foundLogsSteps[searchableStepId] = item.localId
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0 && !itemFound) {
            searchStep(item.properties.steps, searchableStepId)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0 && !itemFound) {
            searchStep(item.properties.try.steps, searchableStepId)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0 && !itemFound) {
            searchStep(item.properties.finally.steps, searchableStepId)
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0 && !itemFound) {
            item.properties.catch.forEach((item2) => {
              searchStep(item2.steps, searchableStepId)
            })
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0 && !itemFound) {
            item.properties.conditions.forEach((item2) => {
              searchStep(item2.steps, searchableStepId)
            })
          }
        })
      }

      searchStep(this.process.steps.steps, stepId)
    },
    moveItemsAround(moveItem, direction) {
      this.moveLock = false

      const formatSteps = (items) => {

        items.forEach((item, index) => {

          if (item.localId === moveItem.localId && !this.moveLock) {
            this.moveLock = true
            const element = items[index]

            items.splice(index, 1)
            items.splice(direction === 'down' ? index + 1 : index - 1, 0, element)

            this.previewData({ id: null }, '', null, '')

            return
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
            formatSteps(item.properties.steps)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0) {
            formatSteps(item.properties.try.steps)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0) {
            formatSteps(item.properties.finally.steps)
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
            if (moveItem.subType === 'EXCEPTION') {
              formatSteps(item.properties.catch)
            }
            item.properties.catch.forEach((item2) => {
              formatSteps(item2.steps)
            })
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
            if (moveItem.subType === 'QUERY') {
              formatSteps(item.properties.conditions)
            }
            item.properties.conditions.forEach((item2) => {
              formatSteps(item2.steps)
            })
          }
        })
      }

      formatSteps(this.process.steps.steps)
    },
    setBorderWidth() {
      const i = this.$refs.resizeme.querySelector(
        '.border-me'
      )

      i.style.zIndex = 1
      i.style.width = this.borderSize + 'px'
      i.style.cursor = 'ew-resize'
    },
    setEvents() {
      const minSize = 320
      const el = this.$refs.resizeme
      const elSteps = this.$refs.steps

      const resizemeBorder = el.querySelector('.border-me')
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this

      function resize(e) {
        document.body.style.cursor = 'ew-resize'
        const f = document.body.scrollWidth - e.clientX

        el.style.width = f + 'px'
      }

      resizemeBorder.addEventListener(
        'mousedown',
        (e) => {
          el.classList.add('noselect')
          elSteps.classList.add('noselect')
          if (e.offsetX < minSize) {
            el.style.transition = 'initial'
            document.addEventListener('mousemove', resize, false)
          }
        },
        false
      )

      document.addEventListener(
        'mouseup',
        () => {
          el.classList.remove('noselect')
          elSteps.classList.remove('noselect')
          el.style.transition = ''
          vm.width = el.style.width
          document.body.style.cursor = ''
          document.removeEventListener('mousemove', resize, false)
        },
        false
      )
    },
    hasDuplicates(arr) {
      return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x))
    },
    openStepFromError(localId) {
      const selectedItem = this.errorItems.find((x) => x.localId === localId)

      if (selectedItem) this.previewData(selectedItem)
    },
    async fetchValidationRecursive(validation) {

      const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

      const fetchedValidations = []
      const tempValidation = (validationId) => {
        getValidationRule({ id: validationId }).then((res) => {
          fetchedValidations.push(res.data.data.fields)
          if (res.data.data.includes && res.data.data.includes.length > 0) {
            res.data.data.includes.forEach((include) => {
              tempValidation(include)
            })
          }
        })
          .catch((err) => {
            console.log(err)
          })
      }

      await tempValidation(validation)

      await pause(1200)

      return fetchedValidations.flat(Infinity).map((x) => `$.${x.name}`)
    },
    async checkReqFields() {
      let errors = ''
      const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

      this.errorItems = []
      this.errorsNew = []

      let errorItemsLength = 0

      if (this.editRolesIds.length < 1 && !this.isSuperUser) errors = errors + `${this.$lang.errors.editRoleCreate}.<br>`

      const checkSteps = async (items) => {

        for (const item of items) {

          switch (item.type) {
          case 'FORMATTING':
            if (!item.name) this.errorsNew.push({
              text: `Formatting step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `Formatting step local Id: ${item.localId} doesn't have Action field set.`,
              value: item.localId
            })

            if (item.properties && item.properties.converts && item.properties.converts.length > 0) {
              item.properties.converts.forEach((convert) => {
                if (!convert.sourceObject) this.errorsNew.push({
                  text: `Formatting step local Id: ${item.localId} doesn't have Converts Source Object field set.`,
                  value: item.localId
                })
                if (convert.sourceObject && !this.$options.filters.javaVariableConventionRules(convert.sourceObject, true)) this.errorsNew.push({
                  text: `Formatting step local Id: ${item.localId} Converts Source Object field is not valid.`,
                  value: item.localId
                })
                if (!convert.targetObject) this.errorsNew.push({
                  text: `Formatting step local Id: ${item.localId} doesn't have Converts Target Object field set.`,
                  value: item.localId
                })
                if (convert.targetObject && !this.$options.filters.javaVariableConventionRules(convert.targetObject, true)) this.errorsNew.push({
                  text: `Formatting step local Id: ${item.localId} Converts Target Object field is not valid.`,
                  value: item.localId
                })
              })
            }

            if (item.properties && !item.properties.fields) this.errorsNew.push({
              text: `Formatting step local Id: ${item.localId} doesn't have Fields set.`,
              value: item.localId
            })

            if (item.properties && item.properties.action && item.properties.action === 'DECIMAL_FORMAT') {
              if (item.properties && item.properties.fields && !item.properties.fields.locale) this.errorsNew.push({
                text: `Formatting step local Id: ${item.localId} doesn't have Locale set.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && !item.properties.fields.pattern) this.errorsNew.push({
                text: `Formatting step local Id: ${item.localId} doesn't have Pattern set.`,
                value: item.localId
              })
            }
            break
          case 'STORAGE':
            if (!item.name) this.errorsNew.push({
              text: `Storage step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `Storage step local Id: ${item.localId} doesn't have Action field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.key) this.errorsNew.push({
              text: `Storage step local Id: ${item.localId} doesn't have Key field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.fields) this.errorsNew.push({
              text: `Storage step local Id: ${item.localId} doesn't have Fields set.`,
              value: item.localId
            })

            if (item.properties && item.properties.action && item.properties.action === 'GET') {
              if (item.properties && item.properties.fields && !item.properties.fields.targetObject) this.errorsNew.push({
                text: `Storage step local Id: ${item.localId} doesn't have Target Object set.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && item.properties.fields.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.fields.targetObject, true)) this.errorsNew.push({
                text: `Storage step local Id: ${item.localId} Target Object field is not valid.`,
                value: item.localId
              })
            }

            if (item.properties && item.properties.action && item.properties.action === 'UPSERT') {
              if (item.properties && item.properties.fields && !item.properties.fields.expiresIn) this.errorsNew.push({
                text: `Storage step local Id: ${item.localId} doesn't have Expires In set.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && !item.properties.fields['value']) this.errorsNew.push({
                text: `Storage step local Id: ${item.localId} doesn't have Value set.`,
                value: item.localId
              })
            }
            break
          case 'ENCODER':
            if (!item.name) this.errorsNew.push({
              text: `Encoder step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `Encoder step local Id: ${item.localId} doesn't have Action field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.converts) this.errorsNew.push({
              text: `Encoder step local Id: ${item.localId} doesn't have Converts set.`,
              value: item.localId
            })
            if (item.properties && item.properties.converts && item.properties.converts.length > 0) {
              item.properties.converts.forEach((convert) => {
                if (!convert.sourceObject) this.errorsNew.push({
                  text: `Encoder step local Id: ${item.localId} doesn't have Converts Source Object field set.`,
                  value: item.localId
                })
                if (convert.sourceObject && !this.$options.filters.javaVariableConventionRules(convert.sourceObject, true)) this.errorsNew.push({
                  text: `Encoder step local Id: ${item.localId} Converts Source Object field is not valid.`,
                  value: item.localId
                })
                if (!convert.targetObject) this.errorsNew.push({
                  text: `Encoder step local Id: ${item.localId} doesn't have Converts Target Object field set.`,
                  value: item.localId
                })
                if (convert.targetObject && !this.$options.filters.javaVariableConventionRules(convert.targetObject, true)) this.errorsNew.push({
                  text: `Encoder step local Id: ${item.localId} Converts Target Object field is not valid.`,
                  value: item.localId
                })
              })
            }
            break
          case 'LOG':
            if (!item.name) this.errorsNew.push({
              text: `Log step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            break
          case 'ZIP':
            if (!item.name) this.errorsNew.push({
              text: `ZIP step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.targetObject) this.errorsNew.push({
              text: `ZIP step local Id: ${item.localId} doesn't have Target object field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `ZIP step local Id: ${item.localId} doesn't have Action field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.action && item.properties.action === 'COMPRESS') {
              if (item.properties && !item.properties.fields) this.errorsNew.push({
                text: `ZIP step local Id: ${item.localId} doesn't have Entries set.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && item.properties.fields.entries && item.properties.fields.entries.length === 0) this.errorsNew.push({
                text: `ZIP step local Id: ${item.localId} doesn't have Entries set.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && item.properties.fields.entries && item.properties.fields.entries.length > 0) {
                item.properties.fields.entries.forEach((item2, index2) => {
                  if (!item2.content) this.errorsNew.push({
                    text: `ZIP step local Id: ${item.localId} doesn't have Content ${index2 + 1} set.`,
                    value: item.localId
                  })
                  if (!item2.name) this.errorsNew.push({
                    text: `ZIP step local Id: ${item.localId} doesn't have Name ${index2 + 1} set.`,
                    value: item.localId
                  })
                })
              }
            }

            if (item.properties && item.properties.action && item.properties.action === 'DECOMPRESS') {
              if (item.properties && !item.properties.fields) this.errorsNew.push({
                text: `ZIP step local Id: ${item.localId} doesn't have Source Object set.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && !item.properties.fields.sourceObject) this.errorsNew.push({
                text: `ZIP step local Id: ${item.localId} doesn't have Source Object set.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && item.properties.fields.sourceObject && !this.$options.filters.javaVariableConventionRules(item.properties.fields.sourceObject, true)) this.errorsNew.push({
                text: `ZIP step local Id: ${item.localId} Source Object is not valid.`,
                value: item.localId
              })
            }

            break
          case 'QUERY_BUILDER':
            if (!item.name) this.errorsNew.push({
              text: `Query builder step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.targetObject) this.errorsNew.push({
              text: `Query builder step local Id: ${item.localId} doesn't have Target object field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.queryConditions && item.properties.queryConditions.length === 0) this.errorsNew.push({
              text: `Query builder step local Id: ${item.localId} doesn't have Query Conditions set.`,
              value: item.localId
            })
            if (item.properties && item.properties.queryConditions && item.properties.queryConditions.length > 0) {
              item.properties.queryConditions.forEach((item2, index2) => {
                if (!item2.condition) this.errorsNew.push({
                  text: `Query builder step local Id: ${item.localId} doesn't have Condition ${index2 + 1} set.`,
                  value: item.localId
                })
                if (!item2.query) this.errorsNew.push({
                  text: `Query builder step local Id: ${item.localId} doesn't have Query ${index2 + 1} set.`,
                  value: item.localId
                })
              })
            }

            break
          case 'CREDENTIAL':
            if (!item.name) this.errorsNew.push({
              text: `Credential step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `Credential step local Id: ${item.localId} doesn't have Action field set.`,
              value: item.localId
            })

            if (item.properties && item.properties.action === 'CREATE') {
              if (!item.properties.fields.targetObject) this.errorsNew.push({
                text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
              if (!item.properties.fields.type) this.errorsNew.push({
                text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Type field set.`,
                value: item.localId
              })
              if (!item.properties.fields.name) this.errorsNew.push({
                text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Name field set.`,
                value: item.localId
              })
              if (!item.properties.fields.roles) this.errorsNew.push({
                text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Roles field set.`,
                value: item.localId
              })
              if (item.properties.fields.values && Object.keys(item.properties.fields.values).length === 0) this.errorsNew.push({
                text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Values set.`,
                value: item.localId
              })
            }
            if (item.properties && item.properties.action === 'UPDATE') {
              if (!item.properties.fields.queryName) this.errorsNew.push({
                text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Query Name field set.`,
                value: item.localId
              })
              if (!item.properties.fields.type) this.errorsNew.push({
                text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Type field set.`,
                value: item.localId
              })
              if (!item.properties.fields.name) this.errorsNew.push({
                text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Name field set.`,
                value: item.localId
              })
              if (!item.properties.fields.roles) this.errorsNew.push({
                text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Roles field set.`,
                value: item.localId
              })
              if (item.properties.fields.values && Object.keys(item.properties.fields.values).length === 0) this.errorsNew.push({
                text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Values set.`,
                value: item.localId
              })
            }
            if (item.properties && ['ENABLE', 'DISABLE'].includes(item.properties.action)) {
              if (!item.properties.fields.queryName) this.errorsNew.push({
                text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Query Name field set.`,
                value: item.localId
              })
            }
            break
          case 'UUID':
            if (!item.name) this.errorsNew.push({
              text: `UUID step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.targetObject) this.errorsNew.push({
              text: `UUID step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
              value: item.localId
            })
            break
          case 'PAYMENT_SENSE_CONNECT_E':
            if (!item.name) this.errorsNew.push({
              text: `Paymentsense Connect E step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.credentialName) this.errorsNew.push({
              text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Action set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.targetObject) this.errorsNew.push({
              text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.action && item.properties.action === 'PAYMENT_METHODS') {
              if (item.properties && item.properties.parameters) item.properties.parameters = null
            }
            if (item.properties && item.properties.action && ['ACCESS_TOKENS'].includes(item.properties.action)) {
              if (item.properties && item.properties.parameters && !item.properties.parameters.currencyCode) this.errorsNew.push({
                text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Currency Code field set.`,
                value: item.localId
              })
              if (item.properties && item.properties.parameters && !item.properties.parameters.merchantUrl) this.errorsNew.push({
                text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Merchant Url field set.`,
                value: item.localId
              })
              if (item.properties && item.properties.parameters && !item.properties.parameters.orderId) this.errorsNew.push({
                text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Order Id field set.`,
                value: item.localId
              })
              if (item.properties && item.properties.parameters && !item.properties.parameters.transactionType) this.errorsNew.push({
                text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Transaction Type field set.`,
                value: item.localId
              })
            }
            if (item.properties && item.properties.action && ['PAYMENT_DETAILS', 'RESUME_PAYMENT', 'REVOKE_ACCESS_TOKEN'].includes(item.properties.action)) {
              if (item.properties && item.properties.parameters && !item.properties.parameters.accessToken) this.errorsNew.push({
                text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Access Token field set.`,
                value: item.localId
              })
            }
            if (item.properties && item.properties.action && ['CROSS_REFERENCE_PAYMENT'].includes(item.properties.action)) {
              if (item.properties && item.properties.parameters && !item.properties.parameters.accessToken) this.errorsNew.push({
                text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Access Token field set.`,
                value: item.localId
              })
              if (item.properties && item.properties.parameters && !item.properties.parameters.crossReferencePayment) this.errorsNew.push({
                text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Cross Reference Payment fields set.`,
                value: item.localId
              })
              if (item.properties && item.properties.parameters && item.properties.parameters.crossReferencePayment && !item.properties.parameters.crossReferencePayment.crossReference) this.errorsNew.push({
                text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Cross Reference field set.`,
                value: item.localId
              })
            }
            break
          case 'PAYMENT_SENSE_PAC':
            if (!item.name) this.errorsNew.push({
              text: `Paymentsense PAC step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.credentialName) this.errorsNew.push({
              text: `Paymentsense PAC step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `Paymentsense PAC step ${item.name} (local Id: ${item.localId}) doesn't have Action set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.targetObject) this.errorsNew.push({
              text: `Paymentsense PAC step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.action && item.properties.action === 'GET_TERMINALS') {
              if (item.properties && item.properties.parameters) item.properties.parameters = null
            }
            if (item.properties && item.properties.action && !['GET_TERMINALS'].includes(item.properties.action)) {
              if (item.properties && item.properties.parameters && !item.properties.parameters.terminalId) this.errorsNew.push({
                text: `Paymentsense PAC step ${item.name} (local Id: ${item.localId}) doesn't have Terminal Id field set.`,
                value: item.localId
              })
            }
            if (item.properties && item.properties.action && ['START_TRANSACTION', 'GET_TRANSACTION', 'SIGNATURE', 'CANCEL_TRANSACTION', 'GET_REPORT'].includes(item.properties.action)) {
              if (item.properties && item.properties.parameters && !item.properties.parameters.requestId) this.errorsNew.push({
                text: `Paymentsense PAC step ${item.name} (local Id: ${item.localId}) doesn't have Request Id field set.`,
                value: item.localId
              })
            }
            if (item.properties && item.properties.action && ['START_REPORT'].includes(item.properties.action)) {
              if (item.properties && item.properties.parameters && !item.properties.parameters.reportType) this.errorsNew.push({
                text: `Paymentsense PAC step ${item.name} (local Id: ${item.localId}) doesn't have Report Type field set.`,
                value: item.localId
              })
            }
            if (item.properties && item.properties.action && ['START_TRANSACTION'].includes(item.properties.action)) {
              if (item.properties && item.properties.parameters && !item.properties.parameters.transaction) this.errorsNew.push({
                text: `Paymentsense PAC step ${item.name} (local Id: ${item.localId}) doesn't have Transaction object set.`,
                value: item.localId
              })
            }
            break
          case 'MESSAGING':
            if (!item.name) this.errorsNew.push({
              text: `Messaging step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.credentialName) this.errorsNew.push({
              text: `Messaging step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.service) this.errorsNew.push({
              text: `Messaging step ${item.name} (local Id: ${item.localId}) doesn't have Service set.`,
              value: item.localId
            })
            if (item.properties.service === 'KAFKA') {
              if (item.properties && !item.properties.config.messageKey) this.errorsNew.push({
                text: `Messaging step ${item.name} (local Id: ${item.localId}) doesn't have Message Key set.`,
                value: item.localId
              })
              delete item.properties.config.topic
            }
            if (item.properties && !item.properties.message) this.errorsNew.push({
              text: `Messaging step ${item.name} (local Id: ${item.localId}) doesn't have Message set.`,
              value: item.localId
            })
            break
          case 'TRY_CATCH':
            if (!item.name) this.errorsNew.push({
              text: `Try catch step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && (!item.properties.try || (item.properties.try && item.properties.try.steps && item.properties.try.steps.length === 0))) this.errorsNew.push({
              text: `Try catch step local Id: ${item.localId} doesn't have any Try steps set.`,
              value: item.localId
            })
            if (item.properties && (!item.properties.catch || (item.properties.catch && item.properties.catch.length === 0))) this.errorsNew.push({
              text: `Try catch step local Id: ${item.localId} doesn't have any Try steps set.`,
              value: item.localId
            })

            // eslint-disable-next-line no-case-declarations
            let hasAll = 0

            if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
              item.properties.catch.forEach((item2, i) => {
                if (item2 && item2.steps?.length === 0) this.errorsNew.push({
                  text: `Try catch step local Id: ${item.localId} Catch Exception ${i} doesn't have any steps set.`,
                  value: item.localId
                })
                if (item2 && item2.exceptions?.length === 0) this.errorsNew.push({
                  text: `Try catch step local Id: ${item.localId} Catch Exception ${i} doesn't have any exceptions set.`,
                  value: item.localId
                })
                if (item2.exceptions.includes('ALL')) hasAll++
                if (hasAll > 1) this.errorsNew.push({
                  text: `Try catch step ${item.name} (local Id: ${item.localId}) Catch Exception ${i} has multiple ALL conditions.`,
                  value: item.localId
                })
              })
            }
            break
          case 'EXECUTE_EXTERNAL_COMMAND':
            if (!item.name) this.errorsNew.push({
              text: `Execute external command step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.command) this.errorsNew.push({
              text: `Execute external command step ${item.name} (local Id: ${item.localId}) doesn't have Command field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.scriptEngine) this.errorsNew.push({
              text: `Execute external command step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine set.`,
              value: item.localId
            })
            if (item.properties && item.properties.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.targetObject, true)) this.errorsNew.push({
              text: `Execute external command step ${item.name} (local Id: ${item.localId}) Target Object field is invalid.`,
              value: item.localId
            })
            if (item.properties && item.properties.resultCode && !this.$options.filters.javaVariableConventionRules(item.properties.resultCode, true)) this.errorsNew.push({
              text: `Execute external command step ${item.name} (local Id: ${item.localId}) Result Code field is invalid.`,
              value: item.localId
            })
            if (item.properties && item.properties.resultErrors && !this.$options.filters.javaVariableConventionRules(item.properties.resultErrors, true)) this.errorsNew.push({
              text: `Execute external command step ${item.name} (local Id: ${item.localId}) Result Errors field is invalid.`,
              value: item.localId
            })

            break
          case 'IMAGE':
            if (!item.name) this.errorsNew.push({
              text: `Image step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.targetObject) this.errorsNew.push({
              text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Action set.`,
              value: item.localId
            })
            if (item.properties && item.properties.action && item.properties.action === 'RESCALE') {
              if (!item.properties.fields || item.properties.fields === {}) {
                this.errorsNew.push({
                  text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Fields set.`,
                  value: item.localId
                })
              } else {
                if (!item.properties.fields.width) this.errorsNew.push({
                  text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Width set.`,
                  value: item.localId
                })
                if (!item.properties.fields.height) this.errorsNew.push({
                  text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Height set.`,
                  value: item.localId
                })
              }
            }
            if (item.properties && item.properties.action && item.properties.action === 'CONVERT') {
              if (!item.properties.fields || item.properties.fields === {}) {
                this.errorsNew.push({
                  text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Fields set.`,
                  value: item.localId
                })
              } else {
                if (!item.properties.fields.type) this.errorsNew.push({
                  text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Type set.`,
                  value: item.localId
                })
              }
            }
            if (item.properties && item.properties.action && item.properties.action === 'FROM_TEMPLATE') {
              if (!item.properties.fields || item.properties.fields === {}) {
                this.errorsNew.push({
                  text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Fields set.`,
                  value: item.localId
                })
              } else {
                if (!item.properties.fields.textTemplate) this.errorsNew.push({
                  text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Template set.`,
                  value: item.localId
                })
                if (!item.properties.fields.width) this.errorsNew.push({
                  text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Width set.`,
                  value: item.localId
                })
              }
            }
            break
          case 'CSV':
            if (!item.name) this.errorsNew.push({
              text: `CSV step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.targetObject) this.errorsNew.push({
              text: `CSV step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.source) this.errorsNew.push({
              text: `CSV step ${item.name} (local Id: ${item.localId}) doesn't have Source set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `CSV step ${item.name} (local Id: ${item.localId}) doesn't have Action set.`,
              value: item.localId
            })
            if (item.properties && item.properties.delimiter && item.properties.delimiter === 'CUSTOM') {
              if (item.properties && !item.properties.customDelimiter) this.errorsNew.push({
                text: `CSV step ${item.name} (local Id: ${item.localId}) doesn't have Custom Delimiter set.`,
                value: item.localId
              })
            }
            if (item.properties && item.properties.filters) {
              const filtersKeys = Object.keys(item.properties.filters)

              if (filtersKeys.length > 0) {
                filtersKeys.forEach((filter) => {
                  if (!item.properties.filters[filter]) this.errorsNew.push({
                    text: `CSV step ${item.name} (local Id: ${item.localId}) filter name ${filter} value is not set.`,
                    value: item.localId
                  })
                })
              }
            }

            break
          case 'PDF':
            if (!item.name) this.errorsNew.push({
              text: `PDF step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.targetObject) this.errorsNew.push({
              text: `PDF step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.textTemplate) this.errorsNew.push({
              text: `PDF step ${item.name} (local Id: ${item.localId}) doesn't have Text Template set.`,
              value: item.localId
            })
            if (item.properties && item.properties.locale && item.properties.locale.length !== 2) this.errorsNew.push({
              text: `PDF step ${item.name} (local Id: ${item.localId}) doesn't have correct Locale set.`,
              value: item.localId
            })
            if (item.properties && item.properties.locale === '') delete item.properties.locale
            break
          case 'IMAP':
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `Imap step ${item.name} (local Id: ${item.localId}) doesn't have Action field set`,
              value: item.localId
            })
            if (item.properties && !item.properties.credentialName) this.errorsNew.push({
              text: `Imap step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.scriptEngine) this.errorsNew.push({
              text: `Imap step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.action && item.properties.action === 'SEARCH') {
              if (item.properties && item.properties.fields && !item.properties.fields.targetObject) this.errorsNew.push({
                text: `Imap step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && item.properties.fields.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.fields.targetObject, true)) this.errorsNew.push({
                text: `Imap step ${item.name} (local Id: ${item.localId}) Target Object field is invalid.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && !item.properties.fields.readType) delete item.properties.fields.readType
              if (item.properties && item.properties.fields && item.properties.fields.sender && !this.$options.filters.checkComplexRegex(item.properties.fields.sender)) this.errorsNew.push({
                text: `Imap step ${item.name} (local Id: ${item.localId}) Sender field is invalid.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && item.properties.fields.recipient && !this.$options.filters.checkComplexRegex(item.properties.fields.recipient)) this.errorsNew.push({
                text: `Imap step ${item.name} (local Id: ${item.localId}) Recipient field is invalid.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && item.properties.fields.subject && !this.$options.filters.checkComplexRegex(item.properties.fields.subject)) this.errorsNew.push({
                text: `Imap step ${item.name} (local Id: ${item.localId}) Subject field is invalid.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && item.properties.fields.body && !this.$options.filters.checkComplexRegex(item.properties.fields.body)) this.errorsNew.push({
                text: `Imap step ${item.name} (local Id: ${item.localId}) Body field is invalid.`,
                value: item.localId
              })
              if (item.properties && item.properties.folder && !this.$options.filters.checkComplexRegex(item.properties.folder)) this.errorsNew.push({
                text: `Imap step ${item.name} (local Id: ${item.localId}) Folder field is invalid.`,
                value: item.localId
              })
            }
            if (item.properties && item.properties.action && item.properties.action !== 'SEARCH') {
              if (item.properties && item.properties.fields && !item.properties.fields.emailId) this.errorsNew.push({
                text: `Imap step ${item.name} (local Id: ${item.localId}) doesn't have Email Id field set.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && item.properties.fields.emailId && !this.$options.filters.javaVariableConventionRules(item.properties.fields.emailId, true)) this.errorsNew.push({
                text: `Imap step ${item.name} (local Id: ${item.localId}) Email Id field is invalid.`,
                value: item.localId
              })
            }
            break
          case 'SECURITY':
            if (!item.name) this.errorsNew.push({
              text: `Security step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `Security step local Id: ${item.localId} doesn't have Action field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.method) this.errorsNew.push({
              text: `Security step local Id: ${item.localId} doesn't have Method field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.variables) this.errorsNew.push({
              text: `Security step ${item.name} (local Id: ${item.localId}) doesn't have any variable set.`,
              value: item.localId
            })
            if (item.properties && item.properties.variables) {
              const variablesKeys = Object.keys(item.properties.variables)

              if (variablesKeys && variablesKeys.length === 0) this.errorsNew.push({
                text: `Security step ${item.name} (local Id: ${item.localId}) doesn't have any variable set.`,
                value: item.localId
              })

              if (variablesKeys.length > 0) {
                variablesKeys.forEach((variable) => {
                  if (!this.$options.filters.javaVariableConventionRules(variable, true)) this.errorsNew.push({
                    text: `Security step ${item.name} (local Id: ${item.localId}) variable name ${variable} is invalid.`,
                    value: item.localId
                  })
                  if (!item.properties.variables[variable]) this.errorsNew.push({
                    text: `Security step ${item.name} (local Id: ${item.localId}) variable name ${variable} value is not set.`,
                    value: item.localId
                  })
                })
              }
            }
            break
          case 'UNSET_VARIABLES':
            if (!item.name) this.errorsNew.push({
              text: `Unset step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.variables) this.errorsNew.push({
              text: `Unset step ${item.name} (local Id: ${item.localId}) doesn't have any variable set.`,
              value: item.localId
            })
            if (item.properties && item.properties.variables && item.properties.variables.length === 0) this.errorsNew.push({
              text: `Unset step ${item.name} (local Id: ${item.localId}) doesn't have any variable set.`,
              value: item.localId
            })
            if (item.properties && item.properties.variables && item.properties.variables.length > 0) {
              item.properties.variables.forEach((variable) => {
                if (!this.$options.filters.javaVariableConventionRules(variable, true)) this.errorsNew.push({
                  text: `Unset step ${item.name} (local Id: ${item.localId}) variable name ${variable} is invalid.`,
                  value: item.localId
                })
              })
            }
            break
          case 'S3':
            if (!item.name) this.errorsNew.push({
              text: `S3 step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.credentialName) this.errorsNew.push({
              text: `S3 step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `S3 step local Id: ${item.localId} doesn't have Action field set.`,
              value: item.localId
            })
            if (item.action && item.action === 'READ') {
              if (item.properties && item.properties.fields && !item.properties.fields.targetObject) this.errorsNew.push({
                text: `S3 step ${item.name} (local Id: ${item.localId}) doesn't have Target Object set.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && item.properties.fields.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.fields.targetObject, true)) this.errorsNew.push({
                text: `S3 step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && !item.properties.fields.key) this.errorsNew.push({
                text: `S3 step ${item.name} (local Id: ${item.localId}) doesn't have Key set.`,
                value: item.localId
              })
              delete item.properties.fields.content
            }
            if (item.action && item.action === 'DELETE') {
              delete item.properties.fields.content
              if (item.properties && item.properties.fields && !item.properties.fields.key) this.errorsNew.push({
                text: `S3 step ${item.name} (local Id: ${item.localId}) doesn't have Key set.`,
                value: item.localId
              })
            }
            if (item.action && item.action === 'CREATE') {
              if (item.properties && item.properties.fields && !item.properties.fields.key) this.errorsNew.push({
                text: `S3 step ${item.name} (local Id: ${item.localId}) doesn't have Key set.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && !item.properties.fields.content) this.errorsNew.push({
                text: `S3 step ${item.name} (local Id: ${item.localId}) doesn't have Content set.`,
                value: item.localId
              })
            }
            if (item.action && item.action === 'LIST') {
              delete item.properties.fields.content
              delete item.properties.fields.key
              if (item.properties && item.properties.fields && !item.properties.fields.targetObject) this.errorsNew.push({
                text: `S3 step ${item.name} (local Id: ${item.localId}) doesn't have Target Object set.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && item.properties.fields.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.fields.targetObject, true)) this.errorsNew.push({
                text: `S3 step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
                value: item.localId
              })
            }
            break
          case 'USER':
            if (!item.name) this.errorsNew.push({
              text: `User step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `User step local Id: ${item.localId} doesn't have Action field set.`,
              value: item.localId
            })
            if (item.action && !['GET_USER_DETAILS', 'GET_USER_ROLES', 'GET_USERS_BY_ROLES'].includes(item.action)) {
              if (item.properties && item.properties.fields && !item.properties.fields.email) this.errorsNew.push({
                text: `User step ${item.name} (local Id: ${item.localId}) doesn't have Email set.`,
                value: item.localId
              })
            }
            if (item.properties && item.properties.fields && !item.properties.fields.targetObject) this.errorsNew.push({
              text: `User step ${item.name} (local Id: ${item.localId}) doesn't have Target Object set.`,
              value: item.localId
            })
            if (item.properties && item.properties.fields && item.properties.fields.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.fields.targetObject, true)) this.errorsNew.push({
              text: `User step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
              value: item.localId
            })
            if (item.action && item.action === 'GET_USERS_BY_ROLES') {
              delete item.properties.fields.name
              delete item.properties.fields.apiKey
              delete item.properties.fields.password
              delete item.properties.fields.userId
              delete item.properties.fields.email
            }
            if (item.action && item.action === 'CHECK_EMAIL_AVAILABILITY') {
              delete item.properties.fields.name
              delete item.properties.fields.roles
              delete item.properties.fields.apiKey
              delete item.properties.fields.password
              delete item.properties.fields.userId
            }
            if (item.action && item.action === 'CREATE_USER') {
              delete item.properties.fields.apiKey
              delete item.properties.fields.password
              delete item.properties.fields.userId
              if (item.properties && item.properties.fields && !item.properties.fields.name) this.errorsNew.push({
                text: `User step ${item.name} (local Id: ${item.localId}) doesn't have Name set.`,
                value: item.localId
              })
            }
            if (item.action && item.action === 'VALIDATE_API_KEY') {
              delete item.properties.fields.name
              delete item.properties.fields.roles
              delete item.properties.fields.password
              delete item.properties.fields.userId
              if (item.properties && item.properties.fields && !item.properties.fields.apiKey) this.errorsNew.push({
                text: `User step ${item.name} (local Id: ${item.localId}) doesn't have Api Key set.`,
                value: item.localId
              })
            }
            if (item.action && item.action === 'GENERATE_API_KEY') {
              delete item.properties.fields.name
              delete item.properties.fields.roles
              delete item.properties.fields.apiKey
              delete item.properties.fields.userId
              if (item.properties && item.properties.fields && !item.properties.fields.password) this.errorsNew.push({
                text: `User step ${item.name} (local Id: ${item.localId}) doesn't have Password set.`,
                value: item.localId
              })
            }
            if (item.action && item.action === 'VALIDATE_PASSWORD') {
              delete item.properties.fields.name
              delete item.properties.fields.roles
              delete item.properties.fields.apiKey
              delete item.properties.fields.userId
              if (item.properties && item.properties.fields && !item.properties.fields.password) this.errorsNew.push({
                text: `User step ${item.name} (local Id: ${item.localId}) doesn't have Password set.`,
                value: item.localId
              })
            }
            if (item.action && ['GET_USER_DETAILS', 'GET_USER_ROLES'].includes(item.action)) {
              delete item.properties.fields.email
              delete item.properties.fields.name
              delete item.properties.fields.roles
              delete item.properties.fields.apiKey
              delete item.properties.fields.password
              if (item.properties && item.properties.fields && !item.properties.fields.userId) this.errorsNew.push({
                text: `User step ${item.name} (local Id: ${item.localId}) doesn't have User ID set.`,
                value: item.localId
              })
              if (item.properties && item.properties.fields && item.properties.fields.userId && !this.$options.filters.javaVariableConventionRules(item.properties.fields.userId, true)) this.errorsNew.push({
                text: `User step ${item.name} (local Id: ${item.localId}) User ID field is invalid.`,
                value: item.localId
              })
            }
            break
          case 'JDBC':
            if (!item.name) this.errorsNew.push({
              text: `Jdbc step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.credentialName) this.errorsNew.push({
              text: `Jdbc step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `Jdbc step ${item.name} (local Id: ${item.localId}) doesn't have Action field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.query) this.errorsNew.push({
              text: `Jdbc step ${item.name} (local Id: ${item.localId}) doesn't have Query field set.`,
              value: item.localId
            })
            if (item.properties.action && item.properties.query && ['BEGIN TRANSACTION', 'COMMIT TRANSACTION', 'ROLLBACK TRANSACTION'].includes(item.properties.action)) {
              item.properties.action = 'CUSTOM'
            }
            break
          case 'MONGODB':
            if (!item.name) this.errorsNew.push({
              text: `MongoDb step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (['FIND', 'UPDATE', 'DELETE'].includes(item.properties.action)) {
              if (!item.properties.query) this.errorsNew.push({
                text: `MongoDb step ${item.name} (local Id: ${item.localId}) doesn't have Query field set.`,
                value: item.localId
              })
            }
            if (['INSERT', 'UPDATE'].includes(item.properties.action)) {
              if (!item.properties.queryUpdate) this.errorsNew.push({
                text: `MongoDb step ${item.name} (local Id: ${item.localId}) doesn't have Query Update field set.`,
                value: item.localId
              })
            }
            if (item.properties && item.properties.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.targetObject, true)) this.errorsNew.push({
              text: `MongoDb step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
              value: item.localId
            })

            break
          case 'EMAIL':
            if (!item.name) this.errorsNew.push({
              text: `Email step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (!item.properties) this.errorsNew.push({
              text: `Email step ${item.name} (local Id: ${item.localId}) not valid.`,
              value: item.localId
            })
            if (!item.properties.credentialName) this.errorsNew.push({
              text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
              value: item.localId
            })
            if (!item.properties.textTemplate) this.errorsNew.push({
              text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have Text Template set.`,
              value: item.localId
            })

            if (item.properties && !item.properties.to) this.errorsNew.push({
              text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have To field set.`,
              value: item.localId
            })

            if (item.properties && item.properties.to) {
              if (!this.$options.filters.javaVariableConventionRules(item.properties.to, true) && !this.emailRules(item.properties.to)) this.errorsNew.push({
                text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have correct To field.`,
                value: item.localId
              })
            }

            if (item.properties && item.properties.from) {
              if (!this.$options.filters.javaVariableConventionRules(item.properties.from, true) && !this.emailRules(item.properties.from)) this.errorsNew.push({
                text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have correct From field.`,
                value: item.localId
              })
            }

            if (item.properties.cc && item.properties.cc.length > 0) {
              item.properties.cc.forEach((ccItem) => {
                if (!this.$options.filters.javaVariableConventionRules(ccItem, true) && !this.emailRules(ccItem)) this.errorsNew.push({
                  text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have correct CC field.`,
                  value: item.localId
                })
              })
            }

            if (item.properties.bcc && item.properties.bcc.length > 0) {
              item.properties.bcc.forEach((bccItem) => {
                if (!this.$options.filters.javaVariableConventionRules(bccItem, true) && !this.emailRules(bccItem)) this.errorsNew.push({
                  text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have correct BCC field.`,
                  value: item.localId
                })
              })
            }

            if (!item.properties.variables || (item.properties.variables && !item.properties.variables.subject)) {
              this.errorsNew.push({
                text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have Subject variable set.`,
                value: item.localId
              })
            }

            if (item.properties.variables) {
              const tempVars = []

              for (const row in item.properties.variables) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.variables.hasOwnProperty(row)) {
                  tempVars.push({ text: row, value: item.properties.variables[row] })
                }
              }
              if (this.hasDuplicates(tempVars)) this.errorsNew.push({
                text: `Email step ${item.name} (local Id: ${item.localId}) has duplicate variable keys.`,
                value: item.localId
              })
            }

            if (item.properties && item.properties.variables && !item.properties.variables.locale) {
              delete item.properties.locale
            }
            if (item.properties && item.properties.variables && item.properties.variables.locale) {
              item.properties.locale = item.properties.variables.locale
              delete item.properties.variables.locale
            }
            if (item.properties.attachments && item.properties.attachments.length > 0) {
              item.properties.attachments.forEach((x) => {
                if (!x.name) this.errorsNew.push({
                  text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have attachment Name set.`,
                  value: item.localId
                })
                if (!x.content) this.errorsNew.push({
                  text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have attachment Content set.`,
                  value: item.localId
                })
                if (!x.contentType) this.errorsNew.push({
                  text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have attachment Content Type set.`,
                  value: item.localId
                })
              })
            } else {
              item.properties.attachments = null
            }
            break
          case 'SLACK':
            if (!item.name) this.errorsNew.push({
              text: `Slack step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.scriptEngine) this.errorsNew.push({
              text: `Slack step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.message && !item.properties.textTemplate) this.errorsNew.push({
              text: `Slack step ${item.name} (local Id: ${item.localId}) doesn't have Message or Template set.`,
              value: item.localId
            })
            if (item.properties.variables) {
              const tempVars = []

              for (const row in item.properties.variables) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.variables.hasOwnProperty(row)) {
                  tempVars.push({ text: row, value: item.properties.variables[row] })
                }
              }
              if (this.hasDuplicates(tempVars)) this.errorsNew.push({
                text: `Slack step ${item.name} (local Id: ${item.localId}) has duplicate variable keys.`,
                value: item.localId
              })
            }
            if (item.properties && item.properties.variables && !item.properties.variables.locale) {
              delete item.properties.locale
            }
            if (item.properties && item.properties.variables && item.properties.variables.locale) {
              item.properties.locale = item.properties.variables.locale
              delete item.properties.variables.locale
            }
            if (item.properties && item.properties.message && !this.$options.filters.checkComplexRegex(item.properties.message)) this.errorsNew.push({
              text: `Slack step ${item.name} (local Id: ${item.localId}) Message field is invalid.`,
              value: item.localId
            })
            if (item.properties && item.properties.channelId && !this.$options.filters.checkComplexRegex(item.properties.channelId)) this.errorsNew.push({
              text: `Slack step ${item.name} (local Id: ${item.localId}) Channel Id field is invalid.`,
              value: item.localId
            })
            break
          case 'JWT':
            if (!item.name) this.errorsNew.push({
              text: `Jwt step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.targetObject) this.errorsNew.push({
              text: `Jwt step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.targetObject, true)) this.errorsNew.push({
              text: `Jwt step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
              value: item.localId
            })
            break
          case 'FOREACH':
            if (!item.name) this.errorsNew.push({
              text: `ForEach step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.query) this.errorsNew.push({
              text: `ForEach step ${item.name} (local Id: ${item.localId}) doesn't have Query.`,
              value: item.localId
            })
            if (item.properties && !item.properties.recordName) this.errorsNew.push({
              text: `ForEach step ${item.name} (local Id: ${item.localId}) doesn't have Record Name field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.recordName && !this.$options.filters.javaVariableConventionRules(item.properties.recordName, true)) this.errorsNew.push({
              text: `ForEach step ${item.name} (local Id: ${item.localId}) Record Name is invalid.`,
              value: item.localId
            })
            break
          case 'EXECUTE_PROCESS':
            if (!item.name) this.errorsNew.push({
              text: `Execute process step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.childEventIdPath && !this.$options.filters.javaVariableConventionRules(item.properties.childEventIdPath, true)) this.errorsNew.push({
              text: `Execute process ${item.name} (local Id: ${item.localId}) Child event ID target object is invalid.`,
              value: item.localId
            })

            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `Execute process ${item.name} (local Id: ${item.localId}) doesn't have Action field set.`,
              value: item.localId
            })

            if ((item.properties && !item.properties.input) || (item.properties && item.properties.input && Object.keys(item.properties.input).length === 0)) this.errorsNew.push({
              text: `Execute process ${item.name} (local Id: ${item.localId}) doesn't have any Input set.`,
              value: item.localId
            })

            if (item.properties && item.properties.input) {
              for (const row in item.properties.input) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.input.hasOwnProperty(row)) {
                  if (!this.$options.filters.javaVariableConventionRules(row, true)) this.errorsNew.push({
                    text: `Execute process ${item.name} (local Id: ${item.localId}) Input key '${row}' is invalid.`,
                    value: item.localId
                  })
                }
              }
            }

            if (item.properties.action && item.properties.action !== 'ASYNC') {
              if (item.properties && item.properties.output) {
                for (const row in item.properties.output) {
                  // eslint-disable-next-line no-prototype-builtins
                  if (item.properties.output.hasOwnProperty(row)) {
                    if (!this.$options.filters.javaVariableConventionRules(row, true)) this.errorsNew.push({
                      text: `Execute process ${item.name} (local Id: ${item.localId}) Output key '${row}' is invalid.`,
                      value: item.localId
                    })
                  }
                }
              }
            }

            getProcesses({ name: item.properties.processName })
              .then((res) => {
                let foundValidations = null
                const [localProcess] = res.data.data.items

                getProcess({ id: localProcess.id })
                  .then(async (res) => {
                    if (res?.data?.data?.validationRuleId) {
                      foundValidations = await this.fetchValidationRecursive(res.data.data.validationRuleId)

                      if (item.properties && item.properties.input) {
                        const inputKeys = Object.keys(item.properties.input)
                        const containsAllValidations = foundValidations.every((key) => inputKeys.includes(key))

                        if (!containsAllValidations) {
                          this.errorsNew.push({
                            text: `Execute process ${item.name} (local Id: ${item.localId}) doesn't have all required Input fields  set.`,
                            value: item.localId
                          })
                        }
                      }
                    } else {
                      this.showExecuteInputs = true
                    }
                  })
                  .catch((err) => {
                    this.isLoadingProcesses = false
                    this.err = err
                  })
              })
              .catch((err) => {
                this.isLoadingProcesses = false
                this.err = err
              })
            break
          case 'REST':
            if (!item.name) this.errorsNew.push({
              text: `Rest step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `Rest step ${item.name} (local Id: ${item.localId}) doesn't have Action field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.scriptEngine) this.errorsNew.push({
              text: `Rest step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.timeout) this.errorsNew.push({
              text: `Rest step ${item.name} (local Id: ${item.localId}) doesn't have Timeout field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.targetObject) this.errorsNew.push({
              text: `Rest step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.targetObject, true)) this.errorsNew.push({
              text: `User step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
              value: item.localId
            })
            if (item.properties && !item.properties.targetUrl) this.errorsNew.push({
              text: `Rest step ${item.name} (local Id: ${item.localId}) doesn't have Target URL field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.targetUrl) {
              const first4 = item.properties.targetUrl.substring(0, 4)

              if (first4 === 'http') item.properties.targetUrl = `"${item.properties.targetUrl}"`
            }
            if (item.properties && item.properties.bodyScript) {
              item.properties.body = null
            }
            if (item.properties && item.properties.credentialName && item.properties.credentialName.name) {
              item.properties.credentialName = item.properties.credentialName.name
            }
            break
          case 'TWILIO':
            if (!item.name) this.errorsNew.push({
              text: `Twilio step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.credentialName) this.errorsNew.push({
              text: `Twilio step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.to) this.errorsNew.push({
              text: `Twilio step ${item.name} (local Id: ${item.localId}) doesn't have To field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.to && !this.$options.filters.checkComplexRegex(item.properties.to)) this.errorsNew.push({
              text: `Twilio step ${item.name} (local Id: ${item.localId}) To field is invalid.`,
              value: item.localId
            })
            if (item.properties && !item.properties.from) this.errorsNew.push({
              text: `Twilio step ${item.name} (local Id: ${item.localId}) doesn't have From field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.from && !this.$options.filters.checkComplexRegex(item.properties.from)) this.errorsNew.push({
              text: `Twilio step ${item.name} (local Id: ${item.localId}) From field is invalid.`,
              value: item.localId
            })
            if (item.properties && !item.properties.scriptEngine) this.errorsNew.push({
              text: `Twilio step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.message && !item.properties.textTemplate) this.errorsNew.push({
              text: `Twilio step ${item.name} (local Id: ${item.localId}) doesn't have Message or Template set.`,
              value: item.localId
            })
            if (item.properties && item.properties.variables && !item.properties.variables.locale) {
              delete item.properties.locale
            }
            if (item.properties && item.properties.variables && item.properties.variables.locale) {
              item.properties.locale = item.properties.variables.locale
              delete item.properties.variables.locale
            }
            break
          case 'WHILE':
            if (!item.name) this.errorsNew.push({
              text: `While step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (!item.properties.query) this.errorsNew.push({
              text: `While step ${item.name} (local Id: ${item.localId}) doesn't have Query.`,
              value: item.localId
            })
            if (item.properties && !item.properties.scriptEngine) this.errorsNew.push({
              text: `While step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine field set.`,
              value: item.localId
            })
            break
          case 'SWITCH':
            if (!item.name) this.errorsNew.push({
              text: `Switch step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.scriptEngine) this.errorsNew.push({
              text: `Switch step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine field set.`,
              value: item.localId
            })
            // eslint-disable-next-line no-case-declarations
            let hasElse = 0

            item.properties.conditions.forEach((query) => {
              if (query.query === 'else') hasElse++
              if (hasElse > 1) this.errorsNew.push({
                text: `Switch step ${item.name} (local Id: ${item.localId}) has multiple else-conditions.`,
                value: item.localId
              })
              if (!query.query) this.errorsNew.push({
                text: `Switch step ${item.name} (local Id: ${item.localId}) Query Q${query.localId} doesn't have Query set.`,
                value: item.localId
              })
              if (query.steps && query.steps.length === 0) this.errorsNew.push({
                text: `Switch step ${item.name} (local Id: ${item.localId}) Query Q${query.localId} doesn't have any Steps set.`,
                value: item.localId
              })
            })
            break
          case 'PROCESS_SETTING':
            if (!item.name) this.errorsNew.push({
              text: `Process Setting step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.processSettingName) this.errorsNew.push({
              text: `Process Setting step ${item.name} (local Id: ${item.localId}) doesn't have Setting Name set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.targetObject) this.errorsNew.push({
              text: `Process Setting step ${item.name} (local Id: ${item.localId}) doesn't have Target Object set.`,
              value: item.localId
            })
            if (item.properties && item.properties.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.targetObject, true)) this.errorsNew.push({
              text: `User step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
              value: item.localId
            })
            break
          case 'PLUGIN':
            if (!item.name) this.errorsNew.push({
              text: `Plugin step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.action) this.errorsNew.push({
              text: `Plugin step local Id: ${item.localId} doesn't have Action field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.pluginName) this.errorsNew.push({
              text: `Plugin step ${item.name} (local Id: ${item.localId}) doesn't have Plugin field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.input) {
              for (const row in item.properties.input) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.input.hasOwnProperty(row)) {
                  if (!this.$options.filters.javaVariableConventionRules(row, true)) this.errorsNew.push({
                    text: `Plugin step ${item.name} (local Id: ${item.localId}) Input key '${row}' is invalid.`,
                    value: item.localId
                  })
                }
              }
            }
            if (item.properties && item.properties.output) {
              for (const row in item.properties.output) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.output.hasOwnProperty(row)) {
                  if (!this.$options.filters.javaVariableConventionRules(row, true)) this.errorsNew.push({
                    text: `Plugin step ${item.name} (local Id: ${item.localId}) Output key '${row}' is invalid.`,
                    value: item.localId
                  })
                }
              }
            }
            break
          case 'JS':
            if (!item.name) this.errorsNew.push({
              text: `JavaScript step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if ((item.properties && !item.properties.set) || (item.properties && item.properties.set && Object.keys(item.properties.set).length === 0)) this.errorsNew.push({
              text: `JavaScript step local Id: ${item.localId} doesn't have Values set.`,
              value: item.localId
            })
            if (item.properties && item.properties.set) {
              for (const row in item.properties.set) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.set.hasOwnProperty(row)) {
                  if (!this.$options.filters.javaVariableConventionRules(row, true)) this.errorsNew.push({
                    text: `JavaScript step ${item.name} (local Id: ${item.localId}) Variable key '${row}' is invalid.`,
                    value: item.localId
                  })
                  // Removing for now, until we figure out complete validation - MD - 23.08.2022
                  // if (!this.$options.filters.checkComplexRegex(item.properties.set[row])) this.errorsNew.push({ text: `JavaScript step ${item.name} (local Id: ${item.localId}) Set value '${row}' is invalid.`, value: item.localId })
                }
              }
            }
            break
          case 'GROOVY':
            if (!item.name) this.errorsNew.push({
              text: `Groovy step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if ((item.properties && !item.properties.set) || (item.properties && item.properties.set && Object.keys(item.properties.set).length === 0)) this.errorsNew.push({
              text: `Groovy step local Id: ${item.localId} doesn't have Values set.`,
              value: item.localId
            })
            if (item.properties && item.properties.set) {
              for (const row in item.properties.set) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.set.hasOwnProperty(row)) {
                  if (!this.$options.filters.javaVariableConventionRules(row, true)) this.errorsNew.push({
                    text: `Groovy step ${item.name} (local Id: ${item.localId}) Variable key '${row}' is invalid.`,
                    value: item.localId
                  })
                  // Removing for now, until we figure out complete validation - MD - 23.08.2022
                  // if (!this.$options.filters.checkComplexRegex(item.properties.set[row])) this.errorsNew.push({ text: `Groovy step ${item.name} (local Id: ${item.localId}) Set value '${row}' is invalid.`, value: item.localId })
                }
              }
            }
            break
          default:
          }

          if (errorItemsLength !== this.errorsNew.length) {
            this.errorItems.push(item)
            errorItemsLength = this.errorsNew.length
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
            checkSteps(item.properties.steps)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0) {
            checkSteps(item.properties.try.steps)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0) {
            checkSteps(item.properties.finally.steps)
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
            item.properties.conditions.forEach((item2) => {
              checkSteps(item2.steps)
            })
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
            item.properties.catch.forEach((item2) => {
              checkSteps(item2.steps)
            })
          }
        }
      }

      checkSteps(this.process.steps.steps)

      await pause(2000)

      return errors
    },
    emailRules(v) {
      const isSplit = v.split(',')

      if (isSplit.length > 1) {
        let correct = true

        isSplit.forEach((testEmail) => {
          if (correct) {
            correct = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(testEmail.trim().toLowerCase())
          }
        })

        if (correct) return true

        return false
      } else {
        return (v && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v.toLowerCase()))
      }
    },
    async submit() {
      this.isError = ''
      this.isErrorShow = false
      this.lock = true
      this.loading = true

      const isError = await this.checkReqFields()

      if (isError || this.errorsNew.length > 0) {
        this.isErrorShow = true
        this.isError = isError
        this.lock = false
        this.loading = false

        return
      }

      const doesNameExists = (await getProcesses({ name: this.process.name })).data.data.items.find((x) => x.name === this.process.name)

      if (doesNameExists && doesNameExists.id !== this.process.id) {
        this.isErrorShow = true
        this.isError = this.$lang.errors.nameInUse
        this.lock = false
        this.loading = false

        return
      }

      if (Number(this.process.simultaneousExecutionsPerInstance) > Number(this.process.overallSimultaneousExecutions)) {
        this.isErrorShow = true
        this.isError = this.$lang.errors.mustLessExecutions
        this.lock = false
        this.loading = false

        return
      }

      let res = null

      const { id } = this.process

      if (!this.isEdit) {
        this.process.status = 'ACTIVE'
      }

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      this.process.roles = tempRoles

      this.process.logsTtlInMSec = this.$options.filters.unitFormatter(this.tempLogsTtlInMSec, this.timeUnitTtl, 'MSec')
      this.process.errorsTtlInMSec = this.$options.filters.unitFormatter(this.tempErrorsTtlInMSec, this.timeUnitErrTtl, 'MSec')
      this.process.maxProcessingDurationInMSec = this.$options.filters.unitFormatter(this.tempMaxProcessingDurationInMSec, this.timeUnitPDur, 'MSec')

      if (Number(this.process.maxProcessingDurationInMSec) > 31556926000) {
        this.err = this.$lang.errors.ttlToBig
        this.lock = false
        this.loading = false
        setTimeout(() => this.err = '', 5000)

        return
      }

      try {

        res = this.isEdit ? await updateProcess({
          id,
          processRequest: this.process
        }) : await createProcess({ processRequest: this.process })

        if (res && res.status !== 200) {
          this.err = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.processUpdate : this.$lang.errors.processCreate)
          setTimeout(() => this.err = '', 5000)
          this.lock = false
          this.loading = false

          return
        }
        this.success = this.isEdit ? this.$lang.success.processUpdated : this.$lang.success.processCreated
        setTimeout(() => this.success = '', 5000)
        this.lock = false
        this.loading = false

        if (!this.isEdit) {
          this.isEdit = true
          this.$router.push({
            name: 'processEdit',
            params: {
              id: res.data.data.id
            }
          })
        }

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    },
    async changeStatus() {
      this.lock = true
      try {
        const res = this.process.status === 'ACTIVE' ? await disableProcess({ id: this.process.id }) : await enableProcess({ id: this.process.id })

        if (res.status !== 200) {
          this.err = this.$lang.errors.processStatusUpdate
          setTimeout(() => this.err = '', 5000)
          this.lock = false

          return
        }
        this.success = this.process.status !== 'ACTIVE' ? this.$lang.success.processActivated : this.$lang.success.processDeactivated

        this.process.status = res.data.data.status

        this.lock = false

        setTimeout(() => this.success = '', 5000)

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    }
  }
}
</script>
<style lang="scss">
.border-me {
  position: absolute;
  left: 10px;
  top: 2px;
  height: 106%;
  background-color: var(--v-primary-base);
  opacity: 0.3;
  border-radius: 1px;
}

.process-name {
  color: var(--v-primary-base);
  cursor: pointer;
}

.is-log-selected-dark {
  background: var(--v-surface-base) 0 0 no-repeat padding-box !important;
  background-color: var(--v-customStepLogSelectBg-darken2) !important;
  color: var(--v-customStepLogSelect-darken2) !important;

  td > div > span {
    color: var(--v-primary-lighten2) !important;
  }
}

.is-log-selected-light {
  background: var(--v-surface-base) 0 0 no-repeat padding-box !important;
  background-color: var(--v-customStepLogSelectBg-lighten2) !important;
  color: var(--v-customStepLogSelect-lighten2) !important;

  td > div > span {
    color: var(--v-primary-darken2) !important;
  }
}

.table-bg {
  background-color: var(--v-surface-base) !important;
}

.bland-bg {
  background-color: var(--v-customDrawerBg-base) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.error-highlight {
  &:hover {
    opacity: 0.6;
  }
}

.this-screen-max-height {
  //max-height: calc(100% - 71px) !important;
}

.v-main {
  overflow: hidden !important;
  padding-bottom: 0 !important;
}

.toggle-left {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 20px;
  border-radius: 20px;
  background: #fff; // var(--v-primary-base)
  padding: 2px;
  cursor: pointer;
}

.toggle-right-list {
  transition: all 0.2s ease;
  min-width: 520px;
  width: 33%;
  position: relative;
  overflow-y: auto;

  &.is-close {
    width: 40px !important;
    min-width: 40px;
    overflow-y: hidden;
  }

  & [class*='_container'] {
    overflow: auto;
    max-height: calc(100% - 48px - 81px - 30px);
  }
}

.toggle-menu {
  .v-size--default {
    width: 28px !important;
    height: 28px !important;
  }
}

.visibility-hidden {
  visibility: hidden;
  width: 0 !important;
  max-width: 0 !important;
  overflow: hidden;
}

.process-container {
  height: calc(100vh - 82px) !important;
}

@media screen and (max-width: 1510px) {
  .flex-fill {
    min-width: 650px!important;
  }
}

.mermaid-style {
  background-color: var(--v-timelineBg-base) !important;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
}

#expansionProcessAdvanced {
  .v-expansion-panel-content__wrap {
    padding: 8px 8px !important;
  }
}

</style>
