<template>
  <div class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="6">
        <h3>{{ $lang.labels[type] }}</h3>
      </v-col>
      <v-col cols="6" class="text-right">
        <div class="d-inline-flex">
          <p v-if="type === 'cc'" class="px-1 pt-1 cursor-pointer color-primary" @click="$emit('hide')">{{ $lang.actions.hideCc }}</p>
          <p v-if="type === 'bcc'" class="px-1 pt-1 cursor-pointer color-primary" @click="$emit('hide')">{{ $lang.actions.hideBcc }}</p>
          <v-btn
            color="accent"
            icon
            class="color-accent-text"
            :disabled="!canEdit"
            @click="deconstructedData.push('')"
          >
            +
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col v-if="duplicated" cols="12" class="pb-2">
        <v-alert dense color="error" style="color: black">{{ $lang.errors.duplicatedVars }}</v-alert>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters align="center" class="pb-1">
          <v-col cols="1">
            <span>{{ $lang.header.id }}</span>
          </v-col>
          <v-col cols="9" class="px-1">
            <span>{{ $lang.header.value }}</span>
          </v-col>
          <v-col cols="2" class="text-right">
            <span>{{ $lang.header.act }}</span>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-row :key="i" no-gutters align="center" class="pb-1">
            <v-col cols="1">
              <span>{{ i + 1 }}</span>
            </v-col>
            <v-col cols="9" class="px-1">
              <v-text-field
                v-model="deconstructedData[i]"
                dense
                required
                :rules="[...rules]"
                :readonly="!canEdit"
                :data-cy="`${type}-value-${i}`"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-btn
                class="ml-1"
                icon
                small
                :disabled="!canEdit"
                @click="deleteRow(i)"
              >
                <v-icon small color="error">mdi-delete</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    },
    stepType: {
      type: String,
      default: () => {
        return ''
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    rules: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: () => ({
    deconstructedData: [],
    duplicated: false
  }),
  computed: {
  },
  watch: {
    deconstructedData: {
      handler(val) {
        this.duplicated = !!this.hasDuplicates(val)
        this.$emit('dataChanged', val)
      },
      deep: true
    }
  },
  created() {
    if (this.data && this.data.length > 0) {
      this.deconstructedData = this.data
    }
  },
  methods: {
    hasDuplicates(arr) {
      return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x))
    },
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
