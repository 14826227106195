<template>
  <div class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="6" class="d-inline-flex">
        <h3 class="pt-2">{{ $lang.labels[type] }}</h3>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          color="accent"
          icon
          class="color-accent-text"
          :disabled="!canEdit"
          @click="deconstructedData.push({ condition: '', query: '' })"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col cols="12">
        <v-row no-gutters align="center" class="pb-1">
          <v-col cols="1">
            <span v-if="userSettings.display.showId">{{ $lang.header.id }} </span>
          </v-col>
          <v-col cols="5">
            <span>{{ $lang.header.condition }}</span>
          </v-col>
          <v-col cols="6">
            <span>{{ $lang.header.query }}</span>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-row :key="i" no-gutters align="center" class="pb-1">
            <v-col cols="1">
              <span v-if="userSettings.display.showId">{{ i + 1 }}</span>
            </v-col>
            <v-col cols="5" style="position: relative">
              <v-text-field
                v-model="item.condition"
                dense
                :readonly="!canEdit"
                required
                :rules="[v => !!v || 'Required!']"
                :data-cy="`${type}-condition-${i}`"
              ></v-text-field>
              <v-btn
                absolute
                icon
                small
                :disabled="!canEdit"
                style="top: 0; right: 8px"
                @click="selectedItemIndex = i; selectedRowType = 'condition'; showGlobalEditor = true"
              >
                <v-icon small color="info">mdi-file-edit-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="4" class="px-1 align-center" style="position: relative">
              <v-text-field
                v-model="item.query"
                dense
                required
                :rules="[v => !!v || 'Required!']"
                :readonly="!canEdit"
                :data-cy="`${type}-query-${i}`"
              ></v-text-field>
              <v-btn
                absolute
                icon
                small
                :disabled="!canEdit"
                style="top: 0; right: 8px"
                @click="selectedItemIndex = i; selectedRowType = 'query'; showGlobalEditor = true"
              >
                <v-icon small color="info">mdi-file-edit-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="d-inline-flex">
                <v-btn
                  class="ml-1"
                  icon
                  small
                  :disabled="!canEdit"
                  @click="deleteRow(i)"
                >
                  <v-icon small color="error">mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
    <v-dialog v-if="showGlobalEditor" v-model="showGlobalEditor" max-width="71%">
      <editor
        :item="{ text: '', value: deconstructedData[selectedItemIndex][selectedRowType], parentType: '' }"
        :language="language"
        :vars="vars"
        :step="step"
        @fromEditor="updateFromEditor($event)"
        @closeDialog="showGlobalEditor = false; selectedItemIndex = null; selectedRowType = ''"
      ></editor>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import editor from '../../pages/processes/Editor'

export default {
  components: {
    editor
  },
  props: {
    language: {
      type: String,
      default: () => {
        return 'en'
      }
    },
    vars: {
      type: Array,
      default: () => {
        return []
      }
    },
    step: {
      type: Object,
      default: () => {
        return {}
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    },
    stepType: {
      type: String,
      default: () => {
        return ''
      }
    },
    full: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({
    deconstructedData: [],
    showGlobalEditor: false,
    selectedItemIndex: null,
    selectedRowType: ''
  }),
  computed: {
    ...mapState('app', ['userSettings'])
  },
  watch: {
    deconstructedData: {
      handler() {
        this.$emit('dataChanged', this.deconstructedData)
      },
      deep: true
    }
  },
  created() {
    this.deconstructedData = this.data ? structuredClone(this.data) : []
  },
  methods: {
    updateFromEditor(data) {
      this.deconstructedData[this.selectedItemIndex][this.selectedRowType] = data.value
    },
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    },
    editRow(i, type) {
      this.$emit('editor', { data: this.deconstructedData[i], type })
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
