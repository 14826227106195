<template>
  <v-row wrap no-gutters class="fill-height">
    <v-col
      cols="12"
      class="justify-end text-right"
      style="height: 48px; padding: 0 16px;"
    >
      <v-btn
        icon
        color="primary"
        class="ml-2"
        @click="zoomHandler('out')"
      >
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        class="ml-1"
        @click="zoomHandler('reset')"
      >
        Reset
      </v-btn>
      <v-btn
        icon
        color="primary"
        class="ml-1"
        @click="zoomHandler('in')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        icon
        color="primary"
        :loading="loadingSave"
        class="ml-3"
        @click="copyDiagram()"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      class="justify-center text-center fill-height"
      style="overflow: hidden; border: 1px solid #e5e5e5; border-radius: 8px;padding: 4px"
    >
      <vue-mermaid
        :nodes="mermaidFormatted"
        :type="mermaidType ? 'graph LR' : 'graph TD'"
        @nodeClick="goToStep"
      ></vue-mermaid>
    </v-col>
  </v-row>
</template>

<script>
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'
import panzoom from 'panzoom'
export default {
  name: 'ProcessMermaid',
  props: {
    mermaidData: {
      type: Array,
      default: () => []
    },
    mermaidType: {
      type: Boolean,
      default: () => false
    },
    highestId: {
      type: Number,
      default: () => 0
    },
    mermaidId: {
      type: Number,
      default: () => 0
    },
    mermaidGrouping: {
      type: Boolean,
      default: () => false
    },
    processName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loadingSave: false,
      mermaidFormatted: [],
      switchIds: [],
      groupArr: [],
      gotToArr: [],
      zoomInstance: null,
      zoomValue: 1,
      backgroundColor: '#ECECFF',
      strokeColor: '#9370DB',
      endNodeStroke: '#594771',
      linkLineColor: '#594771'
    }
  },
  watch: {
    mermaidData: {
      handler: function (val) {
        this.mermaidFormatted = []
        this.switchIds = []
        this.groupArr = []
        this.gotToArr = []
        this.formatMermaidData(val)
      },
      deep: true
    },
    mermaidGrouping: {
      handler: function () {
        this.mermaidFormatted = []
        this.switchIds = []
        this.groupArr = []
        this.gotToArr = []
        this.formatMermaidData(this.mermaidData)
      }
    }
  },
  created() {
    if (this.$vuetify.theme.isDark) {
      this.backgroundColor = this.$vuetify.theme.themes.dark.mermaid.backgroundColor
      this.strokeColor = this.$vuetify.theme.themes.dark.mermaid.strokeColor
      this.fontColor = this.$vuetify.theme.themes.dark.mermaid.fontColor
      this.endNodeStroke = this.$vuetify.theme.themes.dark.mermaid.endNodeStroke
      this.linkLineColor = this.$vuetify.theme.themes.dark.mermaid.linkLineColor
    } else {
      this.backgroundColor = this.$vuetify.theme.themes.light.mermaid.backgroundColor
      this.strokeColor = this.$vuetify.theme.themes.light.mermaid.strokeColor
      this.fontColor = this.$vuetify.theme.themes.light.mermaid.fontColor
      this.endNodeStroke = this.$vuetify.theme.themes.light.mermaid.endNodeStroke
      this.linkLineColor = this.$vuetify.theme.themes.light.mermaid.linkLineColor
    }
    this.formatMermaidData(this.mermaidData)

    setTimeout(() => {
      const el = document.getElementById('mermaid')

      this.zoomInstance = panzoom(el, {
        maxZoom: 10,
        minZoom: 0.1,
        smoothScroll: true,
        bounds: false
      })
    }, 1000)
  },
  methods: {
    zoomHandler(direction) {
      if (direction === 'reset') {
        this.zoomInstance.zoomAbs(0, 0, 1)

        return
      }
      if (direction === 'in') {
        this.zoomInstance.smoothZoom(0.5, 0.5, 1.1)
      } else {
        this.zoomInstance.smoothZoom(0.5, 0.5, 0.9)
      }
    },
    copyDiagram() {
      this.loadingSave = true
      const element = document.getElementById('mermaid')

      const options = {
        backgroundColor: '#ffffff',
        canvasWidth: element.offsetWidth * 3,
        canvasHeight: element.offsetHeight * 3
      }

      htmlToImage.toPng(element, options)
        .then((dataUrl) => {
          download(dataUrl, `${this.processName}.png` || 'mermaid.png')
          this.loadingSave = false
        })
    },
    goToStep(nodeData) {
      this.$emit('goToStep', nodeData)
    },
    findHighestMermaidIdInSwitchCondition(switchItem) {
      let highestId = 0

      const formatSteps = (items) => {
        for (const item of items) {

          if (item.mermaidId > highestId) {
            highestId = item.mermaidId
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
            formatSteps(item.properties.steps)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0) {
            formatSteps(item.properties.try.steps)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0) {
            formatSteps(item.properties.finally.steps)
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
            item.properties.catch.forEach((item2) => {
              formatSteps(item2.steps)
            })
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
            for (const item2 of item.properties.conditions) {
              formatSteps(item2.steps)
            }
          }
        }
      }

      switchItem.forEach((f) => {
        formatSteps(f.steps)
      })

      return highestId
    },
    findHighestMermaidIdInCondition(itemsOuter) {
      let highestId = 0

      const formatSteps = (items) => {
        for (const item of items) {

          if (item.mermaidId > highestId) {
            highestId = item.mermaidId
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
            formatSteps(item.properties.steps)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0) {
            formatSteps(item.properties.try.steps)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0) {
            formatSteps(item.properties.finally.steps)
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
            item.properties.catch.forEach((item2) => {
              formatSteps(item2.steps)
            })
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
            for (const item2 of item.properties.conditions) {
              formatSteps(item2.steps)
            }
          }
        }
      }

      formatSteps(itemsOuter)

      return highestId
    },
    formatMermaidData(data) {
      // console.log(data)
      // CSV, EMAIL, EXECUTE_PROCESS, FOREACH, GROOVY, IMAGE, IMAP, JDBC, JS, JWT, MONGODB, PDF, PLUGIN, PROCESS_SETTING, REST, S3, SECURITY, SLACK, SWITCH, TWILIO, UNSET_VARIABLES, USER, WHILE

      this.mermaidFormatted.push({
        id: String(0),
        text: '" "',
        edgeType: 'circle',
        linkNumber: 0,
        linkStyle: this.colorLinkBasedOnNodeTypeSelector({ type: 'DEFAULT' }),
        editable: false,
        next: ['1'],
        style: `fill:${this.backgroundColor},stroke:${this.strokeColor},stroke-width:16px`
      })

      const formatSteps = async (items, depth = 0, maxMermaidId = 0) => {

        for (const item of items) {
          const query = []
          const index = items.indexOf(item)
          let isElse = true
          const switchNext = []

          if (item.type === 'SWITCH') {
            item.maxMermaidId = this.findHighestMermaidIdInSwitchCondition(item.properties.conditions)
            this.groupArr.push({
              minId: item.mermaidId,
              maxId: item.maxMermaidId + 1,
              name: item.name
            })

            item.properties.conditions.forEach((f, i) => {
              query.push('-.->')
              switchNext.push(f.mermaidId)

              this.mermaidFormatted.push({
                id: f.mermaidId,
                text: `"${this.iconSelector('QUERY')} Query: ${this.nameFormatter(f.name) || encodeURI(f.query)}"`,
                edgeType: 'hexagon',
                link: ['-.->'],
                linkStyle: this.colorLinkBasedOnNodeTypeSelector(item),
                next: [f.steps[0].mermaidId],
                editable: true,
                group: this.mermaidGrouping ? this.groupFormatter(item) : ''
              })

              const findMaxInner = this.findHighestMermaidIdInCondition(f.steps)

              this.switchIds.push({ goToValue: depth > 1 ? maxMermaidId + 1 : item.maxMermaidId + 1, checkValue: findMaxInner })
            })
            if (item.properties.conditions && item.properties.conditions.length > 0) {
              isElse = item.properties.conditions.find((f) => f.query === 'else')

              if (!isElse) {
                this.mermaidFormatted.push({
                  id: String(item.mermaidId + 999),
                  text: `"${this.iconSelector('ELSE')} Query: Else"`,
                  edgeType: 'hexagon',
                  link: ['-->'],
                  linkNumber: item.mermaidId + 999,
                  next: depth > 1 ? [maxMermaidId + 1] : [item.maxMermaidId + 1],
                  editable: false,
                  group: this.mermaidGrouping ? this.groupFormatter(item) : ''
                })
              }
            }
          } else if (['WHILE', 'FOREACH'].includes(item.type)) {
            item.maxMermaidId = this.findHighestMermaidIdInCondition(item.properties.steps)
            this.groupArr.push({
              minId: item.mermaidId,
              maxId: item.maxMermaidId + 1,
              name: item.name
            })
            this.gotToArr.push({ goToValue: item.maxMermaidId + 1, checkValue: item.maxMermaidId })
            this.gotToArr.push({ goToValue: item.mermaidId, checkValue: item.maxMermaidId })
            query.push('-->')
          } else if (item.type === 'TRY_CATCH') {
            item.maxMermaidTryId = this.findHighestMermaidIdInCondition(item.properties?.try?.steps || [])
            item.minMermaidFinallyId = item.properties?.finally?.steps ? item.properties?.finally?.mermaidId : 0
            item.maxMermaidFinallyId = this.findHighestMermaidIdInCondition(item.properties?.finally?.steps || [])
            const catchIdsArr = []

            item.properties.catch.forEach((f) => {
              catchIdsArr.push(this.findHighestMermaidIdInCondition(f.steps))
            })
            item.maxMermaidCatchId = Math.max(...catchIdsArr)
            item.maxMermaidId = Math.max(item.maxMermaidTryId, item.maxMermaidCatchId, item.maxMermaidFinallyId)
            this.groupArr.push({
              minId: item.mermaidId,
              maxId: item.maxMermaidId + 1,
              name: item.name
            })
            catchIdsArr.forEach((f) => {
              this.gotToArr.push({ goToValue: item.minMermaidFinallyId ? item.minMermaidFinallyId : item.maxMermaidId + 1, checkValue: f })
            })
            this.gotToArr.push({ goToValue: item.minMermaidFinallyId ? item.minMermaidFinallyId : item.maxMermaidId + 1, checkValue: item.maxMermaidTryId })
            this.gotToArr.push({ goToValue: item.maxMermaidId + 1, checkValue: item.maxMermaidFinallyId })
            query.push('-->')
          } else {
            query.push('-->')
          }

          const nextLocal = this.nextSelector(items, item, index)

          if (item.type === 'TRY_CATCH') {
            const tempTryCatchMermaidIdArray = []

            if (item.properties?.try?.mermaidId) {
              tempTryCatchMermaidIdArray.push(item.properties.try.mermaidId)
            }
            this.mermaidFormatted.push({
              id: String(item.mermaidId),
              text: `"${this.iconSelector('TRY_CATCH')} ${item.mermaidId} - ${this.nameFormatter(item.name)}"`,
              edgeType: this.edgeTypeSelector(item),
              link: ['-->', '-->'],
              linkStyle: this.colorLinkBasedOnNodeTypeSelector(item),
              linkNumber: item.mermaidId,
              next: [...tempTryCatchMermaidIdArray, 'C' + item.mermaidId],
              editable: true,
              style: item.enabled ? this.colorBasedOnNodeTypeSelector(item) : 'fill: darkgrey, color: grey',
              group: this.mermaidGrouping ? this.groupFormatter(item) : ''
            })
            if (item.properties?.try?.mermaidId) {
              this.mermaidFormatted.push({
                id: String(item.properties.try.mermaidId),
                text: `"${this.iconSelector('TRY')} ${item.properties?.try?.name ? this.nameFormatter(item.properties?.try?.name) : 'Try'}"`,
                edgeType: 'hexagon',
                link: ['-->'],
                linkStyle: this.colorLinkBasedOnNodeTypeSelector(item),
                linkNumber: String('T' + item.mermaidId),
                next: [item.properties.try.steps[0].mermaidId],
                editable: true,
                style: item.enabled ? this.colorBasedOnNodeTypeSelector(item) : 'fill: darkgrey, color: grey',
                group: this.mermaidGrouping ? this.groupFormatter(item) : ''
              })
            }
            if (item.properties?.finally?.mermaidId) {
              this.mermaidFormatted.push({
                id: String(item.properties.finally.mermaidId),
                text: `"${this.iconSelector('FINALLY')} ${item.properties?.finally?.name ? this.nameFormatter(item.properties?.finally?.name) : 'Finally'}"`,
                edgeType: 'hexagon',
                link: ['-->'],
                linkStyle: this.colorLinkBasedOnNodeTypeSelector(item),
                linkNumber: String('F' + item.mermaidId),
                next: [item.properties.finally.steps[0].mermaidId],
                editable: true,
                style: item.enabled ? this.colorBasedOnNodeTypeSelector(item) : 'fill: darkgrey, color: grey',
                group: this.mermaidGrouping ? this.groupFormatter(item) : ''
              })
            }
            this.mermaidFormatted.push({
              id: String('C' + item.mermaidId),
              text: `"${this.iconSelector('CATCH')} Catch"`,
              edgeType: 'hexagon',
              link: ['-->'],
              linkStyle: this.colorLinkBasedOnNodeTypeSelector(item),
              linkNumber: String('C' + item.mermaidId),
              next: item.properties.catch.map((y,n) => y.mermaidId),
              editable: false,
              style: item.enabled ? this.colorBasedOnNodeTypeSelector(item) : 'fill: darkgrey, color: grey',
              group: this.mermaidGrouping ? this.groupFormatter(item) : ''
            })
            if (item.properties.catch && item.properties.catch.length > 0) {
              item.properties.catch.forEach((f, i) => {
                this.mermaidFormatted.push({
                  id: String(f.mermaidId),
                  text: `"${this.iconSelector('EXCEPTION')} ${f.name ? this.nameFormatter(f.name) : f.exceptions && f.exceptions.length > 0 ? f.exceptions.join(', ') : `Exception - ${i + 1}`}"`,
                  edgeType: 'hexagon',
                  link: ['-->'],
                  linkStyle: this.colorLinkBasedOnNodeTypeSelector(item),
                  linkNumber: String('C' + item.mermaidId),
                  next: [f.steps[0].mermaidId],
                  editable: true,
                  style: item.enabled ? this.colorBasedOnNodeTypeSelector(item) : 'fill: darkgrey, color: grey',
                  group: this.mermaidGrouping ? this.groupFormatter(item) : ''
                })
              })
            }
          } else if (item.type === 'SWITCH') {
            this.mermaidFormatted.push({
              id: String(item.mermaidId),
              text: `"${this.iconSelector(item.type)} ${item.mermaidId} - ${this.nameFormatter(item.name)}"`,
              edgeType: this.edgeTypeSelector(item),
              link: query,
              linkStyle: this.colorLinkBasedOnNodeTypeSelector(item),
              next: switchNext && switchNext.length > 0 ? [...new Set(switchNext)] : [this.mermaidId + 1],
              editable: true,
              style: item.enabled ? this.colorBasedOnNodeTypeSelector(item) : 'fill: darkgrey, color: grey',
              group: this.mermaidGrouping ? this.groupFormatter(item) : ''
            })
          } else {
            this.mermaidFormatted.push({
              id: String(item.mermaidId),
              text: `"${this.iconSelector(item.type)} ${item.mermaidId} - ${this.nameFormatter(item.name)}"`,
              edgeType: this.edgeTypeSelector(item),
              link: query,
              linkStyle: this.colorLinkBasedOnNodeTypeSelector(item),
              next: nextLocal && nextLocal.length > 0 ? [...new Set(nextLocal)] : [this.mermaidId + 1],
              editable: true,
              style: item.enabled ? this.colorBasedOnNodeTypeSelector(item) : 'fill: darkgrey, color: grey',
              group: this.mermaidGrouping ? this.groupFormatter(item) : ''
            })
          }

          if (nextLocal && nextLocal.length > 0 && Number(nextLocal[nextLocal.length - 1]) <  item.mermaidId) {
            query.push('-.->')
          }

          if (!isElse) {
            this.mermaidFormatted[this.mermaidFormatted.length - 1].link.push('-.->')
            this.mermaidFormatted[this.mermaidFormatted.length - 1].next.push(item.mermaidId + 999)
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
            formatSteps(item.properties.steps, depth + 1, depth > 1 ? maxMermaidId : item.maxMermaidId)
          }
          if (item.properties?.try?.steps && item.properties.try.steps.length > 0) {
            formatSteps(item.properties.try.steps, depth + 1, depth > 1 ? maxMermaidId : item.maxMermaidId)
          }
          if (item.properties?.finally?.steps && item.properties.finally.steps.length > 0) {
            formatSteps(item.properties.finally.steps, depth + 1, depth > 1 ? maxMermaidId : item.maxMermaidId)
          }
          if (item.properties && item.properties.catch && item.properties.catch.length > 0) {
            for (const item2 of item.properties.catch) {
              formatSteps(item2.steps,  depth + 1, depth > 1 ? maxMermaidId : item.maxMermaidId)
            }
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
            for (const item3 of item.properties.conditions) {
              formatSteps(item3.steps,  depth + 1, depth > 1 ? maxMermaidId : item.maxMermaidId)
            }
          }
        }
      }

      formatSteps(data)

      this.mermaidFormatted.push({
        id: String(this.mermaidId + 1),
        text: '"fa:fa-circle"',
        edgeType: 'circle',
        linkNumber: this.mermaidId + 1,
        editable: false,
        group: null,
        style: `fill:${this.strokeColor},stroke:${this.endNodeStroke},stroke-width:16px`
      })
    },
    nextSelector(items, item, index) {
      const result = []

      if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
        item.properties.conditions.forEach((item2) => {
          result.push(String(item2.steps[0].mermaidId))
        })
      } else if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
        result.push(String(item.properties.steps[0].mermaidId))
      }  else if (items[index + 1]) {
        result.push(String(items[index + 1].mermaidId))
      } else {
        if (this.switchIds.length > 0) {
          const filteredSwitch = this.switchIds.filter((f) => f.checkValue === item.mermaidId)

          if (filteredSwitch.length > 0) {
            filteredSwitch.forEach((f) => {
              result.push(String(f.goToValue))
              const indexOfEl = this.switchIds.findIndex((i) => i.checkValue === f.checkValue)

              this.switchIds.splice(indexOfEl, 1)
            })
          }
        }
      }

      if (this.gotToArr && this.gotToArr.length > 0) {
        const filtered = this.gotToArr.filter((f) => f.checkValue === item.mermaidId)

        if (filtered.length > 0) {
          filtered.forEach((f) => {
            result.push(String(f.goToValue))
            const indexOfEl = this.gotToArr.findIndex((i) => i.checkValue === f.checkValue)

            this.gotToArr.splice(indexOfEl, 1)
          })
        }
      }

      return result
    },
    nameFormatter(item) {
      if (!item) return ''

      return item.toString().replace(/"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
    },
    groupFormatter(item) {
      let groupName = ''

      this.groupArr.forEach((item2) => {
        if (item.mermaidId >= item2.minId || item.mermaidId <= item2.maxId) {
          groupName = item2.name
        }
      })

      return groupName
    },
    edgeTypeSelector(item) {
      if (item.type === 'SWITCH') return 'rhombus'
      if (item.type === 'EXECUTE_EXTERNAL_COMMAND') return 'subroutine'
      if (item.type === 'EXECUTE_PROCESS') return 'subroutine'
      if (item.type === 'JDBC') return 'cylindrical'
      if (item.type === 'MONGODB') return 'cylindrical'

      return 'default'
    },
    colorBasedOnNodeTypeSelector(item) {
      if (item.type === 'SWITCH') return `fill:${this.backgroundColor},stroke:${this.strokeColor},stroke-width:1px`
      if (item.type === 'EXECUTE_EXTERNAL_COMMAND') return `fill:${this.backgroundColor},stroke:${this.strokeColor},stroke-width:1px`
      if (item.type === 'EXECUTE_PROCESS') return `fill:${this.backgroundColor},stroke:${this.strokeColor},stroke-width:1px`
      if (item.type === 'JDBC') return `fill:${this.backgroundColor},stroke:${this.strokeColor},stroke-width:1px`
      if (item.type === 'MONGODB') return `fill:${this.backgroundColor},stroke:${this.strokeColor},stroke-width:1px`

      return `fill:${this.backgroundColor},stroke:${this.strokeColor},stroke-width:1px`
    },
    colorLinkBasedOnNodeTypeSelector(item) {
      if (item.type === 'ELSE') return `fill:none,stroke:${this.linkLineColor},stroke-width:1px`
      if (item.type === 'SWITCH') return `fill:none,stroke:${this.linkLineColor},stroke-width:1px`
      if (item.type === 'EXECUTE_EXTERNAL_COMMAND') return `fill:none,stroke:${this.linkLineColor},stroke-width:1px`
      if (item.type === 'EXECUTE_PROCESS') return `fill:none,stroke:${this.linkLineColor},stroke-width:1px`
      if (item.type === 'JDBC') return `fill:none,stroke:${this.linkLineColor},stroke-width:1px`
      if (item.type === 'MONGODB') return `fill:none,stroke:${this.linkLineColor},stroke-width:1px`

      return `fill:none,stroke:${this.linkLineColor},stroke-width:1px`
    },
    iconSelector(itemType) {
      // CSV, EMAIL, EXECUTE_PROCESS, FOREACH, GROOVY, IMAGE, IMAP, JDBC, JS, JWT, MONGODB, PDF, PLUGIN, PROCESS_SETTING, REST, S3, SECURITY, SLACK, SWITCH, TWILIO, UNSET_VARIABLES, USER, WHILE, MESSAGING
      if (itemType === 'EMAIL') return 'fa:fa-envelope'
      if (itemType === 'GROOVY') return 'fa:fa-code'
      if (itemType === 'JS') return 'fab:fa-js'
      if (itemType === 'IMAGE') return 'fa:fa-image'
      if (itemType === 'JDBC') return 'fa:fa-database'
      if (itemType === 'MONGODB') return 'fa:fa-boxes-stacked'
      if (itemType === 'IMAP') return 'fa:fa-envelope-open'
      if (itemType === 'PDF') return 'fa:fa-file-pdf'
      if (itemType === 'PLUGIN') return 'fa:fa-plug'
      if (itemType === 'REST') return 'fa:fa-globe'
      if (itemType === 'S3') return 'fa:fa-warehouse'
      if (itemType === 'SLACK') return 'fab:fa-slack'
      if (itemType === 'SWITCH') return 'fa:fa-shuffle'
      if (itemType === 'FOREACH') return 'fa:fa-arrow-right-arrow-left'
      if (itemType === 'USER') return 'fa:fa-user'
      if (itemType === 'TWILIO') return 'fa:fa-comment-sms'
      if (itemType === 'WHILE') return 'fa:fa-arrow-rotate-left'
      if (itemType === 'CSV') return 'fa:fa-file-csv'
      if (itemType === 'TRY') return 'fa:fa-vials'
      if (itemType === 'FINALLY') return 'fa:fa-vial-circle-check'
      if (itemType === 'EXCEPTION') return 'fa:fa-vial-virus'
      if (itemType === 'QUERY') return 'fa:fa-question'
      if (itemType === 'ELSE') return 'fa:fa-circle'
      if (itemType === 'UNSET_VARIABLES') return 'fa:fa-broom'
      if (itemType === 'SECURITY') return 'fa:fa-lock'
      if (itemType === 'MESSAGING') return 'fa:fa-message'
      if (itemType === 'TRY_CATCH') return 'fa:fa-flask-vial'
      if (itemType === 'CATCH') return 'fa:fa-virus-slash'
      if (itemType === 'STORAGE') return 'far:fa-hard-drive'

      return ''
    }
  }
}
</script>

<style lang="scss">
svg > path {
  fill: var(--v-mermaidIconColor-base) !important;
}
//.nodes > g {
//  fill: black !important;
//}

//.cssClass > rect {
//  fill: #ff0000;
//  stroke: #ffff00;
//  stroke-width: 4px;
//}

.label {
  //font-size: 12px;
  //font-weight: normal;
  color: var(--v-mermaidFontColor-base) !important;
}
</style>
