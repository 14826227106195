<template>
  <json-viewer
    :value="data ? data : {}"
    :expand-depth="5"
    copyable
    boxed
    sort
    :theme="$vuetify.theme.dark ? 'my-awesome-json-theme-dark' : 'my-awesome-json-theme-light'"
    @keyclick="keyClick"
  />
</template>

<script>
import copy from 'copy-to-clipboard'
export default {
  name: 'JsonCustomViewer',
  props: {
    data: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    keyClick(data) {
      copy(data)
      this.$emit('success', 'Copied')
      setTimeout(() => {
        this.$emit('success', '')
      }, 3000)
    }
  }
}
</script>

<style lang="scss">
.jv-container .jv-code.boxed {
  max-height: 700px
}

.my-awesome-json-theme-dark {
  background: #272727;
  white-space: nowrap;
  color: #edf0f2;
  font-size: 16px;
  font-family: Consolas, Menlo, Courier, monospace;

  .jv-ellipsis {
    color: #009fb7;
    background-color: #eee;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }

  .jv-button {
    color: #49b3ff
  }

  .jv-key {
    color: #edf0f2;
    cursor: pointer;
  }

  .jv-item {
    &.jv-array {
      color: #edf0f2
    }

    &.jv-boolean {
      color: #fc1e70
    }

    &.jv-function {
      color: #067bca
    }

    &.jv-number {
      color: #fc1e70
    }

    &.jv-number-float {
      color: #fc1e70
    }

    &.jv-number-integer {
      color: #fc1e70
    }

    &.jv-object {
      color: #edf0f2
    }

    &.jv-undefined {
      color: #e08331
    }

    &.jv-string {
      color: #42b983;
      word-break: break-word;
      white-space: normal;
    }
  }

  .jv-code {
    overflow-y: auto;
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }

      &:hover {
        &:before {
          background: #eee;
        }
      }
    }
  }
}

.my-awesome-json-theme-light {
  background: #eff1f3;
  white-space: nowrap;
  color: #222222;
  font-size: 16px;
  font-family: Consolas, Menlo, Courier, monospace;

  .jv-ellipsis {
    color: #999;
    background-color: #eee;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }

  .jv-button {
    color: #49b3ff
  }

  .jv-key {
    color: #111111;
    cursor: pointer;
  }

  .jv-item {
    &.jv-array {
      color: #111111
    }

    &.jv-boolean {
      color: #fc1e70
    }

    &.jv-function {
      color: #067bca
    }

    &.jv-number {
      color: #fc1e70
    }

    &.jv-number-float {
      color: #fc1e70
    }

    &.jv-number-integer {
      color: #fc1e70
    }

    &.jv-object {
      color: #111111
    }

    &.jv-undefined {
      color: #e08331
    }

    &.jv-string {
      color: #42b983;
      word-break: break-word;
      white-space: normal;
    }
  }

  .jv-code {
    overflow-y: auto;
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }

      &:hover {
        &:before {
          background: #eee;
        }
      }
    }
  }
}
</style>
