<template>
  <div class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="6" class="d-inline-flex">
        <h3>{{ $lang.labels[type] }}</h3>
        <v-switch
          v-if="stepType === 'REST' && type === 'body'"
          v-model="isBodyScript"
          class="pl-2"
          hide-details
          :label="$lang.labels.isBodyScript"
        ></v-switch>
      </v-col>
      <v-col v-if="!isBodyScript" cols="6" class="text-right">
        <v-btn
          color="accent"
          icon
          small
          class="color-accent-text"
          :disabled="!canEdit"
          @click="deconstructedData.push({ text: '', value: '' }), editRow(deconstructedData.length - 1)"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!isBodyScript" no-gutters align="center" class="pt-2">
      <v-col v-if="duplicatedKeys" cols="12" class="pb-2">
        <v-alert dense color="error" style="color: black">{{ $lang.errors.duplicatedKeys }}</v-alert>
      </v-col>
      <v-col v-if="['GROOVY', 'JS'].includes(stepType)" cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-card :key="i" flat class="pa-2 mb-1" style="position: relative">
            <div class="d-inline-flex" style="position: absolute; top: 8px; right: 8px">
              <v-btn
                icon
                small
                :disabled="!canEdit"
                @click="editRow(i)"
              >
                <v-icon small color="info">mdi-file-edit-outline</v-icon>
              </v-btn>
              <v-btn
                class="ml-1"
                icon
                small
                :disabled="!canEdit"
                @click="deleteRow(i)"
              >
                <v-icon small color="error">mdi-delete</v-icon>
              </v-btn>
            </div>
            <v-row no-gutters align="center" class="pb-1">
              <v-col cols="12">
                <p class="pb-0 mb-0 color-primary">{{ $lang.header.key }}</p>
                <p class="pb-0 mb-0">{{ item.text }}</p>
              </v-col>
              <v-col cols="12" class="my-1">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <p class="pb-0 mb-0 color-primary">{{ $lang.header.value }}</p>
                <p class="pb-0 mb-0">{{ item.value.length > 150 ? `${item.value.substring(0, 147)}...` : item.value }}</p>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-col>
      <v-col v-if="!['GROOVY', 'JS'].includes(stepType)" cols="12">
        <v-row no-gutters align="center" class="pb-1">
          <v-col v-if="userSettings.display.showId" cols="1">
            <span>{{ $lang.header.id }} </span>
          </v-col>
          <v-col cols="4">
            <span>{{ ['EMAIL', 'SLACK', 'TWILIO', 'PDF'].includes(stepType) ? $lang.header.variable : $lang.header.key }}</span>
          </v-col>
          <v-col cols="5" class="px-1">
            <span>{{ $lang.header.value }}</span>
          </v-col>
          <v-col cols="2" class="text-right">
            <span>{{ $lang.header.act }}</span>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="!['GROOVY', 'JS'].includes(stepType)" cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-row :key="i" no-gutters align="center" class="pb-1">
            <v-col v-if="userSettings.display.showId">
              <span>{{ i + 1 }}</span>
            </v-col>
            <v-col cols="4" :style="`padding-top: ${ ['REST'].includes(stepType) && ['Content-Type'].includes(item.text) ? '9px' : ''}`">
              <v-combobox
                v-if="['EMAIL', 'SLACK', 'TWILIO'].includes(stepType)"
                v-model="item.text"
                full-width
                dense
                :items="filteredKeys"
                :readonly="!canEdit"
                :data-cy="`${type}-key-${i}`"
                @focus="removeJavaDollarSign()"
                @change="removeJavaDollarSign()"
                @blur="removeJavaDollarSign()"
                @input="removeJavaDollarSign()"
              ></v-combobox>
              <v-text-field
                v-else-if="['EXECUTE_PROCESS', 'GROOVY', 'JS', 'SECURITY', 'PLUGIN'].includes(stepType)"
                v-model="item.text"
                dense
                :readonly="!canEdit"
                required
                :rules="[v => !!v || 'Required!', (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
                :data-cy="`${type}-key-${i}`"
              ></v-text-field>
              <v-text-field
                v-else
                v-model="item.text"
                dense
                :readonly="type === 'switch' || !canEdit"
                required
                :rules="[v => !!v || 'Required!']"
                :data-cy="`${type}-key-${i}`"
                @focus="['PDF'].includes(stepType) ? removeJavaDollarSign() : ''"
                @change="['PDF'].includes(stepType) ? removeJavaDollarSign() : ''"
                @blur="['PDF'].includes(stepType) ? removeJavaDollarSign() : ''"
                @input="['PDF'].includes(stepType) ? removeJavaDollarSign() : ''"
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="px-1 align-center" :style="`padding-top: ${ ['EMAIL', 'SLACK', 'TWILIO'].includes(stepType) && !['locale'].includes(item.text) ? '13px' : ''}`">
              <v-autocomplete
                v-if="['EMAIL', 'SLACK', 'TWILIO'].includes(stepType) && ['locale'].includes(item.text)"
                v-model="item.value"
                full-width
                dense
                :items="availableLanguages"
                item-value="value"
                item-text="text"
                :readonly="!canEdit"
                :data-cy="`${type}-value-${i}`"
                :search-input.sync="searchSync"
              ></v-autocomplete>
              <v-combobox
                v-else-if="['REST'].includes(stepType) && ['Content-Type'].includes(item.text)"
                v-model="item.value"
                full-width
                dense
                :items="headerPredefinedValues"
                :readonly="!canEdit"
                :data-cy="`${type}-value-${i}`"
              ></v-combobox>
              <!--              Removing for now, until we figure out complete validation - MD - 23.08.2022-->
              <!--              <v-text-field-->
              <!--                v-else-if="['GROOVY', 'JS'].includes(stepType)"-->
              <!--                v-model="item.value"-->
              <!--                dense-->
              <!--                required-->
              <!--                :rules="[v => !!v || 'Required!', v => $options.filters.checkComplexRegex(v) || 'Format invalid']"-->
              <!--                :readonly="!canEdit"-->
              <!--                :data-cy="`${type}-value-${i}`"-->
              <!--              ></v-text-field>-->
              <v-text-field
                v-else
                v-model="item.value"
                dense
                required
                :rules="[v => !!v || 'Required!']"
                :readonly="!canEdit"
                :data-cy="`${type}-value-${i}`"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="d-inline-flex">
                <v-btn
                  v-if="['js', 'groovy'].includes(type) || ['REST', 'JWT', 'EMAIL', 'SMTP', 'EXECUTE_PROCESS'].includes(stepType)"
                  icon
                  small
                  :disabled="!canEdit"
                  @click="editRow(i)"
                >
                  <v-icon small color="info">mdi-file-edit-outline</v-icon>
                </v-btn>
                <v-btn
                  class="ml-1"
                  icon
                  small
                  :disabled="!canEdit"
                  @click="deleteRow(i)"
                >
                  <v-icon small color="error">mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
    <v-row v-else no-gutters align="center" class="pt-2 position-relative">
      <v-textarea
        v-model="bodyScript"
        data-cy="rest-bodyScript"
        rows="2"
        outlined
        hide-details
        dense
        :label="$lang.labels.bodyScript"
        :readonly="!canEdit"
      ></v-textarea>
      <v-btn
        class="button-help"
        style="position: absolute; top: 22px; right: 10px"
        icon
        small
        :disabled="!canEdit"
        @click="$emit('bodyScriptEditor', bodyScript)"
      >
        <v-icon small color="info">mdi-file-edit-outline</v-icon>
      </v-btn>
    </v-row>
    <v-dialog v-if="showJsGroovyEditor" v-model="showJsGroovyEditor" max-width="71%">
      <global-js-groovy-editor
        :item="selectedItem"
        :with-buttons="true"
        :can-edit="canEdit"
        :vars="steps"
        :step-type="stepType"
        @closeDialog="closeEdit()"
        @fromGlobalEditor="updateFromEditor($event)"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import globalJsGroovyEditor from '@/components/ui/GlobalJsGroovyEditor.vue'

export default {
  components: { globalJsGroovyEditor },
  props: {
    steps: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Object,
      default: () => {
        return null
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    },
    stepType: {
      type: String,
      default: () => {
        return ''
      }
    },
    full: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({
    showJsGroovyEditor: false,
    deconstructedData: [],
    selectedItem: null,
    selectedKeys: [],
    emailKeys: ['locale', 'subject'],
    slackKeys: ['locale', 'message'],
    duplicatedKeys: false,
    languages: ['bg', 'zh', 'hr', 'cs', 'da', 'nl', 'en', 'et', 'fi', 'fr', 'de', 'el', 'hu', 'ga', 'it', 'lv', 'lt', 'mt', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sv', 'tr', 'uk'],
    searchSync: '',
    isBodyScript: false,
    bodyScript: '',
    headerPredefinedValues: [
      '\'application/json\'',
      '\'application/xml\'',
      '\'application/x-www-form-urlencoded\'',
      '\'multipart/form-data\''
    ]
  }),
  computed: {
    ...mapState('app', ['userSettings']),
    filteredKeys() {
      if (this.stepType === 'EMAIL') return this.emailKeys.filter((x) => !this.selectedKeys.includes(x))
      if (this.stepType === 'SLACK') return this.slackKeys.filter((x) => !this.selectedKeys.includes(x))
      if (this.stepType === 'TWILIO') return this.slackKeys.filter((x) => !this.selectedKeys.includes(x))

      return []
    },
    availableLanguages() {
      return this.languages.map((x) => {
        return { text: this.$lang.labels[x], value: x }
      })
    }
  },
  watch: {
    searchSync: {
      handler(val) {
        const tempSearch = this.availableLanguages.find((x) => x.text === val)
        const localeData = this.deconstructedData.find((x) => x.text === 'locale')

        if (tempSearch) localeData.value = tempSearch.value
      }
    },
    bodyScript: {
      handler(val) {
        this.$emit('dataBodyScriptChanged', val)
      }
    },
    isBodyScript: {
      handler(val) {
        if (val) {
          this.deconstructedData = []
        } else {
          this.bodyScript = ''
        }
      }
    },
    deconstructedData: {
      handler() {
        let tempData = null

        if (this.type !== 'switch') {
          tempData = {}

          this.deconstructedData.forEach((item) => {
            if (item.text && item.value) tempData[item.text] = item.value
          })
        }
        if (this.type === 'switch') {
          tempData = []

          this.deconstructedData.forEach((item) => {
            if (item.text && item.value) tempData.push({ query: item.value, steps: item.steps ? item.steps : [] })
          })
        }
        this.selectedKeys = this.deconstructedData.map((x) => x.text)
        this.duplicatedKeys = !!this.hasDuplicates(this.selectedKeys)
        this.$emit('dataChanged', tempData)
      },
      deep: true
    }
  },
  created() {
    if (this.data && this.type === 'switch') {
      const tempData = this.data.value

      tempData.forEach((item) => {
        this.deconstructedData.push({ text: 'query', value: item.query, steps: item.steps })
      })
    } else if (this.data && this.stepType === 'REST' && this.type === 'body') {
      if (this.data.bodyScript) {
        this.isBodyScript = true
        this.deconstructedData = []
        this.bodyScript = this.data.bodyScript
      } else {
        for (const row in this.data.body) {
        // eslint-disable-next-line no-prototype-builtins
          if (this.data.body.hasOwnProperty(row)) {
            this.deconstructedData.push({ text: row , value: this.data.body[row] })
          }
        }
      }
    } else {
      for (const row in this.data) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.data.hasOwnProperty(row)) {
          this.deconstructedData.push({ text: row , value: this.data[row] })
        }
      }
    }
  },
  methods: {
    updateFromEditor(data) {
      this.deconstructedData[this.selectedItemIndex].text = data.key
      this.deconstructedData[this.selectedItemIndex].value = data.value
      this.showJsGroovyEditor = false
      this.selectedItemIndex = null
    },
    removeJavaDollarSign() {
      this.deconstructedData.forEach((obj, i) => {
        const isDollarStart = obj.text.substring(0, 1) === '$'

        if (isDollarStart) this.deconstructedData[i].text = obj.text.substring(1)
      })
    },
    hasDuplicates(arr) {
      return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x))
    },
    emailRules(v) {
      const isSplit = v.split(',')

      if (isSplit.length > 1) {
        let correct = true

        isSplit.forEach((testEmail) => {
          if (correct) {
            correct = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(testEmail.trim().toLowerCase())
          }
        })

        if (correct) return true

        return this.$lang.errors.formatEmail
      } else {
        return (v && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v.toLowerCase())) || this.$lang.errors.formatEmail
      }
    },
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    },
    editRow(i) {
      if (['JS', 'GROOVY'].includes(this.stepType)) {
        this.selectedItem = this.deconstructedData[i]
        this.selectedItemIndex = i
        this.showJsGroovyEditor = true
      } else {
        this.$emit('editor', this.deconstructedData[i])
      }
    },
    closeEdit() {
      if (this.selectedItem?.value === '' && this.selectedItem?.text === '') {
        this.deconstructedData.splice(this.selectedItemIndex, 1)
      }
      this.selectedItem = null
      this.selectedItemIndex = null
      this.showJsGroovyEditor = false
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
